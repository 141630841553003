import Button from 'components/Button/Button';
import AntModalStyled from 'components/Modals/AntModalStyled';
import React, { useState } from 'react';
import ErrorIcon from 'assets/icons/error-2.svg';
import ArrowDownIcon from 'assets/icons/arrow-down.svg';
import { ButtonStyledAsLink } from 'components/Button/styled';
import styled from 'styled-components';
import { UploadFailedStatusMessageType } from 'components/FileUpload/type';
import ProcessingErrorsList from './ProcessingErrorsList';

type ProcessingErrorModalType = {
  isAssetUpload?: boolean;
  processingErrors: UploadFailedStatusMessageType[];
  setProccesingErrorModalOpen: (arg: boolean) => void;
};
const StyledContent = styled.div`
  header {
    img {
      width: 40px;
      height: 40px;
    }
  }
  button.-u-link {
    color: #414042;
    fontweight: 400;
    margin-bottom: 10px;
  }
  p {
    font-size: 15px;
  }
  ul {
    padding-left: 20px;
    li {
      margin-bottom: 10px;
    }
  }
`;
export default function ProcessingErrorModal({
  isAssetUpload,
  processingErrors,
  setProccesingErrorModalOpen,
}: ProcessingErrorModalType) {
  const [expanded, setExpanded] = useState(false);

  function handleExpand() {
    setExpanded(!expanded);
  }

  return (
    <AntModalStyled
      onCancel={() => setProccesingErrorModalOpen(false)}
      visible
      centered
      footer={null}
      maskClosable={false}
      style={{ maxHeight: '269px', height: '100%' }}
    >
      <StyledContent>
        <header>
          <img src={ErrorIcon} alt="Error" />
          <h3>{`Error in Uploading ${isAssetUpload ? 'Assets' : 'Beacons'}`}</h3>
        </header>
        <p>Sorry, your upload was unsuccessful. Please check your file.</p>
        <ButtonStyledAsLink
          className="-u-link"
          onClick={handleExpand}
          data-test-id="expand-upload-error"
        >
          Errors ({processingErrors.length})
          <img
            style={{ marginLeft: '10px', transform: `${expanded ? 'rotate(180deg)' : ''}` }}
            width="10px"
            height="10px"
            src={ArrowDownIcon}
            alt="expand"
          />{' '}
        </ButtonStyledAsLink>
        {expanded && <ProcessingErrorsList processingErrors={processingErrors} />}
        <footer>
          <Button onClick={() => setProccesingErrorModalOpen(false)} outline>
            OK
          </Button>
        </footer>
      </StyledContent>
    </AntModalStyled>
  );
}
