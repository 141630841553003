import { LatestBeacon } from 'types/devices';
import React from 'react';
import { Tooltip } from 'react-leaflet';
import { StyledSmallTooltipInner } from '../../Styled';
import { getValueSafely } from 'utils/utils';
import AssetSubtypeCell from 'components/DataTable/cells/AssetSubtypeCell';

export default function SmallInfoBoxTooltip({ asset }: { asset: LatestBeacon }) {
  return (
    <Tooltip className="tooltip-small" opacity={1} direction="left" permanent offset={[-18, 0]}>
      <StyledSmallTooltipInner>
        <span>
          <AssetSubtypeCell value={asset.entity?.subtype} />
        </span>
        <span>{getValueSafely(asset.entity?.properties.name)}</span>
      </StyledSmallTooltipInner>
    </Tooltip>
  );
}
