import axios from 'axios';
import config from 'react-global-configuration';
import { alertErrorMessage } from 'utils/alerts';

class DevicesApi {
  protected token: string;
  protected config: Headers;
  protected axiosConfig: any;

  public constructor(token: string) {
    this.config = new Headers();
    this.token = token;
    this.config.set('Content-Type', 'application/json');
    this.config.set('Accept', 'application/json');
    this.config.set('Authorization', `Bearer ${this.token}`);
    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
  }

  public getNewBeacons = async (source: string, nextToken?: string): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('source', source);
    queryString.append('count', '50');
    if (nextToken) queryString.append('next', nextToken);

    return axios.get(
      `${config.get().DATA_API_URL}/device/beacon/find?${queryString.toString()}`,
      this.axiosConfig,
    );
  };

  public getBeaconById = async (source: string, term: string): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('source', source);
    queryString.append('count', '50');
    queryString.append('filter[device_id]', `"${term}"`);

    return axios.get(
      `${config.get().DATA_API_URL}/device/beacon/find?${queryString.toString()}`,
      this.axiosConfig,
    );
  };

  public getRegisteredBeacons = async (
    assigned?: boolean | null,
    nextToken?: string | null,
    sortField?: string,
    sortDirection?: string,
  ): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('count', '50');
    if (assigned !== null && assigned !== undefined) queryString.append(`assigned`, `${assigned}`);
    if (nextToken) queryString.append('next', nextToken);
    if (sortField) queryString.append('sort', `${sortDirection === 'desc' ? '-' : ''}${sortField}`);

    return (
      axios
        .get(
          `${config.get().DATA_API_URL}/latest/beacon/?${queryString.toString()}`,
          this.axiosConfig,
        )
        // error handled here because we're using react-query to call.
        .catch((err) => {
          alertErrorMessage('Could not fetch Beacons');
          console.log(err);
        })
    );
  };

  public getSearchSingleOptionRegisteredBeacons = async (
    term: string,
    searchOption: string,
    assigned?: boolean | null,
    sortField?: string,
    sortDirection?: string,
  ): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('count', '50');
    queryString.append(`filter[${searchOption}]`, `${term}`);
    if (assigned !== null && assigned !== undefined) queryString.append(`assigned`, `${assigned}`);
    if (sortField) queryString.append('sort', `${sortDirection === 'desc' ? '-' : ''}${sortField}`);

    return axios.get(
      `${config.get().DATA_API_URL}/latest/beacon/?${queryString.toString()}`,
      this.axiosConfig,
    );
  };

  public getSearchAllOptionsRegisteredBeacons = async (
    term: string,
    assigned?: boolean | null,
    sortField?: string,
    sortDirection?: string,
  ): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('count', '50');
    queryString.append(`filter[entity_id]`, `${term}`);
    queryString.append(`filter[device_id]`, `${term}`);
    queryString.append(`filter[source]`, `${term}`);
    if (assigned !== null && assigned !== undefined) queryString.append(`assigned`, `${assigned}`);
    if (sortField) queryString.append('sort', `${sortDirection === 'desc' ? '-' : ''}${sortField}`);

    return axios.get(
      `${config.get().DATA_API_URL}/latest/beacon/?${queryString.toString()}`,
      this.axiosConfig,
    );
  };

  public getRegisteredBeaconsFlexible = async (
    term: string,
    searchOption: string,
    assigned?: boolean | null,
    nextToken?: string | null,
    sortField?: string,
    sortDirection?: string,
    isSearchingAll?: boolean,
  ): Promise<any> => {
    const queryString = new URLSearchParams();

    queryString.append('count', '50');
    if (assigned !== null && assigned !== undefined) queryString.append(`assigned`, `${assigned}`);

    if (isSearchingAll) {
      queryString.append(`filter[entity_id]`, `${term}`);
      queryString.append(`filter[device_id]`, `${term}`);
      queryString.append(`filter[source]`, `${term}`);
    } else if (searchOption) {
      queryString.append(`filter[${searchOption}]`, `${term}`);
    }

    if (sortField) queryString.append('sort', `${sortDirection === 'desc' ? '-' : ''}${sortField}`);
    if (nextToken) queryString.append('next', nextToken);

    return axios.get(
      `${config.get().DATA_API_URL}/latest/beacon/?${queryString.toString()}`,
      this.axiosConfig,
    );
  };

  public getRegisteredBeaconsTotal = async (assigned?: boolean | null): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('count', '1');
    if (assigned !== null && assigned !== undefined) queryString.append(`assigned`, `${assigned}`);

    return axios
      .get(
        `${config.get().DATA_API_URL}/latest/beacon/?${queryString.toString()}`,
        this.axiosConfig,
      )
      .catch((e) => e);
  };

  public postBeacon = async (beaconData: any) => {
    const url = `${config.get().DATA_API_URL}/device/beacon/`;
    return axios.post(url, JSON.stringify(beaconData), this.axiosConfig);
  };

  public getJobTemplateFile = async () => {
    const url = `${config.get().DATA_API_URL}/device/beacon/job/template`;

    return axios({
      url: url,
      method: 'get',
      responseType: 'blob',
      headers: {
        'Access-Control-Expose-Headers': 'Content-Disposition',
        'Content-Disposition': 'attachment;',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
}

export default DevicesApi;
