import differenceInMinutes from 'date-fns/differenceInMinutes';
import freshIcon from '../icons/fresh-asset.svg';
import moderateIcon from '../icons/moderate-asset.svg';
import staleIcon from '../icons/stale-asset.svg';
import freshIconMoving from '../icons/fresh-moving-asset.svg';
import { Estimate } from 'types/combinedEstimatesAssets';

export function getIconFromEstimateFreshness(estimate: Estimate) {
  const timeNow = new Date();
  const timeDifferenceInMins = differenceInMinutes(timeNow, parseFloat(estimate.timestamp));

  // timings:
  // > 1 hour = stale
  // 10 - 59 mins = moderate
  // 0 - 9 mins = fresh

  const estimateIsStale = timeDifferenceInMins >= 60;
  const estimateIsModerate = timeDifferenceInMins >= 10 && timeDifferenceInMins <= 59;
  const estimateIsFresh = timeDifferenceInMins <= 9;
  const estimateIsMobile = estimate.properties?.mobility_state === 'mobile';

  if (estimateIsMobile && estimateIsFresh) return freshIconMoving;
  else {
    // non moving asset icon
    if (estimateIsStale) {
      return staleIcon;
    } else if (estimateIsModerate) {
      return moderateIcon;
    } else if (estimateIsFresh) {
      return freshIcon;
    }
  }
  return staleIcon; // if nothing above matches.
}
