import React, { useState, useContext } from 'react';
import AssetListFlyoutDetails from './content/AssetListFlyoutDetails';
import Button from 'components/Button/Button';
import { StyledAssetListFlyout, StyledButtonsContainer } from './styles';
import EntitiesApi from 'api/entities/entities.api';
import { getIdToken, getValueSafely } from 'utils/utils';
import ArchiveAssetModal from './ArchiveAssetModal';
import { AuthenticationContext } from 'contexts/authentication.context';
import { useNavigate } from 'react-router';
import { alertErrorMessage, alertSuccessMessage } from 'utils/alerts';
import useMustUpdate from 'hooks/mustUpdate.hook';
import { LatestBeacon } from 'types/devices';
import useAssetConfig from 'hooks/useAssetConfig.hook';
import { PERMISSIONS } from 'config/permissions';
import ComponentUserPermissionRenderWrapper from 'wrappers/ComponentConditionWrappers/ComponentUserPermissionRenderWrapper';
import useDevice from 'hooks/useDevice';
import styled from 'styled-components';
import { colours } from 'styling/colours';

const AssetListFlyout = ({
  selectedAssetDetails,
  setFlyoutOpen,
  flyoutOpen,
}: {
  selectedAssetDetails: LatestBeacon;
  setFlyoutOpen: (arg: boolean) => void;
  flyoutOpen: boolean;
}) => {
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { isMobileDevice } = useDevice();

  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const navigate = useNavigate();
  const entitiesAPI = new EntitiesApi(token);

  const { setMustUpdate } = useMustUpdate();
  const { getLabelFromAssetConfig } = useAssetConfig();

  const handleArchive = (entityID: string) => {
    setApiCallInProgress(true);

    entitiesAPI
      .deleteAsset(entityID)
      .then(() => {
        setApiCallInProgress(false);
        setFlyoutOpen(false);
        setModalOpen(false);
        alertSuccessMessage(
          `${selectedAssetDetails.entity.properties.name} - ${getLabelFromAssetConfig(
            selectedAssetDetails.entity.subtype,
            'subtype',
          )} has been archived`,
        );
        setMustUpdate(true);
      })

      .catch((err) => {
        console.log(err);
        alertErrorMessage(
          `An error occurred ${selectedAssetDetails.entity.properties.name} has not been archived`,
        );
      });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const handleEditAsset = () => {
    // set asset detail in local storage, to be read from edit asset page.
    localStorage.setItem('assetDetails', JSON.stringify(selectedAssetDetails));

    navigate('/edit-asset');
  };

  return (
    <>
      <StyledAssetListFlyout
        isOpen={flyoutOpen}
        onRequestClose={() => {
          setFlyoutOpen(false);
        }}
        title={
          isMobileDevice
            ? getValueSafely(selectedAssetDetails.entity?.properties?.name)
            : 'Asset Details'
        }
        subtitle={
          isMobileDevice && getLabelFromAssetConfig(selectedAssetDetails.entity?.subtype, 'subtype')
        }
      >
        <AssetListFlyoutDetails selectedAssetDetails={selectedAssetDetails} />
        {isMobileDevice && (
          <NotMobileSupportStyle>
            Please use a larger screen to edit or archive assets.
          </NotMobileSupportStyle>
        )}
        {!isMobileDevice && (
          <ComponentUserPermissionRenderWrapper userPermissions={[PERMISSIONS.USER.WRITE]}>
            <StyledButtonsContainer>
              <Button className="biggerButton" outline onClick={openModal} id="archiveAssetButton">
                Archive
              </Button>
              <Button className="biggerButton" onClick={handleEditAsset} id="editAssetButton">
                Edit
              </Button>
            </StyledButtonsContainer>
          </ComponentUserPermissionRenderWrapper>
        )}
      </StyledAssetListFlyout>
      {modalOpen && (
        <ArchiveAssetModal
          closeModal={closeModal}
          apiCallInProgress={apiCallInProgress}
          actionFunction={() => handleArchive(selectedAssetDetails.entity.entity_id)}
          selectedAssetDetails={selectedAssetDetails}
        />
      )}
    </>
  );
};
export default AssetListFlyout;

const NotMobileSupportStyle = styled.div`
  width: 100%;
  text-align: center;
  color: ${colours.secondaryText};
`;
