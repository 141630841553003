import useAuth from 'hooks/useAuth.hook';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { AuthContextTypes } from 'hooks/useAuth.hook';
import { Cache } from 'aws-amplify';
import LoadingPlaceholderSkeletonPage from 'components/Loading/LoadingPlaceholderPage/LoadingPlaceholderSkeletonPage';

export const AuthenticationContext = createContext({} as AuthContextTypes);
type AuthenticationProviderProps = { children: ReactNode };

const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const [userAuthChecked, setUserAuthChecked] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const { auth, authState, setAuthState } = useAuth();

  const value: any = { authState, setAuthState };

  useEffect(() => {
    // a function to get current authenticated user, and set auth state with auh
    // if this check errors, it means they are not authenticated and we redirect them to the signin page.
    // this will run once, when the application loads after returning from cognito sign in ui.
    // we bypass this if mocking authstate for tests.

    if (!authState.isMock) {
      setIsAuthenticating(true);
      auth
        .currentAuthenticatedUser()
        .then((user: any) => {
          const newState = { ...user, isAuthenticated: true };

          setAuthState(newState);
          Cache.setItem('authState', newState);
          setIsAuthenticating(false);
          setUserAuthChecked(true);
        })
        .catch((e) => {
          // if current user does not exist or errors, take user directly to signin.
          console.log(e);
          setIsAuthenticating(false);
          setUserAuthChecked(false);

          setTimeout(() => {
            // timeout to prevent too many short redirects resulting in errors to POST to oath/token.
            console.log('Authentication: [redirecting to federated signin]');
            auth.federatedSignIn();
          }, 3000);
        });
    } else {
      setIsAuthenticating(false);
      setUserAuthChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return userAuthChecked && !isAuthenticating ? (
    <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>
  ) : (
    <LoadingPlaceholderSkeletonPage />
  );
};

export default AuthenticationProvider;
