import useAssetConfig from 'hooks/useAssetConfig.hook';
import React from 'react';

export default function AssetSubtypeCell({ value }: { value: string }) {
  const { getLabelFromAssetConfig } = useAssetConfig();

  const label = getLabelFromAssetConfig(value, 'subtype');

  return <>{label}</>;
}
