import EntitiesJobsApi from 'api/entities/entities.job.api';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken, getUUID } from 'utils/utils';
import { alertErrorMessage, alertSuccessMessage } from 'utils/alerts';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UploadSuccessMessage from '../../../views/AssetManagementPage/components/UploadSuccessMessage';
import { UploadFailedStatusMessageType } from '../type';
import DevicesJobsApi from 'api/devices/devices.job.api';
import { getMappedUrl } from 'utils/utils';

type useBulkUploadFileHandlersType = {
  isAssetUpload: boolean;
  setUploadModalOpen: (arg: boolean) => void;
  setProccesingErrorModalOpen: (arg: boolean) => void;
  setProcessingErrors: (arg: UploadFailedStatusMessageType[]) => void;
};

// A custom hook to contain all handlers used by bulk upload forms.
// This hook represents the logic for both asset upload and beacon upload.
// note All the logic is the same except for the API class used, and the navigation after completion.
export default function useBulkUploadFileHandlers({
  isAssetUpload,
  setUploadModalOpen,
  setProccesingErrorModalOpen,
  setProcessingErrors,
}: useBulkUploadFileHandlersType) {
  const [jobID, setJobID] = useState('');
  const [fileName, setFileName] = useState('');
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [fileUploadComplete, setFileUploadComplete] = useState(false);
  const [processedLines, setProcessedLines] = useState(0);
  const [uploadProgressStatus, setUploadProgressStatus] = useState('');
  const [uploadFailed, setUploadFailed] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const { authState } = useContext(AuthenticationContext);
  const idToken = getIdToken(authState);
  const derivedAPI = isAssetUpload ? new EntitiesJobsApi(idToken) : new DevicesJobsApi(idToken);
  const navigate = useNavigate();

  function handleFileStatus(res: any, interval: any) {
    // processing (also has progress value 0 -1 ) - the job is in progress.
    // succeeded (terminal) - the job succeded.
    // failed (terminal) - the job failed.

    const progressData = res.data;

    switch (progressData.status) {
      case 'processing':
        // update percentage with progress int API returns.
        {
          const progressInt = progressData.progress;
          const uploadProgress = Math.round(50 + (progressInt * 100) / 2);

          setUploadPercentage(uploadProgress);
        }

        break;
      case 'succeeded':
        handleDelete(jobID);
        setUploadPercentage(100);
        setProcessedLines(progressData.processed_lines);
        clearInterval(interval);

        break;
      case 'failed':
        {
          setProccesingErrorModalOpen(true);
          setProcessingErrors(progressData.errors);
          handleDelete(jobID);
          setUploadInProgress(false);
          setUploadModalOpen(false);
          clearInterval(interval);
        }
        break;
      default:
        break;
    }
    setUploadProgressStatus(progressData.status);
  }

  function handleConfirmUpload(selectedFile: any) {
    let job_id = '';
    let upload_url = '';
    let file_id = getUUID();

    setUploadInProgress(true);
    setFileName(selectedFile.name);
    // create new job.
    derivedAPI
      .createNewJob()
      .then((res: any) => {
        job_id = res.data.job_id;
        setJobID(res.data.job_id);
      })
      // POST to file endpoint to get upload URL.
      .then(() => {
        return derivedAPI.postJobFile(job_id, file_id).then((res) => {
          upload_url = getMappedUrl(res.data.upload_url);
        });
      })
      // PUT file to endpoint returned from previous POST
      .then(() => {
        return derivedAPI.putJobFile(upload_url, selectedFile);
      })
      // instruct upload complete
      .then(() => {
        return derivedAPI.postJobUploadComplete(job_id, file_id).then(() => {
          setUploadPercentage(50);
          setFileUploadComplete(true);
        });
      })
      .catch(() => {
        setUploadInProgress(false);
        setUploadFailed(true);
      });
  }

  function handleDelete(jobID: string) {
    // // once finished, call delete to remove the job.
    derivedAPI
      .deleteJob(jobID)
      .then(() => {
        console.log(`[job_id deleted]: ${jobID}`);
      })
      .catch((e) => {
        console.log('deleteJob error', e);
      });
  }

  function handleCancel() {
    setUploadModalOpen(false);
    alertSuccessMessage(
      uploadFailed
        ? `The process has been cancelled and no ${isAssetUpload ? 'assets' : 'beacons'} uploaded`
        : `Your file selection has been cancelled`,
    );
  }

  useEffect(() => {
    // handle Polling of status once upload is complete
    // every second we check the interval.
    // and pass the response & interval into the handler function.
    if (fileUploadComplete) {
      const interval = setInterval(() => {
        derivedAPI
          .getJobStatus(jobID)
          .then((res) => {
            handleFileStatus(res, interval);
          })
          .catch((e: any) => {
            // if theres an error, cancel interval.
            console.log(e);
            clearInterval(interval);
            setUploadInProgress(false);
            setUploadModalOpen(false);
            alertErrorMessage('error processing');
          });
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadComplete]);

  useEffect(() => {
    // useEffect to complete post processes once API returns 'succeeded' status
    // note we use a timeout to allow UI to give feedback to user that progress has reached 100%.

    if (uploadProgressStatus === 'succeeded') {
      setTimeout(() => {
        setUploadInProgress(false);
        setUploadModalOpen(false);
        alertSuccessMessage(
          <UploadSuccessMessage
            processedLines={processedLines}
            fileName={fileName}
            isAssetUpload={isAssetUpload}
          />,
        );
        navigate(isAssetUpload ? '/assets' : '/beacon-management?tab=registered');
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadProgressStatus]);

  return {
    handleDelete,
    handleConfirmUpload,
    uploadInProgress,
    uploadPercentage,
    uploadFailed,
    handleCancel,
  };
}
