/* eslint-disable jsx-a11y/click-events-have-key-events */
import { format } from 'date-fns/esm';
import { LatestBeacon } from 'types/devices';
import React from 'react';
import { Tooltip } from 'react-leaflet';
import { StyledBigTooltipInner } from '../../Styled';
import { getValueSafely } from 'utils/utils';
import infoImg from '../../icons/info.svg';
import closeImg from '../../icons/close.svg';
import LastSeenReadoutSpan from '../InfoBoxSpans/LastSeenReadoutSpan';
import AccuracyRangeSpan from '../InfoBoxSpans/AccuracyRangeSpan';
import BatteryReadoutSpan from '../InfoBoxSpans/BatteryReadoutSpan';
import LocationSpan from '../InfoBoxSpans/LocationSpan';
import TooltipDescriptionPanel from './TooltipDescriptionPanel';
import AssetSubtypeCell from 'components/DataTable/cells/AssetSubtypeCell';
import AssetOwnerCell from 'components/DataTable/cells/AssetOwnerCell';

type AssetMarkerLargeInfoboxTooltipType = {
  asset: LatestBeacon;
  handleCloseInfoBox: () => void;
};

function LargeInfoBoxTooltip({ asset, handleCloseInfoBox }: AssetMarkerLargeInfoboxTooltipType) {
  const createdString = asset.entity ? format(asset.entity?.created, 'dd/MM/yyyy - HH:mm') : '--';

  return (
    <Tooltip
      className="tooltip-large"
      interactive={true}
      direction="right"
      offset={[20, 0]}
      opacity={1}
      permanent
    >
      <StyledBigTooltipInner>
        <img
          alt="close"
          className="close-button"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          src={closeImg}
          onClick={() => {
            handleCloseInfoBox();
          }}
        />
        <div className="tooltip-inner-container">
          <div className="tooltip-inner-content">
            <div className="information-line">
              <h4>Asset Name:</h4>
              <span>{getValueSafely(asset.entity.properties.name)}</span>
            </div>
            <div className="information-line">
              <h4>Asset Type:</h4>
              <span>{<AssetSubtypeCell value={asset.entity?.subtype} />}</span>
            </div>
            <div className="information-line">
              <h4>Asset ID:</h4>
              <span style={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                {getValueSafely(asset.estimate.entity_id)}
              </span>
            </div>
            <div className="information-line">
              <h4>Added On:</h4>
              <span>{createdString}</span>
            </div>
            <div className="information-line">
              <h4>Owner:</h4>
              <span>
                <AssetOwnerCell value={asset.entity.properties.owner} />
              </span>
            </div>

            <TooltipDescriptionPanel asset={asset} />
          </div>

          <div className="hr"></div>

          <div className="tooltip-inner-content">
            <div className="information-sentance">
              <img src={infoImg} alt="information" />
              <p>Please note, estimated information was derived from the beacon.</p>
            </div>
            <div className="information-line">
              <h4>Last Seen:</h4>
              <LastSeenReadoutSpan timestamp={asset.estimate.timestamp} />
            </div>
            <div className="information-line">
              <h4>Accuracy Range:</h4>
              <AccuracyRangeSpan asset={asset} />
            </div>
            <div className="information-line">
              <h4>Status:</h4>
              <span style={{ textTransform: 'capitalize' }}>
                {getValueSafely(asset.estimate.properties?.mobility_state)}
              </span>
            </div>
            <div className="information-line">
              <h4>Battery:</h4>
              <BatteryReadoutSpan asset={asset} />
            </div>
            <div className="information-line">
              <h4>Location:</h4>
              <LocationSpan asset={asset} />
            </div>
            <div className="information-line">
              <h4>Beacon ID:</h4>
              <span>{getValueSafely(asset.estimate.device_id)}</span>
            </div>
            <div className="information-line">
              <h4>Beacon Type:</h4>
              <span>
                {getValueSafely(asset.device?.properties.product)}
                {asset.device?.source && ` - ${asset.device?.source}`}
              </span>
            </div>
          </div>
        </div>
      </StyledBigTooltipInner>
    </Tooltip>
  );
}

export default React.memo(LargeInfoBoxTooltip);
