import { LatestBeacon } from 'types/devices';
import React from 'react';
import { getValueSafely } from 'utils/utils';

export default function LocationSpan({ asset }: { asset: LatestBeacon }) {
  if (asset.estimate?.location !== undefined) {
    return (
      <span>
        <div>
          {asset.estimate.location?.building?.building_level_name}/{' '}
          {asset.estimate.location?.building?.building_names}
        </div>

        <div style={{ color: '#CCCCCB', margin: '5px 0' }}>Possible region(s):</div>
        <div>{getValueSafely(asset.estimate?.location?.region?.name)}</div>
      </span>
    );
  }
  return <span>––</span>;
}
