export const NUMBER_OF_ROWS_PER_PAGE: number = 50;
export const MAX_CHARACTERS_IN_ASSET_NAME: number = 100;
export const MIN_CHARACTERS_IN_ASSET_NAME: number = 3;
export const MAX_CHARACTERS_IN_ASSET_DESCRIPTION: number = 1000;
export const LOW_BATTERY_THRESHOLD = 5;
export const FRESH_ESTIMATE_IN_SECONDS_THRESHOLD = 3600;
export const MOBILEDEVICEWIDTH = 729;
export const TABLETDEVICEWIDTH = 1280;
// api end points ------------------
export const DATA_API_ENDPOINT = '/api/v2/data';
export const CONFIG_API_ENDPOINT = '/api/v2/config';
export const VENUE_API_ENDPOINT = '/v3/venue';
