import React from 'react';

import FormInputGroup from 'components/FormInput/FormInputGroup';
import { Flex, Item } from 'react-flex-ready';
import AssetOwners from 'components/SelectInput/SelectInputs/AssetOwners';
import FormFieldsetLabelContainer from 'components/FormInput/FormFieldsetLabelContainer';
import AssetSubTypes from 'components/SelectInput/SelectInputs/AssetSubTypes';
import { checkInputHasErrors } from 'utils/utils';
import FormTextareaGroup from 'components/FormInput/FormTextareaGroup';
import {
  assetIDvalidation,
  assetNameValidation,
  descriptionValidation,
} from 'components/FormInput/validationObjects';
import { EditAssetFormTypes } from '../types';

export default function EditAssetForm({
  register,
  errors,
  control,
  Controller,
  formSubmitted,
  clearErrors,
}: EditAssetFormTypes) {
  return (
    <form style={{ maxWidth: '740px', width: '100%' }}>
      <Flex col={12} align="flex-start">
        <Item
          key="col4"
          col={6}
          gap={3}
          className="asset-id-field"
          style={{ marginBottom: '-20px' }}
        >
          <FormInputGroup
            readOnly
            register={register}
            errors={errors}
            fieldID="assetId"
            fieldName="Asset ID"
            type="text"
            isRequired
            validationObj={assetIDvalidation}
            showErrors={formSubmitted && checkInputHasErrors(errors, 'assetID')}
            clearErrors={clearErrors}
          />
        </Item>
      </Flex>

      <Flex col={12} align="flex-start">
        <Item key="col1" col={6} gap={0}>
          <FormInputGroup
            register={register}
            errors={errors}
            fieldID="assetName"
            fieldName="Asset Name"
            type="text"
            isRequired
            validationObj={assetNameValidation}
            showErrors={formSubmitted && checkInputHasErrors(errors, 'assetName')}
            clearErrors={clearErrors}
          />
        </Item>

        <Item key="col2" col={6} gap={3}>
          <FormFieldsetLabelContainer fieldID="assetType" fieldName="Asset Type" isRequired>
            <Controller
              name="assetSubType"
              control={control}
              rules={{ required: true }}
              render={({ field }: any) => <AssetSubTypes {...field} />}
            />
          </FormFieldsetLabelContainer>
        </Item>
      </Flex>

      <Flex col={12} align="flex-start">
        <Item key="col3" col={6} gap={6} className="asset-owner-field">
          <FormFieldsetLabelContainer fieldID="assetOwners" fieldName="Owner" isRequired>
            <Controller
              name="assetOwner"
              control={control}
              rules={{ required: true }}
              render={({ field }: any) => <AssetOwners {...field} />}
            />
          </FormFieldsetLabelContainer>
        </Item>
      </Flex>

      <Flex col={12} align="flex-start">
        <Item key="col3" col={12} gap={0} style={{ marginTop: '-20px' }}>
          <FormTextareaGroup
            register={register}
            errors={errors}
            fieldID="description"
            fieldName="Description"
            type="text"
            validationObj={descriptionValidation}
            showErrors={formSubmitted && checkInputHasErrors(errors, 'description')}
            clearErrors={clearErrors}
          />
        </Item>
      </Flex>
    </form>
  );
}
