import { StyledAsyncSelect } from 'components/AsyncSelect/Styled';
import React, { useContext, useRef } from 'react';
import { SearchFilterContext } from 'views/MapPage/contexts/searchFilter.context';
import ClearInputButton from 'components/Button/ClearInputButton';

export default function MobileAsyncSelectSearch() {
  const { searchTerm, setSearchTerm } = useContext(SearchFilterContext);

  const inputRef: any = useRef(null);

  function handleInputChange(term: string, actionObj: any) {
    switch (actionObj.action) {
      case 'input-change':
        setSearchTerm(term);

        break;

      default:
        break;
    }
  }

  return (
    <>
      <StyledAsyncSelect
        blurInputOnSelect
        placeholder="Search assets"
        onInputChange={(term: any, actionObj: any) => handleInputChange(term, actionObj)}
        inputValue={searchTerm}
        menuIsOpen={false}
        className="asyncSearch"
        classNamePrefix="asyncSearchPrefix"
        ref={inputRef}
      />

      {searchTerm.length > 0 && (
        <ClearInputButton
          onClick={() => {
            setSearchTerm('');
          }}
        />
      )}
    </>
  );
}
