import HeaderDefault from 'components/Header/HeaderDefault';
import React, { useState } from 'react';
import RecentUsersTableContainer from './RecentUsersTableContainer';
import useDevice from 'hooks/useDevice';
import WidthNotSupportedPage from 'views/WidthNotSupportedPage/WidthNotSupportedPage';

export default function RecentUsersPage() {
  const [totalCount, setTotalCount] = useState<number | undefined>(undefined);
  const { isMobileDevice } = useDevice();

  if (isMobileDevice) {
    return <WidthNotSupportedPage text={'This feature is not available on narrow screens.'} />;
  }
  return (
    <>
      <HeaderDefault itemCount={totalCount} CountTitle="Total User" />
      <main>
        <div className="container">
          <>
            <RecentUsersTableContainer totalCount={totalCount} setTotalCount={setTotalCount} />
          </>
        </div>
      </main>
    </>
  );
}
