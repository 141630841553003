import {
  MAX_CHARACTERS_IN_ASSET_NAME,
  MIN_CHARACTERS_IN_ASSET_NAME,
  MAX_CHARACTERS_IN_ASSET_DESCRIPTION,
} from 'config/constants';

export const assetNameValidation = {
  required: true,
  maxLength: MAX_CHARACTERS_IN_ASSET_NAME,
  minLength: MIN_CHARACTERS_IN_ASSET_NAME,
};
export const descriptionValidation = {
  required: false,
  maxLength: MAX_CHARACTERS_IN_ASSET_DESCRIPTION,
};

export const assetIDvalidation = {
  required: true,
};
