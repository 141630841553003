import React from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import infoBg from '../icons/infoBg.svg';
import { LatestBeacon } from 'types/devices';
import useAssetConfig from 'hooks/useAssetConfig.hook';

const StyledParagraph = styled.p`
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0px;
  color: ${colours.primaryText};
  background: url(${infoBg}) no-repeat;
  background-size: 21px 20px;
  background-position-x: left;
  background-position-y: top;
  padding-left: 30px;
  margin: 30px 0 40px;
`;

const ArchiveAssetModalContent = ({
  selectedAssetDetails,
}: {
  selectedAssetDetails: LatestBeacon;
}) => {
  const { getLabelFromAssetConfig } = useAssetConfig();

  return (
    <>
      <p>
        Are you sure you want to archive{' '}
        <strong id="archiveAssetWarningMessage">
          {selectedAssetDetails.entity.properties?.name} -{' '}
          {getLabelFromAssetConfig(selectedAssetDetails.entity.subtype, 'subtype')}
        </strong>
        ?
      </p>
      <StyledParagraph>
        Please note, archived assets will not be displayed in the asset list and you cannot find
        them by searching.
      </StyledParagraph>
    </>
  );
};
export default ArchiveAssetModalContent;
