import React from 'react';
import DataTable from 'react-data-table-component';
import { datatableRecentUsersStyle } from 'components/DataTable/DataTableStyling.config';
import { ReactComponent as UpDownArrow } from 'components/DataTable/icons/upAndDownArrow.svg';
import { StyledDataTableWrapper } from './style';
import { getNextCount } from 'utils/utils';
import ShowMoreButton from './ShowMoreButton';

type RecentUsersTableTypes = {
  data: any;
  columns: any;
  noDataComponent: React.ReactElement;
  defaultSortFieldId: string;
  disableSort: boolean;
  isSorting: boolean;
  nextPageToken?: string;
  handlePagination: () => void;
  fetchingNextPage: boolean;
  paginationIndex: number;
  totalCount?: number;
};

export default function RecentUsersTable({
  data,
  columns,
  noDataComponent,
  defaultSortFieldId,
  disableSort,
  isSorting,
  nextPageToken,
  handlePagination,
  fetchingNextPage,
  paginationIndex,
  totalCount,
}: RecentUsersTableTypes) {
  const nextCount = getNextCount(paginationIndex, totalCount);

  return (
    <StyledDataTableWrapper
      className={`table-container ${isSorting ? '--sorting' : ''}${
        disableSort ? '--disable-sort' : ''
      }`}
      data-test-id="table"
    >
      <DataTable
        columns={columns}
        data={data}
        customStyles={datatableRecentUsersStyle}
        noDataComponent={noDataComponent}
        persistTableHead
        defaultSortFieldId={defaultSortFieldId}
        defaultSortAsc
        sortIcon={<UpDownArrow />}
        sortServer
      />
      {nextPageToken && nextCount > 0 && (
        <ShowMoreButton
          fetchFunction={handlePagination}
          nextIsLoading={fetchingNextPage}
          nextCount={nextCount}
        />
      )}
    </StyledDataTableWrapper>
  );
}
