import Button from 'components/Button/Button';
import { MIN_CHARACTERS_IN_ASSET_NAME } from 'config/constants';
import React, { useContext, useEffect, useState } from 'react';
import { useForm, useFormState, Controller } from 'react-hook-form';
import { windowBeforeUnloadEventHandler } from 'utils/utils';
import { IFormInputs } from 'types/formInterface';
import { EditAssetFormContainer, ButtonsContainer } from '../components/Styled';
import ConfirmCancelModal from '../components/ConfirmCancelModal';
import useEditAssetHooks from '../hooks/useEditAssetHooks';
import EditAssetForm from '../forms/EditAssetForm';
import { AssetManagementContext } from 'contexts/assetManagement.context';

export default function FormController({ existingAssetDetails }: { existingAssetDetails: any }) {
  const [formFieldsValid, setFormFieldsValid] = useState(false);

  const defaultValues = {
    assetId: existingAssetDetails?.entity?.entity_id,
    assetName: existingAssetDetails?.entity?.properties?.name,
    assetSubType: {
      value: existingAssetDetails?.entity?.subtype,
      label: existingAssetDetails?.entity?.subtype,
    },
    assetOwner: {
      value: existingAssetDetails?.entity?.properties?.owner,
      label: existingAssetDetails?.entity?.properties?.owner,
    },
    description: existingAssetDetails?.entity?.properties?.description,
  };

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    clearErrors,
    setError,
    watch,
  } = useForm<IFormInputs>({
    defaultValues: defaultValues,
  });
  const { dirtyFields } = useFormState({ control });
  const {
    formSubmitHandler,
    formSubmitted,
    apiCallInProgress,
    handleCancel,
    handleCloseModal,
    handleCloseModalAndSave,
    setFormSubmitted,
  } = useEditAssetHooks(handleSubmit, formFieldsValid, setError);

  const {
    assetFormHasChanges,
    setAssetFormHasChanges,
    showCancelConfirmationDialog,
    setShowCancelConfirmationDialog,
  } = useContext(AssetManagementContext);

  useEffect(() => {
    // watch all formFields, and derive if form has changes, and if fields are valid on input changes.
    watch(() => {
      const watchFields: any = watch([
        'assetId',
        'assetName',
        'assetSubType',
        'assetOwner',
        'description',
      ]);

      const assetNameField = watchFields[1];
      const assetNameHasRequiredLength = assetNameField.length >= MIN_CHARACTERS_IN_ASSET_NAME;
      const assetSubTypeHasValue = watchFields[2].value !== undefined;
      const assetOwnerHasValue = watchFields[3]?.value !== undefined;
      const fieldsValid = assetNameHasRequiredLength && assetSubTypeHasValue && assetOwnerHasValue;
      const formIsDirty = dirtyFields && Object.keys(dirtyFields).length > 0;

      setAssetFormHasChanges(formIsDirty);
      setFormSubmitted(false);
      setFormFieldsValid(fieldsValid && formIsDirty);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    // attach window unload listener to show browser dialog if edit or add assetform has changes.
    if (assetFormHasChanges) {
      window.addEventListener('beforeunload', windowBeforeUnloadEventHandler, {
        capture: true,
      });

      return () => {
        window.removeEventListener('beforeunload', windowBeforeUnloadEventHandler, {
          capture: true,
        });
      };
    }
  }, [assetFormHasChanges]);

  useEffect(() => {
    // ensure dialog is not shown when page loads.
    // set assetformchanges to false on unmount to ensure navigation logic is contained to this page.
    setShowCancelConfirmationDialog(false);
    return () => {
      setAssetFormHasChanges(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EditAssetFormContainer>
      <div className="content">
        <EditAssetForm
          register={register}
          errors={errors}
          control={control}
          Controller={Controller}
          formSubmitted={formSubmitted}
          clearErrors={clearErrors}
        />
      </div>
      {/* buttons ------------> */}
      <ButtonsContainer>
        <Button
          style={{ width: '170px', height: '50px' }}
          outline
          onClick={handleCancel}
          id="cancelEditAsset"
          className="biggerButton"
        >
          Cancel
        </Button>
        <Button
          style={{ width: '170px', height: '50px' }}
          disabled={!formFieldsValid}
          id="saveEditAsset"
          className="biggerButton"
          onClick={formSubmitHandler}
          isLoading={apiCallInProgress}
        >
          Save
        </Button>
      </ButtonsContainer>

      {showCancelConfirmationDialog && (
        <ConfirmCancelModal
          xClick={() => setShowCancelConfirmationDialog(false)}
          closeModal={handleCloseModal}
          actionFunction={handleCloseModalAndSave}
        />
      )}
    </EditAssetFormContainer>
  );
}
