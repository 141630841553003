import React, { createContext, ReactNode, useContext, useRef, useState } from 'react';
import useSubscribeToGlobalContext from '../hooks/useSubscribeToGlobalContext';
import {
  checkFullAssetURLParamsExist,
  checkFullMobileClickThroughParamsExist,
} from '../utils/utils';
import { URLParamContext } from './urlParam.context';
import useURLParamEffects from '../hooks/useURLParamEffects';

type MapControlContextTypes = {
  assetMarkerInfoChecked: boolean;
  setAssetMarkerInfoChecked: (arg: boolean) => void;
  userMarkerInfoChecked: boolean;
  setUserMarkerInfoChecked: (arg: boolean) => void;
  floorSelectedIDArray: string[];
  setFloorSelectedIDArray: (arg: string[]) => void;
  buildingSelectedID: string;
  setBuildingSelectedID: (arg: string) => void;
  selectedFloorOrdinal: number | null;
  setSelectedFloorOrdinal: (arg: number | null) => void;
  buildingIsSelected: boolean;
  clickedMarkerID: string;
  setClickedMarkerID: (arg: string) => void;
  shouldRecalculateFloor: boolean;
  setShouldRecalculateFloor: (arg: boolean) => void;
  apiCallInProgress: boolean;
  setApiCallInProgress: (arg: boolean) => void;
  recallGetAssets: boolean;
  setRecallGetAssets: (arg: boolean) => void;
  showAssetsChecked: boolean;
  setShowAssetsChecked: (arg: boolean) => void;
  showUsersChecked: boolean;
  setShowUsersChecked: (arg: boolean) => void;
  pollAssetsFinished: boolean;
  setPollAssetsFinished: (arg: boolean) => void;
  mapCompletedLoadRef: any;
};
export const MapControlContext = createContext({} as MapControlContextTypes);

type MapControlProviderProps = { children: ReactNode };

// A context that stores all values needed for controls and functioning UI elements within the Map.
const MapControlProvider = ({ children }: MapControlProviderProps) => {
  const { getGlobalCTXValueOrFallback } = useSubscribeToGlobalContext();
  const { urlParams } = useContext(URLParamContext);
  const { checkBuildingAndLevelAreWithinVenueData } = useURLParamEffects();

  // variables ===================
  const [buildingSelectedID, setBuildingSelectedID] = useState(() => {
    // check url params exist first.
    if (checkFullAssetURLParamsExist()) {
      return urlParams.buildingID;
    } else if (checkFullMobileClickThroughParamsExist()) {
      if (checkBuildingAndLevelAreWithinVenueData()) {
        return urlParams.buildingID;
      }
    } else return getGlobalCTXValueOrFallback('GLOBAL_buildingSelectedID', '');
  });
  const [selectedFloorOrdinal, setSelectedFloorOrdinal] = useState<number | null>(() => {
    return getGlobalCTXValueOrFallback('GLOBAL_mapFloorOrdinal', null);
  }); // note this is null until a building is selected, ordinal is set in getOrdinalFromConditions() func

  // checkboxes
  const [assetMarkerInfoChecked, setAssetMarkerInfoChecked] = useState(false);
  const [userMarkerInfoChecked, setUserMarkerInfoChecked] = useState(false);
  // api call flags
  const [pollAssetsFinished, setPollAssetsFinished] = useState(true);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);

  const [recallGetAssets, setRecallGetAssets] = useState(true); // set to true for inital load.
  const [floorSelectedIDArray, setFloorSelectedIDArray] = useState(['']); // sets associated ids of floor to filter geojson units to display by
  const [clickedMarkerID, setClickedMarkerID] = useState(() =>
    checkFullAssetURLParamsExist() ? urlParams.entityID : '',
  );
  const [shouldRecalculateFloor, setShouldRecalculateFloor] = useState(false);

  // dropdowns

  const [showAssetsChecked, setShowAssetsChecked] = useState(true);
  const [showUsersChecked, setShowUsersChecked] = useState(true);

  const buildingIsSelected = typeof buildingSelectedID === 'string' && buildingSelectedID !== '';

  const mapCompletedLoadRef = useRef(false); // ref set to true after assets are fully loaded, as this is the last API call.

  const value: MapControlContextTypes = {
    assetMarkerInfoChecked,
    setAssetMarkerInfoChecked,
    userMarkerInfoChecked,
    setUserMarkerInfoChecked,
    floorSelectedIDArray,
    setFloorSelectedIDArray,
    selectedFloorOrdinal,
    setSelectedFloorOrdinal,
    buildingSelectedID,
    setBuildingSelectedID,
    buildingIsSelected,
    clickedMarkerID,
    setClickedMarkerID,
    shouldRecalculateFloor,
    setShouldRecalculateFloor,
    apiCallInProgress,
    setApiCallInProgress,
    recallGetAssets,
    setRecallGetAssets,
    showAssetsChecked,
    setShowAssetsChecked,
    showUsersChecked,
    setShowUsersChecked,
    pollAssetsFinished,
    setPollAssetsFinished,
    mapCompletedLoadRef,
  };

  return <MapControlContext.Provider value={value}>{children}</MapControlContext.Provider>;
};

export default MapControlProvider;
