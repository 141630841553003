import { formatDistanceToNowStrict } from 'date-fns';
import React from 'react';
import { colours } from 'styling/colours';
import { replaceWordsInDistanceString } from 'utils/utils';

const LastSeenCell = ({ timestamp }: { timestamp: string }) => {
  if (!timestamp) return <span>––</span>;

  const distance = formatDistanceToNowStrict(new Date(timestamp), { roundingMethod: 'floor' });

  if (distance.match(/hour|day|week|month|year/)) {
    return (
      <span className="u-no-pointer-event" style={{ color: `${colours.error}` }}>
        {replaceWordsInDistanceString(distance)} ago
      </span>
    );
  }
  return <span className="u-no-pointer-event">{replaceWordsInDistanceString(distance)} ago</span>;
};

export default LastSeenCell;
