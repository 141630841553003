import AntModalStyled from 'components/Modals/AntModalStyled';
import React from 'react';
import { getFileValidityStatus } from './utils';
import ModalInvalidFileContent from './ModalInvalidFileContent';
import ModalValidFileContent from './ModalValidFileContent';
import useBulkUploadFileHandlers from '../hooks/useBulkUploadFileHandlers';
import { UploadFailedStatusMessageType } from '../type';

type FileUploadModalTypes = {
  isAssetUpload: boolean;
  selectedFile: any;
  setUploadModalOpen: (arg: boolean) => void;
  setProccesingErrorModalOpen: (arg: boolean) => void;
  setProcessingErrors: (arg: UploadFailedStatusMessageType[]) => void;
};

export default function FileUploadModal({
  isAssetUpload,
  selectedFile,
  setUploadModalOpen,
  setProccesingErrorModalOpen,
  setProcessingErrors,
}: FileUploadModalTypes) {
  const fileStatus = getFileValidityStatus(selectedFile);
  const fileIsValid = fileStatus === 'valid';

  const { handleConfirmUpload, uploadInProgress, uploadPercentage, uploadFailed, handleCancel } =
    useBulkUploadFileHandlers({
      isAssetUpload,
      setUploadModalOpen,
      setProccesingErrorModalOpen,
      setProcessingErrors,
    });

  return (
    <AntModalStyled
      closable={!uploadInProgress}
      onCancel={() => {
        // do not show popup if invalid file modal is closed using x.
        fileIsValid ? handleCancel() : setUploadModalOpen(false);
      }}
      visible
      centered
      footer={null}
      maskClosable={false}
      style={{ maxHeight: '269px', height: '100%' }}
    >
      {fileIsValid ? (
        <ModalValidFileContent
          isAssetUpload={isAssetUpload}
          handleConfirmUpload={() => handleConfirmUpload(selectedFile)}
          fileName={selectedFile.name}
          uploadPercentage={uploadPercentage}
          uploadInProgress={uploadInProgress}
          uploadFailed={uploadFailed}
          handleCancel={handleCancel}
        />
      ) : (
        <ModalInvalidFileContent
          isAssetUpload={isAssetUpload}
          setUploadModalOpen={setUploadModalOpen}
          fileStatus={fileStatus}
          fileName={selectedFile.name}
        />
      )}
    </AntModalStyled>
  );
}
