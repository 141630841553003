import { VenuesContext } from 'contexts/venues.context';
import React, { useContext } from 'react';
import { UserType } from 'types/userInfo';

const LocationCellRecentUser = ({ user }: { user: UserType }) => {
  const { isSingleVenue } = useContext(VenuesContext);

  if (user.estimate?.location !== undefined) {
    return (
      <div className="u-no-pointer-event" data-tag="allowRowEvents">
        {!isSingleVenue && <div>{user.estimate?.location?.venue?.name}</div>}
        <div>
          {user.estimate.location?.building?.building_level_name}/
          {user.estimate.location?.building?.building_names}
        </div>
        <div>{user.estimate.location?.region?.name}</div>
      </div>
    );
  }
  return <>––</>;
};
export default LocationCellRecentUser;
