import React from 'react';

export default function UploadSuccessMessage({
  processedLines,
  fileName,
  isAssetUpload,
}: {
  processedLines: number;
  fileName: string;
  isAssetUpload: boolean;
}) {
  const textString = isAssetUpload ? 'asset' : 'beacon';

  return (
    <>
      #{processedLines} {processedLines > 1 ? `${textString}s have` : `${textString} has`} been
      uploaded from the <b>{fileName}</b> file
    </>
  );
}
