import React, { useContext, useEffect, useRef, useState } from 'react';
import { MapControlContext } from 'views/MapPage/contexts/mapcontrol.context';
import { SearchFilterContext } from 'views/MapPage/contexts/searchFilter.context';
import useCallSearchAssets from 'views/MapPage/hooks/useCallSearchAssets';
import { SearchBarContainerStyled } from 'views/MapPage/Styled';
import { MobileSearchBarStyled, StyledMobileMapFilterPanel } from './mobileStyles';
import { StyledInputContainer } from 'components/AsyncSelect/Styled';
import Button from 'components/Button/Button';
import MobileAsyncSelectSearch from './MobileAsyncSelectSearch';
import NoSearchResultsPanel from '../NoSearchResultsPanel';
import MobileAssetSubTypeDropdownFilterInput from './MobileAssetSubTypeDropdownFilterInput';
import MobileAssetOwnerDropdownFilterInput from './MobileAssetOwnerDropdownFilterInput';
import NoFilterResultsPanel from '../NoFilterResultsPanel';
import {
  checkIfMobileFiltersHaveChangedSinceLastSearch,
  checkIfSearchTermHasChangedSinceLastSearch,
} from 'views/MapPage/utils/utils';

export default function MobileMapSearchFilterPanel({
  setMobileMapFilterMenuOpen,
  mobileMapFilterMenuOpen,
}: {
  setMobileMapFilterMenuOpen: (arg: boolean) => void;
  mobileMapFilterMenuOpen: boolean;
}) {
  const [noSearchResultsReturned, setNoSearchResultsReturned] = useState(false);
  const [noFilterResultsReturned, setNoFilterResultsReturned] = useState(false);

  const { callSearchAssets, callFilterAssets } = useCallSearchAssets();
  const { apiCallInProgress, setRecallGetAssets } = useContext(MapControlContext);
  const {
    searchTerm,
    setSearchTerm,
    setDoNotCalculateFloor,
    setInSearchMode,
    setInFilterMode,
    inSearchMode,
    inFilterMode,
    assetOwnerCheckedListArray,
    assetSubtypeCheckedListArray,
    setAssetOwnerCheckedListArray,
    setAssetSubtypeCheckedListArray,
    setAssetSubtypeCheckAll,
    setAssetOwnerCheckAll,
    assetOwnerFilterModified,
    assetSubtypeFilterModified,
    defaultAssetOwnerCheckedListArray,
    defaultAssetSubtypeCheckedListArray,
  } = useContext(SearchFilterContext);

  const assetOwnerCheckedListArrayRef: any = useRef(assetOwnerCheckedListArray);
  const assetSubtypeCheckedListArrayRef: any = useRef(assetSubtypeCheckedListArray);
  const searchTermRef: any = useRef('');

  function handleInputClick(ev: React.SyntheticEvent) {
    const target = ev.target as HTMLTextAreaElement;
    const targetClassName = target.classList[0];
    // prevent anything occuring if input was not clicked.
    if (targetClassName !== 'asyncSearchPrefix__input') return;
  }

  function resetSearchFromCheckbox() {
    setSearchTerm('');
    setNoSearchResultsReturned(false);
  }

  function handleMobileSearchFilter() {
    const filtersModified = assetOwnerFilterModified || assetSubtypeFilterModified;
    const hasSearchTerm = searchTerm.length > 0;

    setNoSearchResultsReturned(false);
    setNoFilterResultsReturned(false);

    if (hasSearchTerm) {
      callSearchAssets(searchTerm).then((items) => {
        const noResults = items === undefined || items.length < 1;

        setNoSearchResultsReturned(noResults);
        setInSearchMode(!noResults); // only is set if there are results.
        setInFilterMode(false);
        searchTermRef.current = searchTerm;
        assetOwnerCheckedListArrayRef.current = defaultAssetOwnerCheckedListArray;
        assetSubtypeCheckedListArrayRef.current = defaultAssetSubtypeCheckedListArray;
        setAssetOwnerCheckAll(true);
        setAssetSubtypeCheckAll(true);

        // do not close menu if there are no results from search.
        if (!noResults) {
          setMobileMapFilterMenuOpen(false);
        }
      });
    } else if (filtersModified) {
      callFilterAssets(null, assetOwnerCheckedListArray, assetSubtypeCheckedListArray).then(
        (filteredItems) => {
          const noResults = filteredItems === undefined || filteredItems.length < 1;
          setNoFilterResultsReturned(noResults);

          setInFilterMode(!noResults); // only is set if there are results
          setInSearchMode(false);
          // store checkedlists in refs after search.
          assetOwnerCheckedListArrayRef.current = assetOwnerCheckedListArray;
          assetSubtypeCheckedListArrayRef.current = assetSubtypeCheckedListArray;
          searchTermRef.current = ''; // reset search after filter takes place.

          // do not close menu if there are no results from search.
          if (!noResults) {
            setMobileMapFilterMenuOpen(false);
          }
        },
      );
    } else {
      // close menu and recall assets if no searchterm or filters exist
      setMobileMapFilterMenuOpen(false);
      setInSearchMode(false);
      setInFilterMode(false);
      setRecallGetAssets(true);
      setDoNotCalculateFloor(true); // set to true so we dont recenter map / floor
      setNoFilterResultsReturned(false);
      setNoSearchResultsReturned(false);
    }
  }

  function handleClosePanel() {
    const filtersShouldRevertToRefs = checkIfMobileFiltersHaveChangedSinceLastSearch(
      assetOwnerCheckedListArray,
      assetSubtypeCheckedListArray,
      assetOwnerCheckedListArrayRef.current,
      assetSubtypeCheckedListArrayRef.current,
    );
    // do not reset search, if search or filter mode is active.
    if (inFilterMode) {
      setMobileMapFilterMenuOpen(false);
      if (searchTerm.length > 0) {
        setSearchTerm('');
      }
    } else if (inSearchMode) {
      setMobileMapFilterMenuOpen(false);
    } else {
      // full reset
      setMobileMapFilterMenuOpen(false);
      setInSearchMode(false);
      setInFilterMode(false);
      setRecallGetAssets(true);
      setDoNotCalculateFloor(true); // set to true so we dont recenter map / floor
      setNoFilterResultsReturned(false);
      setNoSearchResultsReturned(false);
      setSearchTerm('');
      searchTermRef.current = '';
    }

    // revert filters to last search values, if user has modified, not searched and closes menu.
    if (filtersShouldRevertToRefs) {
      setAssetOwnerCheckedListArray(assetOwnerCheckedListArrayRef.current);
      setAssetSubtypeCheckedListArray(assetSubtypeCheckedListArrayRef.current);

      setAssetSubtypeCheckAll(
        assetSubtypeCheckedListArrayRef.current.length ===
          defaultAssetSubtypeCheckedListArray.length,
      );
    }
    setAssetOwnerCheckAll(
      assetOwnerCheckedListArrayRef.current.length === defaultAssetOwnerCheckedListArray.length,
    );
    if (checkIfSearchTermHasChangedSinceLastSearch(searchTerm, searchTermRef.current)) {
      setSearchTerm(searchTermRef.current);
    }
  }

  useEffect(() => {
    // reset no search panel when checkboxes change.
    setNoFilterResultsReturned(false);
  }, [assetOwnerCheckedListArray, assetSubtypeCheckedListArray]);

  useEffect(() => {
    // reset search when searchterm changes.
    setNoSearchResultsReturned(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    // set refs to default on load.
    assetOwnerCheckedListArrayRef.current = defaultAssetOwnerCheckedListArray;
    assetSubtypeCheckedListArrayRef.current = defaultAssetSubtypeCheckedListArray;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledMobileMapFilterPanel
      title="Search & Filter"
      from="left"
      isOpen={mobileMapFilterMenuOpen}
      onRequestClose={() => {
        handleClosePanel();
      }}
    >
      <SearchBarContainerStyled>
        <p style={{ marginBottom: '10px' }} className="searchbar-readout">
          Search
        </p>
        <MobileSearchBarStyled>
          <StyledInputContainer
            width={'100%'}
            onClick={(ev: React.SyntheticEvent) => handleInputClick(ev)}
          >
            <MobileAsyncSelectSearch />
            {noSearchResultsReturned && <NoSearchResultsPanel searchTerm={searchTerm} />}
          </StyledInputContainer>
        </MobileSearchBarStyled>
        <p className="searchbar-readout">Filter</p>
        <MobileSearchBarStyled>
          <MobileAssetSubTypeDropdownFilterInput
            resetSearchFromCheckbox={resetSearchFromCheckbox}
          />
          <MobileAssetOwnerDropdownFilterInput resetSearchFromCheckbox={resetSearchFromCheckbox} />
          {noFilterResultsReturned && <NoFilterResultsPanel />}
        </MobileSearchBarStyled>
      </SearchBarContainerStyled>
      <Button isLoading={apiCallInProgress} onClick={handleMobileSearchFilter}>
        Done
      </Button>
    </StyledMobileMapFilterPanel>
  );
}
