import React, { useEffect } from 'react';
import HeaderDefault from 'components/Header/HeaderDefault';
import FormController from './controllers/FormController';
import { useNavigate } from 'react-router-dom';
import { getAssetDetailsFromLocalStorage } from 'utils/utils';

export default function EditAssetPage() {
  const navigate = useNavigate();
  const existingAssetDetails = getAssetDetailsFromLocalStorage();

  useEffect(() => {
    // prevent user accessing edit asset without asset provided from local storage
    if (!existingAssetDetails) navigate('/assets');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderDefault />
      <main>
        <div className="container">
          <FormController existingAssetDetails={existingAssetDetails} />
        </div>
      </main>
    </>
  );
}
