import { UserType } from 'types/userInfo';
import React from 'react';
import { Tooltip } from 'react-leaflet';
import { humaniseStringCasing } from 'utils/utils';
import MapTooltipAnimationWrapper from 'wrappers/MapTooltipAnimationWrapper';
import { StyledUserNameTooltipInner } from '../../Styled';
import { getValueSafely } from 'utils/utils';

export default function UserNameTooltip({ user }: { user: UserType }) {
  // display name as priority, otherwise username.
  const userEstimateName = user.entity?.properties?.name;
  const valueToDisplay = userEstimateName ? userEstimateName : user.entity?.properties.username;

  return (
    <Tooltip className="tooltip-small" opacity={1} direction="left" permanent offset={[-18, 0]}>
      <MapTooltipAnimationWrapper>
        <StyledUserNameTooltipInner>
          <span>{humaniseStringCasing(getValueSafely(valueToDisplay))}</span>
        </StyledUserNameTooltipInner>
      </MapTooltipAnimationWrapper>
    </Tooltip>
  );
}
