import styled from 'styled-components';
import AsyncSelect from 'react-select/async';
import loupe from './loupe.svg';
import { colours, coloursNames } from 'styling/colours';

const inputInnerWidth = 280;

export const StyledInputContainer: any = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: ${(props: any) => props.width}px;
  .asyncSearch {
    max-width: ${(props: any) => props.width}px;
    width: 100%;
  }
  .clear-input-button {
    display: flex;
    position: absolute;
    right: 8px;
  }
`;

export const StyledAsyncSelect: any = styled(AsyncSelect)`
  position: relative;
  .asyncSearchPrefix__control {
    width: 100%;
    margin: 0;
    height: 40px;
    padding-left: 30px;
    background-image: url(${loupe});
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: left 12px top 12px;
    border: 1px solid ${colours.tertiaryText};
    border-radius: 6px;
    display: inline-flex;
    box-shadow: none;
    &.asyncSearchPrefix__control--is-focused {
      border-color: ${colours.secondary};
    }
    &:hover {
      border-color: ${colours.secondary};
    }
  }
  .asyncSearchPrefix__loading-indicator {
    margin-right: 25px;
  }
  .asyncSearchPrefix__value-container {
    position: relative;
    width: ${inputInnerWidth}px;
    max-width: ${inputInnerWidth}px;

    .asyncSearchPrefix__input-container {
      overflow-x: hidden;
      .asyncSearchPrefix__input {
        width: ${inputInnerWidth}px;
        min-width: ${inputInnerWidth}px!important;
      }
    }
    .asyncSearchPrefix__placeholder {
      pointer-events: none;
    }
  }
  .asyncSearchPrefix__dropdown-indicator,
  .asyncSearchPrefix__indicator-separator {
    display: none;
  }

  .asyncSearchPrefix__option {
    margin: 0 7px;
    padding: 11px 9px;
    width: 310px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .asyncSearchPrefix__option--is-focused {
    background-color: ${coloursNames.lightGrey15};
    color: ${coloursNames.deepSkyBlue};
  }
  .asyncSearchPrefix__option--is-focused strong {
    font-weight: normal;
  }

  .asyncSearchPrefix__clear-indicator svg {
    display: none;
  }
  .asyncSearch__menu-option-container {
    position: absolute;
    top: 45px;
    left: 0px;
    width: 100%;
    z-index: 9999;
    background: white;
    border: 1px solid ${colours.tertiaryText};
    border-radius: 5px;
    padding: 0px 7px 7px 7px;
    .asyncSearchPrefix__option {
      width: 100%;
      margin: 0;
      border-radius: 3px;
      &:hover {
        cursor: pointer;
        color: ${colours.secondary};
        background-color: ${coloursNames.lightGrey15};
      }
    }
  }
  .dropdownMessage {
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    color: ${colours.tertiaryText};
    padding: 11px 16px;
    border-bottom: 1px solid ${coloursNames.lightGrey30};
  }
  &.--has-search {
    .asyncSearchPrefix__value-container {
      display: flex;
      flex-wrap: initial;
      align-items: center;
      justify-content: flex-start;
      white-space: nowrap;

      &:before {
        content: '${(props: any) =>
          props.selectedSearchMenuOptionLabel ? `${props.selectedSearchMenuOptionLabel}: ` : ''}  ';
        padding-right: 2px;
        font-weight: 600;
      }
    }
  }
`;
