import React, { useContext, useEffect, useState } from 'react';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import AssetsTable from 'components/DataTable/AssetsTable';
import AssetListFlyout from './AssetListFlyout';
import NoDataComponent from 'components/DataTable/NoDataComponent';
import { clearWindowURLParams, getAssetsNonExpired } from 'utils/utils';
import DataTableStatusWrapper from 'components/DataTable/DataTableStatusWrapper';
import usePageInfo from 'hooks/pageInfo.hook';
import useMustUpdate from 'hooks/mustUpdate.hook';
import useAssetListPageHandlers from '../hooks/useAssetListPageHandlers.hook';
import { AssetListPageContext } from 'contexts/assetListPage.context';
import { LatestBeacon } from 'types/devices';
import { assetSearchOptionsArray } from 'components/Header/components/AssetSearch/AssetSearchField';
import useDevice from 'hooks/useDevice';
import { MobileDataTable, DataSortDirection } from 'components/DataTable/MobileDataTable';
import { MobileAssetColumn } from './MobileAssetColumn';

function getSearchLabelFromValue(value: string): string {
  return assetSearchOptionsArray.filter((entry) => entry.value === value)[0].label;
}

export default function AssetTableContainer() {
  const [selectedRow, setSelectedRow] = useState<LatestBeacon | null>(null);
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const { setHeaderAssetCount, headerAssetCount } = usePageInfo();
  const { mustUpdate, setMustUpdate } = useMustUpdate();
  const { isMobileDevice } = useDevice();
  const {
    callFetchEstimates,
    callFetchEstimatesLoop,
    handleSort,
    handlePagination,
    handleClickOptionSearch,
    handleEnterSearch,
  } = useAssetListPageHandlers();

  const {
    setSelectedSearchMenuOptionLabel,
    selectedSearchMenuOptionLabel,
    searchTerm,
    sortColumnField,
    sortDirection,
    setSortColumnField,
    setSortDirection,
    assetTableData,
    setAssetTableData,
    isSorting,
    isLoading,
    setIsLoading,
    nextToken,
    setNextToken,
    networkError,
    isSearching,
    searchCleared,
    setSearchCleared,
    cachedAssetTableDataRef,
    cachedAssetCountRef,
    cachedNextTokenRef,
    cachedPaginationIndexRef,
    fetchingNextPage,
    paginationIndex,
    setPaginationIndex,
  } = useContext(AssetListPageContext);

  const handleRowSelected = React.useCallback((row: any) => {
    setSelectedRow(row);
    setFlyoutOpen(true);
  }, []);
  const noResultsText =
    searchTerm !== '' ? `Sorry, no results were found for ${searchTerm}` : 'No assets to display';

  useEffect(() => {
    // reset state values on load / componnent unmount.
    setIsLoading(true);
    setSortColumnField('estimate.timestamp');
    setSortDirection(DataSortDirection.desc);

    return () => {
      setIsLoading(true);
      setNextToken('');
      setPaginationIndex(0);
      setSortColumnField('-estimate.timestamp');
      setSortDirection('asc');
      setSelectedRow(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // toggle table results, after user starts to clear.
    if (!isSearching && !isLoading) {
      const cachedResultsExist = cachedAssetTableDataRef.current.length > 0;

      if (!cachedResultsExist) {
        // re-call APi if nothing in cache to fallback to. eg coming back from edit page and clearing search.
        callFetchEstimates();
      } else {
        // set next token and asset counts to cached refs if user no longer searching.
        setHeaderAssetCount(cachedAssetCountRef.current);
        setAssetTableData(cachedAssetTableDataRef.current);
        setNextToken(cachedNextTokenRef.current);
        setPaginationIndex(cachedPaginationIndexRef.current);
      }

      clearWindowURLParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching]);

  useEffect(() => {
    // recall api only when search has been cleared, so we get original estimates in table.
    if (searchCleared) {
      callFetchEstimates();
      setSearchCleared(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCleared]);

  useEffect(() => {
    // re-call API after asset archived and mustUpdate is changed.
    // callFetchestimatesLoop will iterate calls until pagination index is met.
    // this way, user will be shown same data if they had used pagination
    if (mustUpdate) {
      callFetchEstimatesLoop();
      setMustUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mustUpdate]);

  useEffect(() => {
    // call api on page load to set initial table data.
    const params = new URLSearchParams(window.location.search);
    const searchTerm = params.get('term');
    const searchCategoryValue = params.get('category');
    // detect  if search params exist in URL on component load
    // invoke relevant search funcwith params if they do.
    if (searchCategoryValue && searchTerm) {
      const searchCategoryLabel = getSearchLabelFromValue(searchCategoryValue);

      setSelectedSearchMenuOptionLabel(searchCategoryLabel);
      handleClickOptionSearch(searchTerm, searchCategoryValue);
      return;
    }
    if (searchTerm) {
      handleEnterSearch(searchTerm);
      return;
    } else {
      callFetchEstimates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DataTableStatusWrapper error={networkError} status={isLoading ? 'loading' : ''}>
        {isMobileDevice ? (
          <MobileDataTable
            RowElement={(row) => <MobileAssetColumn row={row} onClick={handleRowSelected} />}
            defaultSortField={sortColumnField}
            defaultSortDirection={sortDirection as DataSortDirection}
            SortOptions={[
              {
                title: 'Asset Name',
                sortField: 'properties.name',
              },
              {
                title: 'Asset Type',
                sortField: 'subtype',
              },
              {
                title: 'Last Seen',
                sortField: 'estimate.timestamp',
                DescTitle: 'descending',
                AscTitle: 'ascending',
              },
              {
                title: 'Status',
                sortField: 'estimate.properties.mobility_state',
              },
              {
                title: 'Location',
                sortField: 'estimate.location.region.name',
              },
              {
                title: 'Owner',
                sortField: 'properties.owner',
              },
              {
                title: 'Battery',
                sortField: 'estimate.properties.battery.battery_percent',
                DescTitle: 'high to low',
                AscTitle: 'low to high',
              },
            ]}
            data={getAssetsNonExpired(assetTableData)}
            nextPageToken={nextToken}
            handleSort={handleSort}
            handlePagination={handlePagination}
            fetchingNextPage={fetchingNextPage}
            handleRowSelected={handleRowSelected}
            isSorting={isSorting}
            paginationIndex={paginationIndex}
            totalCount={headerAssetCount}
            noDataComponent={
              !networkError && (
                <NoDataComponent
                  selectedSearchMenuOptionLabel={selectedSearchMenuOptionLabel}
                  text={noResultsText}
                />
              )
            }
          />
        ) : (
          <AssetsTable
            assetTableData={getAssetsNonExpired(assetTableData)}
            nextPageToken={nextToken}
            handleSort={handleSort}
            handlePagination={handlePagination}
            fetchingNextPage={fetchingNextPage}
            handleRowSelected={handleRowSelected}
            isSorting={isSorting}
            paginationIndex={paginationIndex}
            totalCount={headerAssetCount}
            noDataComponent={
              !networkError && (
                <NoDataComponent
                  selectedSearchMenuOptionLabel={selectedSearchMenuOptionLabel}
                  text={noResultsText}
                />
              )
            }
          />
        )}
      </DataTableStatusWrapper>
      {selectedRow && (
        <AssetListFlyout
          selectedAssetDetails={selectedRow}
          flyoutOpen={flyoutOpen}
          setFlyoutOpen={setFlyoutOpen}
        />
      )}
    </>
  );
}
