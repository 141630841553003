import React from 'react';
import { LatestBeacon } from 'types/devices';
import { StyledMobileAssetInfoFlyout } from 'views/MapPage/Styled';
import MobileAssetInfoFlyoutContent from './MobileAssetInfoFlyoutContent';
import { getValueSafely } from 'utils/utils';
import useAssetConfig from 'hooks/useAssetConfig.hook';

export default function MobileAssetInfoFlyout({
  selectedAssetDetails,
  handleCloseInfoBox,
}: {
  selectedAssetDetails: LatestBeacon;
  handleCloseInfoBox: () => void;
}) {
  const { getLabelFromAssetConfig } = useAssetConfig();

  return (
    <StyledMobileAssetInfoFlyout
      title={getValueSafely(selectedAssetDetails.entity?.properties?.name)}
      isOpen
      onRequestClose={() => {
        handleCloseInfoBox();
      }}
      subtitle={getLabelFromAssetConfig(selectedAssetDetails.entity?.subtype, 'subtype')}
    >
      <MobileAssetInfoFlyoutContent selectedAssetDetails={selectedAssetDetails} />
    </StyledMobileAssetInfoFlyout>
  );
}
