import { UserType } from 'types/userInfo';
import React from 'react';
import UserMarker from '../components/Markers/UserMarker';

type SelectedBuildingUsersMarkersLayerType = {
  usersMarkers: UserType[];
};
export default function SelectedBuildingUsersMarkersLayer({
  usersMarkers,
}: SelectedBuildingUsersMarkersLayerType) {
  return (
    <>
      {usersMarkers.map((user) => {
        return <UserMarker key={user.estimate._id} user={user} />;
      })}
    </>
  );
}
