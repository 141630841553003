import axios from 'axios';
import config from 'react-global-configuration';

class AssetsApi {
  protected token: string;
  protected config: Headers;
  protected axiosConfig: any;

  public constructor(token: string) {
    this.config = new Headers();
    this.token = token;
    this.config.set('Content-Type', 'application/json');
    this.config.set('Accept', 'application/json');
    this.config.set('Authorization', `Bearer ${this.token}`);
    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
  }

  public getRegisteredBeaconMapAssets = async (
    venueID: string | null,
    term?: string,
  ): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('count', '100');
    queryString.append(`filter[$must:estimate.type]`, 'asset'); // ensure we only get assets with estimates of type asset from api.

    if (venueID) {
      // note we are using $must syntax to force an AND comparison so we filter by venue name.
      queryString.append(`filter[$must:estimate.location.venue.id]`, `${venueID}`);
    }
    if (term) {
      // only search by asset name.
      queryString.append(`filter[properties.name]`, `${term}`);
    }

    return axios.get(
      `${config.get().DATA_API_URL}/latest/asset/?${queryString.toString()}`,
      this.axiosConfig,
    );
  };

  public getRegisteredBeaconFilteredMapAssets = async (
    venueID: string | null,
    assetOwnerQueryArray: string[],
    assetSubtypeQueryArray: string[],
  ): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('count', '100');
    queryString.append(`filter[$must:estimate.type]`, 'asset'); // ensure we only get assets with estimates of type asset from api.

    if (venueID) {
      // note we are using $must syntax to force an AND comparison so we filter by venue name.
      queryString.append(`filter[$must:estimate.location.venue.id]`, `${venueID}`);
    }

    assetOwnerQueryArray.forEach((assetTypeValue) => {
      queryString.append(`filter[properties.owner]`, `"${assetTypeValue}"`);
    });

    assetSubtypeQueryArray.forEach((assetTypeValue) => {
      queryString.append(`filter[subtype]`, `"${assetTypeValue}"`);
    });

    return axios.get(
      `${config.get().DATA_API_URL}/latest/asset/?${queryString.toString()}`,
      this.axiosConfig,
    );
  };
}

export default AssetsApi;
