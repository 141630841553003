import React, { useEffect, useContext, useState } from 'react';
import DataTableStatusWrapper from 'components/DataTable/DataTableStatusWrapper';
import BeaconsTable from 'components/DataTable/BeaconsTable';
import RegisteredBeaconSearchSelectForm from '../components/RegisteredBeaconSearch/RegisteredBeaconSearchSelectForm';
import { registeredBeaconColumns } from 'components/DataTable/columns/registeredBeaconColumns';
import NoDataComponent from 'components/DataTable/NoDataComponent';
import { BeaconManagementContext } from '../contexts/BeaconManagment.context';
import useRegisteredBeaconAPIHandlers from '../hooks/useRegisteredBeaconAPIHandlers';
import { datatableStyle } from 'components/DataTable/DataTableStyling.config';
import RegisteredBeaconFlyout from '../components/RegisteredBeaconFlyout/RegisteredBeaconFlyout';
import { LatestBeacon } from 'types/devices';

type TableWithFilterPanelType = {
  query: any;
  selectedTabKey: string;
};

export default function RegisteredBeaconTabContent({
  query,
  selectedTabKey,
}: TableWithFilterPanelType) {
  const {
    setToggleClearRows,
    searchTerm,
    setSearchTerm,
    nextToken,
    setFilteredData,
    setNextToken,
    toggledClearRows,
    assignOptionHasNoResults,
    isRefreshing,
    setIsSearching,
    searchInProgress,
    selectedSearchMenuOptionLabel,
    filteredData,
    cachedFilteredDataRef,
    isSorting,
    nextIsLoading,
    nextTokenCachedRef,
    assetTotalRef,
    paginationIndexRef,
    paginationIndex,
    setPaginationIndex,
    assetTotal,
    setAssetTotal,
    setAssetAssignOptionSelectedObj,
    defaultDropdownObj,
  } = useContext(BeaconManagementContext);
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<LatestBeacon | []>([]);
  const { fetchNextPage, handleSort } = useRegisteredBeaconAPIHandlers();

  function getNoDataText() {
    return assignOptionHasNoResults
      ? 'No Results.'
      : `Sorry, no results were found for ${searchTerm}`;
  }
  function handleRowSelected(selectedRow: LatestBeacon) {
    setFlyoutOpen(true);
    setSelectedRow(selectedRow);
  }

  useEffect(() => {
    // set initial filter data and next token from inital query response.
    if (query.data) {
      const { items, next, total } = query.data.data;
      setFilteredData(items);
      setNextToken(next);
      setAssetTotal(total);
      setPaginationIndex(0);
      setIsSearching(false);

      cachedFilteredDataRef.current = items; // set cached version.
      nextTokenCachedRef.current = next;
      assetTotalRef.current = total;
      paginationIndexRef.current = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.status, query.data]);

  useEffect(() => {
    // reset dialogs and selected rows on tab change
    setToggleClearRows(!toggledClearRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabKey]);

  useEffect(() => {
    // unselect beacons on unmount.
    return () => {
      setToggleClearRows(!toggledClearRows);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // useEffect to reset search, and asset assignment options after clicking refresh.
    if (isRefreshing) {
      setIsSearching(false);
      setSearchTerm('');
      setAssetAssignOptionSelectedObj(defaultDropdownObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshing]);

  return (
    <>
      <RegisteredBeaconSearchSelectForm />

      <DataTableStatusWrapper
        error={query.error}
        status={query.status}
        isLoadingParam={searchInProgress}
      >
        {query.data && (
          <BeaconsTable
            customStyles={datatableStyle}
            columns={registeredBeaconColumns}
            clearSelectedRows={toggledClearRows}
            handleSort={handleSort}
            data={filteredData}
            fetchNextPage={fetchNextPage}
            defaultSortFieldId={1} // pass device_id to match query sort.
            nextToken={nextToken}
            isSorting={isSorting}
            searchInProgress={searchInProgress}
            nextIsLoading={nextIsLoading}
            totalCount={assetTotal}
            paginationIndex={paginationIndex}
            handleRowSelected={handleRowSelected}
            noDataComponent={
              !query.error && (
                <NoDataComponent
                  selectedSearchMenuOptionLabel={selectedSearchMenuOptionLabel}
                  text={getNoDataText()}
                />
              )
            }
            // selectableRows={userPermissions.hasWrite} // disabled until we can fulfil actions for rows.
          />
        )}
      </DataTableStatusWrapper>
      {selectedRow && (
        <RegisteredBeaconFlyout
          selectedAssetDetails={selectedRow}
          flyoutOpen={flyoutOpen}
          setFlyoutOpen={setFlyoutOpen}
        />
      )}
    </>
  );
}
