import AssetsApi from 'api/assets/assets.api';
import { useContext } from 'react';
import { getIdToken } from 'utils/utils';
import { AssetsContext } from '../contexts/assets.context';
import { SearchFilterContext } from '../contexts/searchFilter.context';
import { VenuesContext } from '../contexts/venues.context';
import { VenueFilesContext } from '../contexts/venuefiles.context';
import {
  checkAssetEstimateLocationIsDifferent,
  checkAssetIsInSameBuilding,
  checkAssetIsOnSelectedFloor,
  getEstimatesWithBuildingAndEstimate,
  getIntersectionFilteredAssets,
} from '../utils/utils';
import { MapControlContext } from '../contexts/mapcontrol.context';
import useMapHooks from './useMapHooks';
import { LatestBeacon } from 'types/devices';
import useMapHooksExternalMapRef from './useMapHooksExternalMapRef';

export default function useCallAssetsAPIIntervalFunction() {
  const { setRawAssets, cachedAssetRef } = useContext(AssetsContext);
  const { selectedVenueObj, isSingleVenue } = useContext(VenuesContext);
  const selectedVenueID = isSingleVenue ? null : selectedVenueObj.venue_id;

  const {
    inSearchMode,
    inFilterMode,
    assetSubtypeCheckedListArray,
    assetOwnerCheckedListArray,
    searchTerm,
  } = useContext(SearchFilterContext);
  const {
    setPollAssetsFinished,
    setShouldRecalculateFloor,
    floorSelectedIDArray,
    buildingSelectedID,
    setBuildingSelectedID,
  } = useContext(MapControlContext);
  const { levelsFeatures } = useContext(VenueFilesContext);
  const { checkAssetLatLngIsWithinMapBounds } = useMapHooks();

  const { fitMapToBoundsOfBuildingLevelsWithAssets } = useMapHooksExternalMapRef();

  function handleSingleAssetReturnedFromSearch(singleAsset: LatestBeacon) {
    // logic for single asset returned usecases.
    const estimateCoordsArray = singleAsset.estimate.location.coordinates.coordinates;
    const assetLocationChanged = checkAssetEstimateLocationIsDifferent(
      singleAsset.estimate,
      cachedAssetRef.current.estimate,
    );
    const assetIsWithinMapBounds = checkAssetLatLngIsWithinMapBounds(estimateCoordsArray);
    const assetIsInSelectedBuilding = checkAssetIsInSameBuilding(singleAsset, buildingSelectedID);
    const assetIsOnSelectedFloor = checkAssetIsOnSelectedFloor(singleAsset, floorSelectedIDArray);
    const assetIsVisibleToUser =
      assetIsWithinMapBounds && assetIsOnSelectedFloor && assetIsInSelectedBuilding;

    if (assetLocationChanged && !assetIsVisibleToUser) {
      const assetWasInSelectedBuildingLastPoll = checkAssetIsInSameBuilding(
        cachedAssetRef.current,
        buildingSelectedID,
      );

      if (assetWasInSelectedBuildingLastPoll && !assetIsInSelectedBuilding) {
        // if asset was in selected building and  has moved buildings, select new building, and pan map to show asset and prev building
        fitMapToBoundsOfBuildingLevelsWithAssets(levelsFeatures, [singleAsset], buildingSelectedID);
        setBuildingSelectedID(singleAsset.estimate.location.building.building_uuids[0]); // this will automatically trigger floor calc.
      } else if (!assetWasInSelectedBuildingLastPoll && !assetIsInSelectedBuilding) {
        // adjust map if necessary for building of asset, whilst keeping current building within view.
        fitMapToBoundsOfBuildingLevelsWithAssets(levelsFeatures, [singleAsset], buildingSelectedID);
      } else if (assetIsInSelectedBuilding) {
        setShouldRecalculateFloor(true); // set recaculatefloorflag to true so that useFloorSelectionHook logic will run
      }
      cachedAssetRef.current = singleAsset; // set ref to the latest location so we can check against it on next poll.
    }
  }

  function callAssetsAPIIntervalFunction() {
    // This function is called on each poll.
    // using localstorage for token instead of context, as we need the latest token each time, and cant use usecontext inside the interval function.
    const localStorageState: any = localStorage.getItem('aws-amplify-cacheauthState');
    const authState = JSON.parse(localStorageState).data;
    const token = getIdToken(authState);
    const assetsApi = new AssetsApi(token);

    async function callDerivedAPIMethod() {
      // If there is a search or filter set by user, we need to use the respective API call for that for the polling.
      if (inSearchMode) {
        return assetsApi.getRegisteredBeaconMapAssets(selectedVenueID, searchTerm);
      } else if (inFilterMode) {
        return assetsApi.getRegisteredBeaconFilteredMapAssets(
          selectedVenueID,
          assetOwnerCheckedListArray,
          assetSubtypeCheckedListArray,
        );
      } else {
        // if no filter / searches, we call  api with only venuename param.
        return assetsApi.getRegisteredBeaconMapAssets(selectedVenueID);
      }
    }
    setPollAssetsFinished(false);
    setShouldRecalculateFloor(false);

    callDerivedAPIMethod().then((res) => {
      const { items } = res.data;
      const estimatesWithBuilding = getEstimatesWithBuildingAndEstimate(items);

      if (inFilterMode) {
        // apply intersectional filtering if filtermode is active
        const intersectionalFilteredAssets = getIntersectionFilteredAssets(
          estimatesWithBuilding,
          assetOwnerCheckedListArray,
          assetSubtypeCheckedListArray,
        );
        setRawAssets(intersectionalFilteredAssets);
      } else {
        setRawAssets(estimatesWithBuilding);
      }

      if (inSearchMode || inFilterMode) {
        if (estimatesWithBuilding.length === 1)
          handleSingleAssetReturnedFromSearch(estimatesWithBuilding[0]);
      }
      setPollAssetsFinished(true);
    });
  }

  return { callAssetsAPIIntervalFunction };
}
