import { LatestBeacon } from 'types/devices';
import React from 'react';
import { coloursNames } from 'styling/colours';
import { getValueSafely } from 'utils/utils';
import errorIcon from 'assets/icons/error-2.svg';
import { LOW_BATTERY_THRESHOLD } from 'config/constants';

export default function BatterySpan({ assetDetails }: { assetDetails: LatestBeacon }) {
  const isBelowThreshold =
    assetDetails.estimate?.properties?.battery?.battery_percent <= LOW_BATTERY_THRESHOLD;
  const textString = getValueSafely(
    assetDetails.estimate?.properties?.battery?.battery_percent,
    '%',
  );
  return (
    <span
      style={
        isBelowThreshold
          ? { color: coloursNames.cinnabarOrange, display: 'flex', alignItems: 'center' }
          : {}
      }
    >
      {textString}
      {isBelowThreshold && <img style={{ marginLeft: '13px' }} src={errorIcon} alt="low battery" />}
    </span>
  );
}
