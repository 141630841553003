import React, { useState, useEffect } from 'react';
import { StyledError } from './Styled';

function getErrorString(errType: string, errorMsg: string): string {
  switch (errType) {
    case 'maxLength':
      return 'Value too long';
    case 'validate':
      return 'Asset name specified already exists.';
    case 'custom':
      return errorMsg;
    default:
      return '';
  }
}

export default function FieldErrorMessage({ errors }: { errors: any }) {
  const [errorString, setErrorString] = useState('');

  useEffect(() => {
    const errorType = errors.type;
    const errorMsg = errors.message;
    const errorStr = getErrorString(errorType, errorMsg);

    setErrorString(errorStr);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return errorString !== '' ? <StyledError>{errorString}</StyledError> : null;
}
