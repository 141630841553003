import React, { useState, useEffect, useRef, useContext } from 'react';
import DataTableStatusWrapper from 'components/DataTable/DataTableStatusWrapper';
import BeaconsTable from 'components/DataTable/BeaconsTable';
import { newBeaconColumns } from 'components/DataTable/columns/newBeaconColumns';
import ImportConfirmationModal from '../components/modals/ImportConfirmationModal';
import ImportActionModal from '../components/modals/ImportActionModal';
import { useQueryClient } from 'react-query';
import { selectedRowsType } from '../types';
import NewBeaconDialog from '../components/dialogs/NewBeaconDialog';
import NewBeaconSearchForm from '../components/NewBeaconSearch/NewBeaconSearchForm';
import NoDataComponent from 'components/DataTable/NoDataComponent';
import DevicesApi from 'api/devices/devices.api';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken } from 'utils/utils';
import { alertErrorMessage } from 'utils/alerts';
import { BeaconManagementContext } from '../contexts/BeaconManagment.context';
import BeaconUploadFormController from 'components/FileUpload/controllers/BeaconUploadFormController';
import { StyledUploadFormContainer } from '../components/Styled';
import { FullConfigContext } from 'contexts/fullConfig.context';
import { datatableNewBeaconStyle } from 'components/DataTable/DataTableStyling.config';

type TableWithFilterPanelType = {
  query: any;
  selectedTabKey: string;
  setImportCreateSelected: (param: boolean) => void;
  setBeaconsForImport: (param: selectedRowsType) => void;
  beaconsForImport: selectedRowsType;
};
type selectedRowsObjType = {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: selectedRowsType;
};

export default function NewBeaconTabContent({
  query,
  selectedTabKey,
  setImportCreateSelected,
  setBeaconsForImport,
  beaconsForImport,
}: TableWithFilterPanelType) {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [nextToken, setNextToken] = useState('');
  const [paginationIndex, setPaginationIndex] = useState(0); // keep track of pagination clicks
  const [nextIsLoading, setNextIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<selectedRowsType>([]);
  const [preSelectedRows, setPreSelectedRows] = useState<selectedRowsType>([]); // for remembering rows to be selected when returning from import screen to table.
  const [filteredData, setFilteredData] = useState<selectedRowsType>([]);
  const queryClient = useQueryClient();
  const [isImporting, setIsImporting] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<selectedRowsType>([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [selectedBeaconDialogIsOpen, setSelectedBeaconDialogIsOpen] = useState(false);

  const { isRefreshing } = useContext(BeaconManagementContext);
  const authContext = useContext(AuthenticationContext).authState;
  const { beaconIntegration, hasBeaconIntegrations } = useContext(FullConfigContext);
  const token = getIdToken(authContext);
  const devicesApi = new DevicesApi(token);
  const cachedFilteredDataRef = useRef([]);
  const cachedNextTokenRef = useRef('');

  const handleSelectRow = (selectedRowsObj: selectedRowsObjType) => {
    // open dialog if table rows are selected.
    const { selectedRows } = selectedRowsObj;
    const rowsSelected = selectedRows.length > 0;
    setSelectedRows(selectedRows);
    setSelectedBeaconDialogIsOpen(rowsSelected);
  };

  function handleCloseDialog() {
    setSelectedBeaconDialogIsOpen(false);
    setToggleClearRows(!toggledClearRows);
    setPreSelectedRows([]);
  }

  function handleImportCreateAsset() {
    setBeaconsForImport(selectedRows);
    setImportCreateSelected(true);
  }

  function handleModalConfirm(optionSelected: number) {
    switch (optionSelected) {
      case 1: // import only
        setIsImporting(true);
        break;
      case 2: // import and assign to asset
        break;
      case 3: // import and create asset
        handleImportCreateAsset();
        break;
      default:
        break;
    }
  }

  function handleDialogAction() {
    setConfirmationModalOpen(true);
  }

  function getNoDataText() {
    let textString =
      'No beacons found. Please check you have correctly entered the full beacon ID.';

    if (!isSearching) textString = 'No Beacons';
    return textString;
  }

  function repopulateTables() {
    // requeries APIs. and resets the table, selection,modals.
    queryClient.invalidateQueries('new-beacons');
    queryClient.invalidateQueries('registered-beacons');
    setToggleClearRows(!toggledClearRows);
    setConfirmationModalOpen(false);
    setSelectedBeaconDialogIsOpen(false);
    setPaginationIndex(0);
  }

  function fetchNextPage() {
    const currentData = filteredData;

    setConfirmationModalOpen(false);
    setSelectedBeaconDialogIsOpen(false);
    setNextIsLoading(true);
    devicesApi
      .getNewBeacons(beaconIntegration, nextToken)
      .then((res) => {
        const combinedData: any = [...currentData, ...res.data.items];

        setPreSelectedRows(selectedRows);
        setFilteredData(combinedData);
        setNextToken(res.data.next);
        cachedFilteredDataRef.current = combinedData;
        cachedNextTokenRef.current = res.data.next;

        setNextIsLoading(false);
        setPaginationIndex(paginationIndex + 1);
      })
      .catch((err) => {
        console.error(err.response);
        alertErrorMessage('Could not retrieve next page');
        setNextIsLoading(false);
      });
  }

  useEffect(() => {
    // set initial filter data and next token from inital query response.
    if (query.data) {
      const { items, next } = query.data.data;

      setFilteredData(items);
      setNextToken(next);
      setPaginationIndex(0);
      cachedFilteredDataRef.current = items; // set cached version.
      cachedNextTokenRef.current = next;
    } else if (query.isError) {
      alertErrorMessage('Could not fetch Beacons');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.status, query.data]);

  useEffect(() => {
    // set preselect rows for when cancelling and returning from import & Create asset screen
    if (beaconsForImport.length > 0) {
      setPreSelectedRows(beaconsForImport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set filtered data to search results if there are matches.
    if (isSearching) {
      setFilteredData(searchResults);
    } else {
      // otherwise revert tabledata & nextToken to cached ref we used on inital load.
      setFilteredData(cachedFilteredDataRef.current);
      setNextToken(cachedNextTokenRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching]);

  useEffect(() => {
    // reset dialogs and selected rows on tab change
    setSelectedBeaconDialogIsOpen(false);
    setToggleClearRows(!toggledClearRows);
    setBeaconsForImport([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabKey]);

  useEffect(() => {
    // unselect beacons and clear rows on refresh.
    if (isRefreshing) {
      setToggleClearRows(!toggledClearRows);
      setPreSelectedRows([]);
      setSearchTerm('');
      setIsSearching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshing]);

  return (
    <>
      <StyledUploadFormContainer>
        <BeaconUploadFormController />
      </StyledUploadFormContainer>
      {hasBeaconIntegrations && ( // we only display searchbar and table if there are existing integrations.
        <>
          <NewBeaconSearchForm
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setIsSearching={setIsSearching}
            setSearchResults={setSearchResults}
            setNextToken={setNextToken}
            setPreSelectedRows={setPreSelectedRows}
          />

          <DataTableStatusWrapper error={query.error} status={query.status}>
            {query.data && (
              <BeaconsTable
                customStyles={datatableNewBeaconStyle}
                columns={newBeaconColumns}
                clearSelectedRows={toggledClearRows}
                handleSelectRow={handleSelectRow}
                data={filteredData}
                fetchNextPage={fetchNextPage}
                nextIsLoading={nextIsLoading}
                selectableRows
                disableSort
                preSelectedRows={preSelectedRows}
                nextToken={nextToken}
                totalCount={query.data.data.total}
                paginationIndex={paginationIndex}
                selectedBeaconDialogIsOpen={selectedBeaconDialogIsOpen}
                noDataComponent={!query.error && <NoDataComponent text={getNoDataText()} />}
              />
            )}
          </DataTableStatusWrapper>
          {confirmationModalOpen && !isImporting && (
            <ImportConfirmationModal
              selectedCount={selectedRows.length}
              handleModalConfirm={handleModalConfirm}
              setConfirmationModalOpen={setConfirmationModalOpen}
            />
          )}

          {isImporting && (
            <ImportActionModal
              selectedRows={selectedRows}
              repopulateTables={repopulateTables}
              setIsImporting={setIsImporting}
              setSearchTerm={setSearchTerm}
              setIsSearching={setIsSearching}
            />
          )}

          {selectedBeaconDialogIsOpen && (
            <NewBeaconDialog
              handleCloseDialog={handleCloseDialog}
              selectedCount={selectedRows.length}
              handleDialogAction={handleDialogAction}
            />
          )}
        </>
      )}
    </>
  );
}
