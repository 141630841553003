import useAssetConfig from 'hooks/useAssetConfig.hook';
import React from 'react';

export default function AssetOwnerCell({ value }: { value: string }) {
  const { getLabelFromAssetConfig } = useAssetConfig();

  const label = getLabelFromAssetConfig(value, 'owner');

  return <>{label}</>;
}
