import React, { useContext } from 'react';
import { colours } from 'styling/colours';
import { format } from 'date-fns';
import { getLastSeenString, getValueSafely } from 'utils/utils';
import LastSeenCell from '../../../../components/DataTable/cells/LastSeenCell';
import { LatestBeacon } from 'types/devices';
import AssetOwnerCell from 'components/DataTable/cells/AssetOwnerCell';
import infoImg from '../../icons/infoBg.svg';
import { VenuesContext } from 'contexts/venues.context';
import { StyledTable } from 'views/AssetListPage/components/styles';
import AccuracyRangeSpan from 'views/AssetListPage/components/content/AccuracyRangeSpan';
import LocationSpan from 'views/AssetListPage/components/content/LocationSpan';
import BatteryReadoutSpan from '../InfoBoxSpans/BatteryReadoutSpan';

const MobileAssetInfoFlyoutContent = ({
  selectedAssetDetails,
}: {
  selectedAssetDetails: LatestBeacon;
}) => {
  const deviceSource = selectedAssetDetails.device?.source;

  const { isSingleVenue } = useContext(VenuesContext);

  return (
    <>
      <StyledTable data-test-id="asset-details-id">
        <tbody>
          <tr>
            <td>Asset ID:</td>
            <td>{getValueSafely(selectedAssetDetails.entity?.entity_id)}</td>
          </tr>
          <tr>
            <td>Added On:</td>
            <td>{format(new Date(selectedAssetDetails.entity.created), 'dd/LL/yyyy - HH:mm')}</td>
          </tr>
          <tr>
            <td>Owner:</td>
            <td>
              <AssetOwnerCell value={selectedAssetDetails.entity?.properties?.owner} />
            </td>
          </tr>
          <tr>
            <td valign="top">Description:</td>
            <td id="assetDetailsDescription">
              {getValueSafely(selectedAssetDetails.entity.properties.description)}
            </td>
          </tr>
        </tbody>
      </StyledTable>
      <div className="hr" />
      <div className="information-sentance">
        <img src={infoImg} alt="information" />
        <p>Please note, estimated information was derived from the beacon.</p>
      </div>
      <StyledTable data-test-id="asset-details-estimate">
        <tbody>
          <tr>
            <td>Last Seen:</td>
            <td>
              <LastSeenCell timestamp={selectedAssetDetails.estimate?.timestamp} />
              {` ${getLastSeenString(selectedAssetDetails.estimate?.timestamp)}`}
            </td>
          </tr>
          <tr>
            <td>Accuracy Range:</td>
            <td>
              <AccuracyRangeSpan assetDetails={selectedAssetDetails} />{' '}
            </td>
          </tr>
          <tr>
            <td>Status:</td>
            <td style={{ textTransform: 'capitalize' }}>
              {getValueSafely(selectedAssetDetails.estimate?.properties?.mobility_state)}
            </td>
          </tr>
          <tr>
            <td>Battery:</td>
            <td>
              <BatteryReadoutSpan asset={selectedAssetDetails} />
            </td>
          </tr>
          <tr>
            <td valign="top">Location:</td>
            <td>
              {!isSingleVenue && (
                <div style={{ marginBottom: '15px' }}>
                  {selectedAssetDetails.estimate?.location?.venue?.name}
                </div>
              )}
              <LocationSpan assetDetails={selectedAssetDetails} />
              <div className="region">
                <div style={{ color: colours.secondaryText, margin: '15px 0 0px 0' }}>
                  Possible region:
                </div>
                <div>{getValueSafely(selectedAssetDetails.estimate?.location?.region?.name)}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Beacon ID:</td>
            <td>{getValueSafely(selectedAssetDetails.device?.device_id)}</td>
          </tr>
          <tr>
            <td>Beacon Type:</td>
            <td>
              {getValueSafely(selectedAssetDetails.device?.properties?.product)}
              {deviceSource && ` - ${deviceSource}`}
            </td>
          </tr>
        </tbody>
      </StyledTable>
    </>
  );
};

export default MobileAssetInfoFlyoutContent;
