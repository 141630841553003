import React from 'react';
import DownloadIcon from 'assets/icons/download-outlined.svg';
import Button from 'components/Button/Button';
import { Progress } from 'antd';

export default function ModalValidFileContent({
  isAssetUpload,
  handleConfirmUpload,
  fileName,
  uploadPercentage,
  uploadInProgress,
  uploadFailed,
  handleCancel,
}: {
  handleConfirmUpload: () => void;
  isAssetUpload: boolean;
  fileName: string;
  uploadPercentage: number;
  uploadInProgress: boolean;
  uploadFailed: boolean;
  handleCancel: () => void;
}) {
  function getModalText(uploadFailed: boolean) {
    return uploadFailed
      ? 'Oops, something went wrong. Please click retry to attempt uploading the file again.'
      : "Please confirm to start the upload and don't close this popup until upload is finished.";
  }
  return (
    <>
      <header>
        <img style={{ width: '40px', height: '40px' }} src={DownloadIcon} alt="upload" />
        <h3>Bulk {isAssetUpload ? 'Assets' : 'Beacons'} Upload</h3>
      </header>
      <p style={{ fontSize: '15px' }}>{getModalText(uploadFailed)}</p>
      <div className="progress-info">
        <span>
          <b style={{ color: '#414042', maxWidth: '363px', display: 'block' }}>{fileName} file</b>
        </span>
        <span>{uploadPercentage} %</span>
      </div>
      <Progress percent={uploadPercentage} showInfo={false} />
      <footer>
        <Button
          data-test-id="cancel-bulk-upload"
          disabled={uploadInProgress}
          onClick={() => handleCancel()}
          outline
        >
          Cancel
        </Button>
        <Button isLoading={uploadInProgress} onClick={() => handleConfirmUpload()}>
          {uploadFailed ? 'Retry' : 'Confirm'}
        </Button>
      </footer>
    </>
  );
}
