import UserApi from 'api/user/user.api';
import { UsersContext } from '../contexts/users.context';
import { useContext } from 'react';
import { getIdToken } from 'utils/utils';
import { getUsersWithEstimate } from '../utils/utils';
import { alertErrorMessage } from 'utils/alerts';

export default function useCallUsersAPIIntervalFunction() {
  const { setUsersMarkers } = useContext(UsersContext);

  function callUsersAPIIntervalFunction() {
    // using localstorage for token instead of context, as we need the latest token each time, and cant use usecontext inside the interval function.
    const localStorageState: any = localStorage.getItem('aws-amplify-cacheauthState');
    const authState = JSON.parse(localStorageState).data;
    const token = getIdToken(authState);
    const userApi = new UserApi(token);

    userApi
      .getUsersMapLastHour()
      .then((res) => {
        const { items } = res.data;
        const filteredItems = getUsersWithEstimate(items);

        setUsersMarkers(filteredItems);
      })
      .catch((err) => {
        alertErrorMessage('Could not fetch Users');
        console.error(err.message);
      });
  }
  return { callUsersAPIIntervalFunction };
}
