import { FRESH_ESTIMATE_IN_SECONDS_THRESHOLD } from 'config/constants';
import { differenceInSeconds, format } from 'date-fns';
import React from 'react';
import { colours } from 'styling/colours';

const FormattedDateCell = ({ timestamp }: { timestamp: number }) => {
  if (!timestamp) return <span>--</span>;

  const formattedDate = format(new Date(timestamp), 'dd MMM yyyy, HH:mm');
  const showRedHighlight =
    differenceInSeconds(new Date(), new Date(timestamp)) > FRESH_ESTIMATE_IN_SECONDS_THRESHOLD;

  return (
    <span
      className="u-no-pointer-event"
      style={showRedHighlight ? { color: `${colours.error}` } : {}}
    >
      {formattedDate}
    </span>
  );
};

export default FormattedDateCell;
