import { FRESH_ESTIMATE_IN_SECONDS_THRESHOLD } from 'config/constants';
import { VenuesContext } from 'contexts/venues.context';
import { differenceInSeconds } from 'date-fns';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import { LatestAssetResults } from 'types/combinedEstimatesAssets';
import { UserType } from 'types/userInfo';

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  div:first-of-type {
    margin-right: 5px;
  }

  @media (max-width: 1800px) {
    flex-direction: column;
    div:first-of-type {
      margin-left: initial;
    }
  }
`;

const LocationCell = (row: LatestAssetResults | UserType) => {
  const { isSingleVenue } = useContext(VenuesContext);
  let showError = false;

  if (
    differenceInSeconds(new Date(), new Date(row.estimate?.timestamp)) >
    FRESH_ESTIMATE_IN_SECONDS_THRESHOLD
  ) {
    showError = true;
  }
  if (row.estimate?.location !== undefined) {
    return (
      <div className="u-no-pointer-event" data-tag="allowRowEvents">
        {!isSingleVenue && (
          <div data-test-id="venue-name" style={{ color: showError ? `${colours.error}` : '' }}>
            {row.estimate.location?.venue.name}
          </div>
        )}
        <StyledDiv>
          <div data-test-id="floor-name" style={{ color: showError ? `${colours.error}` : '' }}>
            {row.estimate.location?.building?.building_level_name}/
            {row.estimate.location?.building?.building_names}
          </div>
          <div data-test-id="region-name" style={{ color: showError ? `${colours.error}` : '' }}>
            {row.estimate.location?.region?.name}
          </div>
        </StyledDiv>
      </div>
    );
  }
  return <>––</>;
};
export default LocationCell;
