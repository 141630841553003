import DevicesApi from 'api/devices/devices.api';
import { AuthenticationContext } from 'contexts/authentication.context';
import { useContext, useRef, useState } from 'react';
import { getIdToken } from 'utils/utils';
import { BeaconManagementContext } from 'views/BeaconManagementPage/contexts/BeaconManagment.context';
import { alertErrorMessage } from 'utils/alerts';
import { apiAssetAssignEnum } from 'views/BeaconManagementPage/enum';

export default function useRegisteredBeaconSearchHandlers() {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [inputHasFocus, setInputHasFocus] = useState(false);

  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const devicesApi = new DevicesApi(token);

  const {
    searchTerm,
    setSearchTerm,
    setIsSearching,
    setIsSearchingAll,
    searchInProgress,
    setSearchInProgress,
    setFilteredData,
    setNextToken,
    assetAssignOptionSelectedObj,
    setSelectedSearchMenuOptionLabel,
    setSelectedSearchMenuOptionValue,
    activeSortField,
    activeSortDirection,
    setPaginationIndex,
    setAssetTotal,
  } = useContext(BeaconManagementContext);
  const selectedSearchMenuOptionLabelRef = useRef('');

  function handleClickOptionSearch(selectedOptionObj: { value: string; label: string }) {
    if (searchTerm.length < 1) return;

    const termTrimmed = searchTerm.trim();

    setSearchTerm(termTrimmed);
    setSelectedSearchMenuOptionValue(selectedOptionObj.value);
    setSelectedSearchMenuOptionLabel(selectedOptionObj.label);
    selectedSearchMenuOptionLabelRef.current = selectedOptionObj.label;
    setSearchInProgress(true);
    setIsSearchingAll(false); // searching all used for sort call.
    setIsSearching(false);

    devicesApi
      .getSearchSingleOptionRegisteredBeacons(
        searchTerm,
        selectedOptionObj.value,
        apiAssetAssignEnum[assetAssignOptionSelectedObj.value],
        activeSortField,
        activeSortDirection,
      )
      .then((res: any) => {
        const { items, next, total } = res.data;
        // note we do not set refs when searching.

        setNextToken(next);
        setAssetTotal(total);
        setFilteredData(items);
        setPaginationIndex(0); // reset pagination on search

        setSearchInProgress(false);
        setIsSearching(true);
        setMenuIsOpen(false);
        setInputHasFocus(false);
      })
      .catch((err: any) => {
        setSearchInProgress(false);
        setIsSearching(false);
        alertErrorMessage(`Search Failed: ${err.message}`);
        console.error(err.message);
      });
  }

  function handleEnterSearch(ev: any) {
    if (ev.key !== 'Enter') return; // only call search on enter key.
    if (searchTerm.length < 1) return;

    const termTrimmed = searchTerm.trim();

    setSearchTerm(termTrimmed);
    setSearchInProgress(true);
    setSelectedSearchMenuOptionLabel('');
    selectedSearchMenuOptionLabelRef.current = '';

    setIsSearchingAll(true); // searching all used for sort call.
    setIsSearching(false);

    devicesApi
      .getSearchAllOptionsRegisteredBeacons(
        termTrimmed,
        apiAssetAssignEnum[assetAssignOptionSelectedObj.value],
        activeSortField,
        activeSortDirection,
      )
      .then((res: any) => {
        const { items, next, total } = res.data;
        // note we do not set refs when searching.
        setNextToken(next);
        setFilteredData(items);
        setAssetTotal(total);
        setPaginationIndex(0); // reset pagination on search
        setSearchInProgress(false);
        setIsSearching(true);
        setMenuIsOpen(false);
      })
      .catch((err: any) => {
        setSearchInProgress(false);
        setIsSearching(false);
        alertErrorMessage(`Search Failed: ${err.message}`);
        console.error(err.message);
      });
  }

  function handleClearField() {
    setSearchInProgress(true);

    devicesApi
      .getRegisteredBeacons(
        apiAssetAssignEnum[assetAssignOptionSelectedObj.value],
        null,
        activeSortField,
        activeSortDirection,
      )
      .then((res) => {
        const { items, next, total } = res.data;

        setNextToken(next);
        setFilteredData(items);
        setAssetTotal(total);
        setPaginationIndex(0);
        setSearchInProgress(false);
      })
      .catch((err: any) => {
        setSearchInProgress(false);
        setIsSearching(false);
        console.error(err.message);
      });
    setSearchTerm('');
    setSelectedSearchMenuOptionLabel('');
    selectedSearchMenuOptionLabelRef.current = '';
    setIsSearching(false);
  }

  return {
    handleClearField,
    searchInProgress,
    handleEnterSearch,
    handleClickOptionSearch,
    menuIsOpen,
    setMenuIsOpen,
    selectedSearchMenuOptionLabelRef,
    inputHasFocus,
    setInputHasFocus,
  };
}
