import React, { useContext } from 'react';
import { getIdToken } from 'utils/utils';
import { SearchBarStyled } from '../Styled';
import { BeaconSelectOptionType } from '../../types';
import DevicesApi from 'api/devices/devices.api';
import { AuthenticationContext } from 'contexts/authentication.context';
import RegisteredBeaconAsyncSelect from './RegisteredBeaconAsyncSelect';
import { apiAssetAssignEnum } from 'views/BeaconManagementPage/enum';
import RegisteredBeaconAssignedInputSelect from './RegisteredBeaconAssignedInputSelect';
import { BeaconManagementContext } from 'views/BeaconManagementPage/contexts/BeaconManagment.context';

export default function RegisteredBeaconSearchSelectForm() {
  const {
    setFilteredData,
    setSearchTerm,
    isSearching,
    setIsSearching,
    setAssetAssignOptionSelectedObj,
    setNextToken,
    activeSortField,
    activeSortDirection,
    setAssignOptionHasNoResults,
    cachedFilteredDataRef,
    nextTokenCachedRef,
    assetTotalRef,
    setPaginationIndex,
    paginationIndexRef,
    setAssetTotal,
    setSearchInProgress,
  } = useContext(BeaconManagementContext);

  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const deviceApi = new DevicesApi(token);

  function handleSelectAssignmentChange(val: BeaconSelectOptionType) {
    // changing assignment should clear search if there was a search term.
    setAssetAssignOptionSelectedObj(val);
    setSearchInProgress(true);

    deviceApi
      .getRegisteredBeacons(
        apiAssetAssignEnum[val.value],
        null,
        activeSortField,
        activeSortDirection,
      )
      .then((res: any) => {
        const { items, next, count, total } = res.data;

        setFilteredData(items);
        setNextToken(next);
        setAssetTotal(total);
        setPaginationIndex(0);

        setSearchTerm('');
        setIsSearching(false);
        setAssignOptionHasNoResults(count === 0);
        setSearchInProgress(false);

        if (!isSearching) {
          cachedFilteredDataRef.current = items;
          nextTokenCachedRef.current = next;
          assetTotalRef.current = total;
          paginationIndexRef.current = 0;
        }
      })
      .catch((err: any) => {
        setIsSearching(false);
        console.error(err);
      });
  }

  return (
    <SearchBarStyled>
      <RegisteredBeaconAsyncSelect placeholder="Search for beacons" width={350} />
      <RegisteredBeaconAssignedInputSelect
        handleSelectAssignmentChange={handleSelectAssignmentChange}
      />
    </SearchBarStyled>
  );
}
