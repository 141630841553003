import { useContext } from 'react';
import { LatestBeacon } from 'types/devices';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { checkAssetsShouldDisplayConditions } from '../utils/utils';

export default function useFilteredAssets() {
  const { apiCallInProgress, showAssetsChecked } = useContext(MapControlContext);

  // a simple function to return all assets passed in, or empty array depending if a condition is not met.
  // note that this function depends on context being up to date. If this is used before a context is updated, it will not have correct values.
  function getVisibleAssets(assets: LatestBeacon[]) {
    const assetsShouldDisplay = checkAssetsShouldDisplayConditions(
      [showAssetsChecked, true],
      [apiCallInProgress, false],
    );

    return assetsShouldDisplay ? assets : [];
  }

  return getVisibleAssets;
}
