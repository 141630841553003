import { latLngBounds, LatLngExpression } from 'leaflet';
import { useMap } from 'react-leaflet';
import { getCoordsFromAssets, getCoordsFromUsers } from '../utils/utils';
import L from 'leaflet';
import { LatestBeacon } from 'types/devices';
import { UserType } from 'types/userInfo';

export default function useMapHooks() {
  const map = useMap(); // note: requires components to be decendant of mapcontainer to invoke this

  function recenterMapToCombinedMarkerBounds(assets: LatestBeacon[], users: UserType[]) {
    if (assets.length < 1 && users.length < 1) return;

    const assetCoords: any = getCoordsFromAssets(assets);
    const userCoords: any = getCoordsFromUsers(users);
    const combinedCoords = [...assetCoords, ...userCoords];
    const bounds = latLngBounds(assetCoords[0]);

    bounds.extend(combinedCoords);

    map.panInsideBounds(bounds, { animate: false });
  }

  function recenterMapToAssetMarkerBounds(assets: LatestBeacon[]) {
    if (assets.length < 1) return;

    const assetCoords: any = getCoordsFromAssets(assets);
    const bounds = latLngBounds(assetCoords[0]);

    bounds.extend(assetCoords);

    map.panInsideBounds(bounds, {
      noMoveStart: true,
    });
  }

  function removeMapLayerByClassName(className: string) {
    // programatically remove map tooltips,
    map.eachLayer((layer: any) => {
      if (layer.options && layer.options.className === className) {
        map.removeLayer(layer);
      }
    });
  }

  function fitMapToBoundsOfGeoJSON(geoJSONData: any, maxZoom: number = 21) {
    const geojsonLayer = L.geoJSON(geoJSONData);
    map.fitBounds(geojsonLayer.getBounds(), { padding: [50, 50], maxZoom: maxZoom });
  }

  function panToLatLong(eTargetLatLong: LatLngExpression) {
    map.panTo(eTargetLatLong);
  }

  function zoomMapToSingleAsset(asset: LatestBeacon) {
    const latLong: any = [...asset.estimate.location.coordinates.coordinates].reverse(); // reversed to correct order

    map.flyTo(latLong, 20);
  }

  function checkAssetLatLngIsWithinMapBounds(assetLatLongArr: any) {
    const reversedCoords: any = [...assetLatLongArr].reverse(); // reversed to correct order

    return map.getBounds().contains(reversedCoords);
  }

  return {
    removeMapLayerByClassName,
    fitMapToBoundsOfGeoJSON,
    panToLatLong,
    zoomMapToSingleAsset,
    recenterMapToAssetMarkerBounds,
    recenterMapToCombinedMarkerBounds,
    checkAssetLatLngIsWithinMapBounds,
  };
}
