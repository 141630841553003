import React from 'react';
import BatteryCell from '../cells/BatteryCell';
import LocationCell from '../cells/LocationCell';
import ArrowCell from '../cells/ArrowCell';
import { LatestAssetResults } from 'types/combinedEstimatesAssets';
import DefaultCell from 'components/DataTable/cells/DefaultCell';
import LastSeenCell from 'components/DataTable/cells/LastSeenCell';
import AssetSubtypeCell from '../cells/AssetSubtypeCell';
import AssetOwnerCell from '../cells/AssetOwnerCell';

export const assetTableColumns: any[] = [
  {
    name: 'ASSET NAME',
    selector: (row: LatestAssetResults) => <DefaultCell value={row.entity.properties.name} />,
    sortable: true,
    minWidth: '240px',
    maxWidth: '410px',
    sortField: 'properties.name',
  },
  {
    name: 'ASSET TYPE',
    selector: (row: LatestAssetResults) => <AssetSubtypeCell value={row.entity.subtype} />,
    sortable: true,
    minWidth: '180px',
    maxWidth: '240px',
    overflow: 'false',
    sortField: 'subtype',
  },
  {
    name: 'ASSET ID',
    selector: (row: LatestAssetResults) => <DefaultCell value={row.entity?.entity_id} />,
    sortable: true,
    minWidth: '230px',
    maxWidth: '410px',
    overflow: 'false',
    sortField: 'entity_id',
  },
  {
    name: 'LAST SEEN',
    sortable: true,
    minWidth: '140px',
    maxWidth: '140px',

    cell: (row: LatestAssetResults) =>
      row.estimate ? <LastSeenCell timestamp={row.estimate?.timestamp} /> : '––',
    sortField: 'estimate.timestamp',
  },
  {
    name: 'STATUS',
    selector: (row: LatestAssetResults) => (
      <DefaultCell value={row.estimate?.properties?.mobility_state} />
    ),
    sortable: true,
    minWidth: '140px',
    maxWidth: '140px',
    overflow: 'false',
    sortField: 'estimate.properties.mobility_state',
  },
  {
    name: 'LOCATION',
    cell: (row: LatestAssetResults) => <LocationCell {...row} />,
    sortable: true,
    overflow: 'false',
    minWidth: '260px',
    maxWidth: '410px',
    sortField: 'estimate.location.region.name',
  },
  {
    name: 'OWNER',
    sortable: true,
    selector: (row: LatestAssetResults) => <AssetOwnerCell value={row.entity.properties?.owner} />,
    sortField: 'properties.owner',
    minWidth: '160px',
    maxWidth: '200px',
  },
  {
    name: 'BATTERY',
    cell: (row: LatestAssetResults) => <BatteryCell {...row} />,
    sortable: true,
    sortField: 'estimate.properties.battery.battery_percent',
    minWidth: '120px',
    maxWidth: '120px',
  },
  {
    right: true,
    sortable: false,
    cell: () => <ArrowCell />,
    sortField: '',
    width: '10px',
  },
];
