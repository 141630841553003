import React, { useState } from 'react';
import VenueFilesProvider from './contexts/venuefiles.context';
import AssetsProvider from './contexts/assets.context';
import MapControlProvider from './contexts/mapcontrol.context';
import UsersProvider from './contexts/users.context';
import { StyledMapFilterContainer } from './Styled';
import AssetMapTopFilterBar from './components/AssetMapTopFilterBar';
import MapContainerWrapper from './wrappers/MapContainerWrapper';
import VenuesProvider from './contexts/venues.context';
import SearchFilterProvider from './contexts/searchFilter.context';
import HeaderDefault from 'components/Header/HeaderDefault';
import PublishStateToGlobalContextWrapper from './wrappers/PublishStateToGlobalContextWrapper';
import AssetMapSearchFilterForm from './components/SearchFilterBar/AssetMapSearchFilterForm';
import MapContainerRefProvider from './contexts/mapContainerRef.context';
import URLParamProvider from './contexts/urlParam.context';
import useDevice from 'hooks/useDevice';
import MobileSelectFiltersButton from './components/mobile/MobileSelectFiltersButton';
import MobileMapSearchFilterPanel from './components/mobile/MobileMapSearchFilterPanel';

export default function MapPage() {
  const [mobileMapFilterMenuOpen, setMobileMapFilterMenuOpen] = useState(false);
  const { isMobileDevice } = useDevice();

  function handleMobileFilterClick() {
    setMobileMapFilterMenuOpen(!mobileMapFilterMenuOpen);
  }

  return (
    <>
      <HeaderDefault />

      <main style={{ overflowY: isMobileDevice ? 'hidden' : 'initial' }}>
        <div
          className="container"
          style={{
            height: !isMobileDevice ? '100%' : 'initial',
            overflowY: !isMobileDevice ? 'auto' : 'hidden',
          }}
        >
          <URLParamProvider>
            <VenuesProvider>
              <VenueFilesProvider>
                <MapControlProvider>
                  <SearchFilterProvider>
                    <AssetsProvider>
                      <UsersProvider>
                        <PublishStateToGlobalContextWrapper>
                          <MapContainerRefProvider>
                            {isMobileDevice ? (
                              <MobileMapSearchFilterPanel
                                mobileMapFilterMenuOpen={mobileMapFilterMenuOpen}
                                setMobileMapFilterMenuOpen={setMobileMapFilterMenuOpen}
                              />
                            ) : (
                              <AssetMapSearchFilterForm />
                            )}

                            <StyledMapFilterContainer>
                              {!isMobileDevice && <AssetMapTopFilterBar />}
                              {isMobileDevice && (
                                <MobileSelectFiltersButton onClick={handleMobileFilterClick} />
                              )}
                              <MapContainerWrapper />
                            </StyledMapFilterContainer>
                          </MapContainerRefProvider>
                        </PublishStateToGlobalContextWrapper>
                      </UsersProvider>
                    </AssetsProvider>
                  </SearchFilterProvider>
                </MapControlProvider>
              </VenueFilesProvider>
            </VenuesProvider>
          </URLParamProvider>
        </div>
      </main>
    </>
  );
}
