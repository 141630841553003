import EntitiesApi from 'api/entities/entities.api';
import { AssetManagementContext } from 'contexts/assetManagement.context';
import { AuthenticationContext } from 'contexts/authentication.context';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { alertErrorMessage, alertSuccessMessage } from 'utils/alerts';
import { getAddAssetSuccessMessage, getIdToken } from 'utils/utils';

export default function useAssetManagementHooks(
  handleSubmit: any,
  formFieldsValid: boolean,
  setError: any,
  getValues: any,
) {
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();
  const { assetFormHasChanges, setShowCancelConfirmationDialog } =
    useContext(AssetManagementContext);

  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const entitiesApi = new EntitiesApi(token);

  const createAssetMutation = useMutation((assetDetails: any) => {
    return entitiesApi.createNewAsset(assetDetails);
  });

  function handleCancel(ev: React.SyntheticEvent) {
    ev.preventDefault();
    // The allFieldsEmpty is there to allow for the user
    // starting to add an asset and then clearing everything
    // before pressing cancel In this case they are taken to '/'
    if (assetFormHasChanges) {
      setShowCancelConfirmationDialog(true);
    } else {
      navigate('/assets');
    }
  }

  function handleCloseModal() {
    setShowCancelConfirmationDialog(false);
    navigate('/assets');
  }

  function handleCloseModalAndSave() {
    // submit form and close modal.
    setShowCancelConfirmationDialog(false);
    if (formFieldsValid) formSubmitHandler(getValues());
  }

  const formSubmitHandler = handleSubmit((formData: any) => {
    const assetNameTrimmed = formData.assetName.trim();
    const trimmedFormData = {
      ...formData,
      assetName: assetNameTrimmed,
    };
    setFormSubmitted(true);
    setApiCallInProgress(true);

    entitiesApi
      .checkAssetExistsByAssetName(assetNameTrimmed)
      .then(async (assetExists) => {
        if (assetExists) {
          setError('assetName', {
            type: 'validate',
            message: 'Asset name specified already exists.',
          });
          setApiCallInProgress(false);
          return;
        } else {
          createAssetMutation.mutate(getPostAssetDataObject(trimmedFormData));
        }
      })
      .catch((err) => {
        console.log(err);
        alertErrorMessage('An error occurred. The asset has not been saved');
        setApiCallInProgress(false);
      });
  });

  const getPostAssetDataObject = (formData: any) => {
    return {
      modified: new Date().getTime(),
      type: 'asset',
      entity_id: formData.assetId,
      subtype: formData.assetSubType.value,
      properties: {
        description: formData.description,
        owner: formData.assetOwner.value,
        name: formData.assetName,
      },
    };
  };

  useEffect(() => {
    //useeffect to execute mutation state outcomes.

    if (createAssetMutation.isSuccess) {
      const assetSuccessMessage = getAddAssetSuccessMessage(createAssetMutation);

      navigate('/assets');
      alertSuccessMessage(assetSuccessMessage);
    }

    if (createAssetMutation.isError) {
      createAssetMutation.reset();
      setApiCallInProgress(false);
      alertErrorMessage('An error occurred. The asset has not been saved');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAssetMutation]);

  return {
    formSubmitHandler,
    createAssetMutation,
    getPostAssetDataObject,
    apiCallInProgress,
    formSubmitted,
    setFormSubmitted,
    handleCancel,
    handleCloseModal,
    handleCloseModalAndSave,
  };
}
