import React, { SyntheticEvent, useState } from 'react';

import FieldErrorMessage from './FieldErrorMessage';
import MaxCharacterReadout from './MaxCharacterReadout';
import { StyledLabel, RequiredAsterisk, StyledTextarea } from './Styled';

type FormTextareaGroupType = {
  register: any;
  errors: any;
  fieldID: string;
  fieldName: string;
  isRequired?: boolean;
  validationObj?: any;
  type?: string;
  defaultValue?: string;
  showErrors?: boolean;
  clearErrors: any;
};

export default function FormTextareaGroup({
  register,
  errors,
  fieldID,
  fieldName,
  isRequired,
  validationObj,
  defaultValue,
  type,
  showErrors,
  clearErrors,
}: FormTextareaGroupType) {
  const [typedInputLength, setTypedInputLength] = useState(0);
  const { maxLength, minLength } = validationObj;

  function handleInputChange(e: React.SyntheticEvent) {
    const target = e.target as HTMLTextAreaElement;

    clearErrors(fieldID); // clear error of field that has been changed.
    setTypedInputLength(target.value.length);
  }

  return (
    <fieldset style={{ marginBottom: '40px' }}>
      <StyledLabel htmlFor={fieldID}>
        {fieldName}: {isRequired && <RequiredAsterisk />}
      </StyledLabel>
      <StyledTextarea
        type={type}
        id={fieldID}
        defaultValue={defaultValue}
        className={showErrors ? 'error' : ''}
        maxLength={maxLength}
        {...register(fieldID, {
          onChange: (e: SyntheticEvent) => handleInputChange(e),
          ...validationObj,
        })}
      />
      {errors[fieldID] && showErrors && <FieldErrorMessage errors={errors[fieldID]} />}
      {maxLength && (
        <MaxCharacterReadout
          minLength={minLength}
          maxLength={maxLength}
          typedInputLength={typedInputLength}
          fieldID={fieldID}
        />
      )}
    </fieldset>
  );
}
