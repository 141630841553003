import React, { useContext, useState } from 'react';
import format from 'date-fns/format';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import { alertErrorMessage } from 'utils/alerts';
import Button from 'components/Button/Button';
import { BeaconManagementContext } from '../contexts/BeaconManagment.context';

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 10px;
  span {
    margin-right: 10px;
    color: ${colours.secondaryText};
  }
`;

export default function RefreshTimestampAndButton() {
  const [time, setTime] = useState(getTimeString());
  const { isRefreshing, setIsRefreshing, tabIsSetRef, setSearchInProgress } =
    useContext(BeaconManagementContext);

  const queryClient = useQueryClient();

  function getTimeString() {
    const timeNow = new Date();
    const year = format(timeNow, 'dd/MM/yyyy');
    const time = format(timeNow, 'HH.mm');
    return `${time} on ${year}`;
  }

  async function handleRefresh() {
    const queries = ['new-beacons', 'registered-beacons'];
    let index = 0;
    setSearchInProgress(true);
    setIsRefreshing(true);
    tabIsSetRef.current = false;

    for await (const query of queries) {
      queryClient
        .invalidateQueries(query)
        .then(() => {
          index++;
          if (index === queries.length) {
            setIsRefreshing(false);
            setSearchInProgress(false);
            setTime(getTimeString());
          }
        })
        .catch((err) => {
          console.error(err.response);
          alertErrorMessage('Could not refresh');
          setIsRefreshing(false);
          setSearchInProgress(false);
        });
    }
  }

  return (
    <StyledContainer>
      <span>Last updated at {time}</span>
      <Button outline isLoading={isRefreshing} onClick={handleRefresh}>
        Refresh
      </Button>
    </StyledContainer>
  );
}
