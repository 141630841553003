import React, { useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from 'contexts/authentication.context';
import { alertErrorMessage } from 'utils/alerts';
import { getIdToken } from 'utils/utils';
import UserApi from 'api/user/user.api';
import { recentUsersColumns } from 'components/DataTable/columns/recentUsersColumns';
import DataTableStatusWrapper from 'components/DataTable/DataTableStatusWrapper';
import NoDataComponent from 'components/DataTable/NoDataComponent';
import RecentUsersTable from 'components/DataTable/RecentUsersTable';
import { UserType } from 'types/userInfo';

export default function RecentUsersTableContainer({
  setTotalCount,
  totalCount,
}: {
  setTotalCount: (arg: number) => void;
  totalCount?: number;
}) {
  const [usersList, setUsersList] = useState<UserType[]>([]);
  const [nextToken, setNextToken] = useState(undefined);
  const [apiCallInProgress, setApiCallInProgress] = useState(true);
  const [fetchingNextPage, setFetchingNextPage] = useState(false);
  const [paginationIndex, setPaginationIndex] = useState(0);

  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const userApi = new UserApi(token);

  function handlePagination() {
    setFetchingNextPage(true);

    userApi
      .getUsersActiveLastHour(nextToken)
      .then((res: any) => {
        const { items, next } = res.data;
        const combinedResults: UserType[] = [...usersList, ...items];

        setUsersList(combinedResults);
        setNextToken(next);
        setApiCallInProgress(false);
        setFetchingNextPage(false);
        setPaginationIndex(paginationIndex + 1);
      })
      .catch((err) => {
        alertErrorMessage('Could not fetch Users');
        setApiCallInProgress(false);
        setFetchingNextPage(false);
        console.error(err);
      });
  }

  useEffect(() => {
    setApiCallInProgress(true);

    userApi
      .getUsersActiveLastHour()
      .then((res) => {
        const { items, next, total } = res.data;

        setUsersList(items);
        setTotalCount(total);
        setNextToken(next);
        setApiCallInProgress(false);
      })
      .catch((err) => {
        alertErrorMessage('Could not fetch Users');
        console.error(err.message);
        setApiCallInProgress(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataTableStatusWrapper error={false} status={apiCallInProgress ? 'loading' : ''}>
      <RecentUsersTable
        columns={recentUsersColumns}
        data={usersList}
        disableSort
        defaultSortFieldId="last_seen"
        isSorting={false}
        nextPageToken={nextToken}
        fetchingNextPage={fetchingNextPage}
        handlePagination={handlePagination}
        paginationIndex={paginationIndex}
        totalCount={totalCount}
        noDataComponent={<NoDataComponent text={'No users to display'} />}
      />
    </DataTableStatusWrapper>
  );
}
