import axios from 'axios';
import config from 'react-global-configuration';

class UserApi {
  protected token: string;
  protected config: Headers;
  protected axiosConfig: any;

  public constructor(token: string) {
    this.config = new Headers();
    this.token = token;
    this.config.set('Content-Type', 'application/json');
    this.config.set('Accept', 'application/json');
    this.config.set('Authorization', `Bearer ${this.token}`);
    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
  }

  public getUsersActiveLastHour = async (nextToken?: string): Promise<any> => {
    const queryString = new URLSearchParams();
    const date = new Date();

    date.setHours(date.getHours() - 1);
    queryString.append('count', '50');
    queryString.append('sort', '-timestamp');

    queryString.append('last_seen', date.toISOString());
    if (nextToken) queryString.append('next', nextToken);

    return axios.get(
      `${config.get().DATA_API_URL}/latest/user/?${queryString.toString()}`,
      this.axiosConfig,
    );
  };

  public getUsersMapLastHour = async (): Promise<any> => {
    const queryString = new URLSearchParams();
    const date = new Date();

    date.setHours(date.getHours() - 1);
    queryString.append('count', '100');
    queryString.append('last_seen', date.toISOString());

    return axios.get(
      `${config.get().DATA_API_URL}/latest/user/?${queryString.toString()}`,
      this.axiosConfig,
    );
  };
}
export default UserApi;
