import { useContext, useState } from 'react';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken } from 'utils/utils';
import { alertErrorMessage } from 'utils/alerts';
import AssetsApi from 'api/assets/assets.api';
import { AssetsContext } from '../contexts/assets.context';
import { getIntersectionFilteredAssets, getEstimatesWithBuildingAndEstimate } from '../utils/utils';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { VenuesContext } from '../contexts/venues.context';
import { SearchFilterContext } from '../contexts/searchFilter.context';
import { VenueFilesContext } from '../contexts/venuefiles.context';
import useMapHooksExternalMapRef from './useMapHooksExternalMapRef';

export default function useCallSearchAssets() {
  const [noResultsReturned, setNoResultsReturned] = useState(false);

  const { setRawAssets, cachedAssetRef } = useContext(AssetsContext);
  const authContext = useContext(AuthenticationContext).authState;
  const { selectedVenueObj, isSingleVenue } = useContext(VenuesContext);
  const { levelsFeatures } = useContext(VenueFilesContext);
  const {
    setApiCallInProgress,
    setBuildingSelectedID,
    buildingSelectedID,
    buildingIsSelected,
    setClickedMarkerID,
  } = useContext(MapControlContext);
  const { setDoNotCalculateFloor, setInSearchMode, setInFilterMode, setSearchAPIcallInProgress } =
    useContext(SearchFilterContext);
  const { fitMapToBoundsOfBuildingLevelsWithAssets } = useMapHooksExternalMapRef();

  const token = getIdToken(authContext);
  const assetsApi = new AssetsApi(token);

  const callSearchAssets = (term: string) => {
    const selectedVenueID = isSingleVenue ? null : selectedVenueObj.venue_id;

    const termTrimmed = term.trim();
    cachedAssetRef.current = null; // reset cached asset.
    setApiCallInProgress(true);
    setSearchAPIcallInProgress(true);
    setDoNotCalculateFloor(false);
    setInSearchMode(false);
    setNoResultsReturned(false);

    return assetsApi
      .getRegisteredBeaconMapAssets(selectedVenueID, termTrimmed)
      .then((res) => {
        const { items } = res.data;
        const estimatesWithBuilding = getEstimatesWithBuildingAndEstimate(items);

        setRawAssets(estimatesWithBuilding);
        setInSearchMode(true);
        setInFilterMode(false);
        setApiCallInProgress(false);
        setSearchAPIcallInProgress(false);
        setClickedMarkerID('');

        if (estimatesWithBuilding.length < 1) {
          setNoResultsReturned(true);
          return;
        } else if (estimatesWithBuilding.length === 1) {
          // cache single asset estimate after search in ref
          // so we can compare this in polling.
          cachedAssetRef.current = estimatesWithBuilding[0];

          if (!buildingIsSelected) {
            // if one result is returned, and no building selected
            // programatically select the building that this asset belongs to
            // so the user can see it.
            // NOTE that this only sets the associated builiding GeoJSON to display,
            // the actual programatic floor change is handled in useFloorSelectionHook.
            // which uses buildingSelectedID as a dep.

            const singleMatchedAssetBuildingID =
              estimatesWithBuilding[0].estimate.location.building.building_uuids[0];

            setBuildingSelectedID(singleMatchedAssetBuildingID);
          }
        }
        if (buildingSelectedID) {
          fitMapToBoundsOfBuildingLevelsWithAssets(
            levelsFeatures,
            estimatesWithBuilding,
            buildingSelectedID,
          );
        }

        return estimatesWithBuilding;
      })
      .catch((err) => {
        setApiCallInProgress(false);
        setSearchAPIcallInProgress(false);
        alertErrorMessage(`Search Failed`);
        console.error(err.message);
      });
  };

  const callFilterAssets = (
    inputRef: any,
    assetOwnerQueryArray: string[],
    assetSubtypeQueryArray: string[],
  ) => {
    const selectedVenueID = isSingleVenue ? null : selectedVenueObj.venue_id;

    setApiCallInProgress(true);
    setSearchAPIcallInProgress(true);

    return assetsApi
      .getRegisteredBeaconFilteredMapAssets(
        selectedVenueID,
        assetOwnerQueryArray,
        assetSubtypeQueryArray,
      )
      .then((res) => {
        const { items } = res.data;
        const filteredItems = getEstimatesWithBuildingAndEstimate(items);
        const intersectionalFilteredAssets = getIntersectionFilteredAssets(
          filteredItems,
          assetOwnerQueryArray,
          assetSubtypeQueryArray,
        );
        // need to find way to check filter array, and return nothing if all are unchecked.
        setRawAssets(intersectionalFilteredAssets);
        setApiCallInProgress(false);
        setSearchAPIcallInProgress(false);
        setInFilterMode(true);
        setInSearchMode(false);

        // keep focus on  input after api call, so we can close menu properly.
        if (inputRef && inputRef.current) inputRef.current.focus();

        if (intersectionalFilteredAssets.length > 0) {
          fitMapToBoundsOfBuildingLevelsWithAssets(
            levelsFeatures,
            intersectionalFilteredAssets,
            buildingSelectedID,
          );
        }
        return intersectionalFilteredAssets;
      })
      .catch((err) => {
        setApiCallInProgress(false);
        setSearchAPIcallInProgress(false);
        alertErrorMessage('could not search');
        console.log(err);
      });
  };

  return {
    callSearchAssets,
    noResultsReturned,
    setNoResultsReturned,
    callFilterAssets,
  };
}
