import React, { useContext } from 'react';
import SelectInput from '../SelectInput';
import { getReactSelectObjectFromValueKey, getSortedArrayByValue } from 'utils/utils';
import StyledSelectContainer from './StyledSelectContainer';
import { AssetConfigContext } from 'contexts/assetConfig.context';

type AssetOwnerProps = {
  value?: { value: string; label: string };
  onInputChange?: () => void;
  isDisabled?: boolean;
  className?: string;
};
interface IAssetOwner {
  value?: { value: string; label: string };
  onInputChange?: () => void;
  isDisabled?: boolean;
  className?: string;
}
const AssetOwners: React.FunctionComponent<IAssetOwner> = (props: AssetOwnerProps) => {
  const { assetOwners } = useContext(AssetConfigContext);
  const alphaSortedOptions = getSortedArrayByValue(assetOwners, 'value');
  const valueObjToDisplay = getReactSelectObjectFromValueKey(assetOwners, props.value?.value);

  return (
    <StyledSelectContainer data-test-id="asset-owner-options">
      <SelectInput
        {...props}
        id="assetOwners"
        name="assetOwners"
        aria-label="Owner"
        placeholder="Enter the Keyword"
        options={alphaSortedOptions}
        value={valueObjToDisplay}
      />
    </StyledSelectContainer>
  );
};
export default AssetOwners;
