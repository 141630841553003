import { useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getDefaultDropdownObj, getIdToken } from 'utils/utils';
import DevicesApi from 'api/devices/devices.api';
import { beaconAssignmentOptionsArray } from './menuOptions';
import { apiAssetAssignEnum } from 'views/BeaconManagementPage/enum';
import { alertErrorMessage } from 'utils/alerts';
import { BeaconSelectOptionType } from 'views/BeaconManagementPage/types';

export default function useCallBeaconAssignmentOptionApiCount() {
  const [options, setOptions] = useState(beaconAssignmentOptionsArray);
  const [defaultDropdownValue, setDefaultDropdownValue] = useState(() =>
    getDefaultDropdownObj(beaconAssignmentOptionsArray),
  );
  const authContext = useContext(AuthenticationContext).authState;
  const [isLoading, setIsLoading] = useState(true);

  const token = getIdToken(authContext);
  const deviceApi = new DevicesApi(token);

  useEffect(() => {
    // loop over each assignment function and call api with assignment param to retrieve total.
    // create new Obj with appended values set states to corresponding values once count reaches 3.
    let count = 0;
    const newOptionsArray: BeaconSelectOptionType[] = [];
    setIsLoading(true);

    beaconAssignmentOptionsArray.forEach(async (entry, index) => {
      let total = null;

      const res = await deviceApi.getRegisteredBeaconsTotal(apiAssetAssignEnum[entry.value]);

      if (res && res.data) {
        total = res.data.total;
      } else {
        const errorText =
          entry.value === 'all'
            ? `Could not fetch beacon totals`
            : 'Could not fetch beacon assignment status totals';

        alertErrorMessage(errorText);
      }
      const newOptionObj = {
        value: beaconAssignmentOptionsArray[index].value,
        label: `${beaconAssignmentOptionsArray[index].label} ${total ? `(${total})` : ''}`,
        default: entry.default ? true : false,
        order: entry.order,
      };
      newOptionsArray.push(newOptionObj);

      count += 1;

      if (count === 3) {
        const sortedOptions = newOptionsArray.sort((a: any, b: any) => a.order - b.order);
        const defaultDropDownObj = getDefaultDropdownObj(sortedOptions);

        setDefaultDropdownValue(defaultDropDownObj);
        setOptions(sortedOptions);
        setIsLoading(false);

        return;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { options, defaultDropdownValue, isLoading };
}
