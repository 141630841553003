import React, { ReactElement } from 'react';
import { message } from 'antd';
import SuccessAlert from 'components/Alert/SuccessAlert';
import ErrorAlert from 'components/Alert/ErrorAlert';

export const alertErrorMessage = (messageString: string) => {
  message.config({ maxCount: 2 });

  message.error(<ErrorAlert messageString={messageString} />);
};

export const alertSuccessMessage = (messageEl: string | ReactElement) => {
  message.config({ maxCount: 2 });

  message.success(<SuccessAlert messageEl={messageEl} />);
};
