import React, { useContext, useState, useRef, useEffect } from 'react';
import { SearchBarStyled } from '../Styled';
import { StyledAsyncSelect, StyledInputContainer } from 'components/AsyncSelect/Styled';
import DeviceApi from 'api/devices/devices.api';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken } from 'utils/utils';
import { selectedRowsType } from '../../types';
import { alertErrorMessage } from 'utils/alerts';
import ClearInputButton from 'components/Button/ClearInputButton';
import NoSearchResultsPanel from './NoSearchResultsPanel';
import { FullConfigContext } from 'contexts/fullConfig.context';

type NewBeaconSearchFormTypes = {
  setSearchResults: (arg: selectedRowsType) => void;
  setIsSearching: (arg: boolean) => void;
  searchTerm: string;
  setSearchTerm: (arg: string) => void;
  setNextToken: (arg: string) => void;
  setPreSelectedRows: (arg: selectedRowsType) => void;
};

export default function NewBeaconSearchForm({
  setSearchResults,
  setIsSearching,
  searchTerm,
  setSearchTerm,
  setNextToken,
  setPreSelectedRows,
}: NewBeaconSearchFormTypes) {
  const [showMinimumCharactersError, setShowMinimumCharactersError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const authContext = useContext(AuthenticationContext).authState;
  const { beaconIntegration } = useContext(FullConfigContext);
  const token = getIdToken(authContext);
  const deviceApi = new DeviceApi(token);
  const searchTermRef = useRef(searchTerm);

  const callSearchDevices = (term: string) => {
    const termTrimmed = term.trim();

    setSearchTerm(termTrimmed);
    setIsLoading(true);

    deviceApi
      .getBeaconById(beaconIntegration, termTrimmed)
      .then((res) => {
        const { items, next } = res.data;
        setIsLoading(false);
        setSearchResults(items);
        setIsSearching(true);
        setNextToken(next);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsSearching(false);
        alertErrorMessage(`Search Failed: ${err.message}`);
        console.error(err.message);
      });
  };

  function handleClearField() {
    setIsSearching(false);
    setSearchTerm('');
    setSearchResults([]);
    setPreSelectedRows([]);
  }

  function handleInputChange(term: string, actionObj: any) {
    switch (actionObj.action) {
      case 'input-change':
        setSearchTerm(term);
        setIsSearching(false);
        setShowMinimumCharactersError(false);

        break;
      case 'menu-close':
        setSearchTerm(searchTermRef.current);
        break;
      case 'input-blur':
        setSearchTerm(searchTermRef.current);
        break;
      default:
        break;
    }
  }

  function handleKeyPress(e: React.KeyboardEvent) {
    if (e.keyCode === 13) {
      if (searchTerm.length < 3) {
        setShowMinimumCharactersError(true);
        return;
      }
      callSearchDevices(searchTerm);
    }
  }

  useEffect(() => {
    // keep ref of searchTerm so we can apply again it if react-select clears it
    searchTermRef.current = searchTerm;
  }, [searchTerm]);

  return (
    <SearchBarStyled>
      <StyledInputContainer width={350}>
        <StyledAsyncSelect
          isLoading={isLoading}
          placeholder="Search for beacon ID"
          onInputChange={handleInputChange}
          onKeyDown={(e: React.KeyboardEvent) => handleKeyPress(e)}
          inputValue={searchTerm}
          className="asyncSearch"
          classNamePrefix="asyncSearchPrefix"
          menuIsOpen={false}
        />
        {searchTerm.length > 0 && (
          <ClearInputButton onClick={handleClearField} searchInProgress={isLoading} />
        )}
        {showMinimumCharactersError && <NoSearchResultsPanel />}
      </StyledInputContainer>
    </SearchBarStyled>
  );
}
