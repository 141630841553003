import React from 'react';
import { LatestBeacon } from 'types/devices';
import DefaultCell from '../cells/DefaultCell';
import AssetSubtypeCell from '../cells/AssetSubtypeCell';
import ArrowCell from '../cells/ArrowCell';

export const registeredBeaconColumns = [
  {
    name: 'BEACON ID',
    selector: (row: LatestBeacon) => <DefaultCell value={row.device.device_id} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
    sortField: 'device_id',
  },
  {
    name: 'BEACON TYPE',
    selector: (row: LatestBeacon) => <DefaultCell value={row.device.properties.product} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
    sortField: 'properties.product',
  },
  {
    name: 'SOURCE',
    selector: (row: LatestBeacon) => <DefaultCell value={row.device.source || 'manual'} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
    sortField: 'source',
  },
  {
    name: 'ASSET NAME',
    selector: (row: LatestBeacon) => <DefaultCell value={row.entity?.properties.name} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
    sortField: 'entity.properties.name',
  },
  {
    name: 'ASSET TYPE',
    selector: (row: LatestBeacon) => <AssetSubtypeCell value={row.entity?.subtype} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
    sortField: 'entity.subtype',
  },
  {
    name: 'TAGS',
    selector: (row: LatestBeacon) => <DefaultCell value={row.device.properties.tags} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
    sortField: 'properties.tags',
  },
  {
    name: 'NOTES',
    selector: (row: LatestBeacon) => <DefaultCell value={row.device.properties.notes} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
    sortField: 'properties.notes',
  },
  {
    right: true,
    sortable: false,
    cell: () => <ArrowCell />,
    sortField: '',
    width: '10px',
  },
];
