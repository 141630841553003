import React from 'react';
import mobileLegendIcon from '../../icons/mobile-legend.svg';
import { StyledMobileLegendButton } from 'views/MapPage/Styled';

export default function MobileLegendButton({ onClick }: { onClick: () => void }) {
  return (
    <StyledMobileLegendButton data-test-id="mobile-legend-button" onClick={onClick}>
      <img src={mobileLegendIcon} alt="mobile legend" />
    </StyledMobileLegendButton>
  );
}
