import React from 'react';
import { Device } from 'types/devices';
import DefaultCell from '../cells/DefaultCell';
// TODO: still need to implement the following:
// Notes
// Venue
// Manager

export const newBeaconColumns: any[] = [
  {
    name: 'BEACON ID',
    selector: (row: Device) => <DefaultCell value={row.device_id} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
  },
  {
    name: 'BEACON TYPE',
    selector: (row: Device) => <DefaultCell value={row.properties.product} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
  },
  {
    name: 'TAGS',
    selector: (row: Device) => <DefaultCell value={row.properties.tags} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
  },
  {
    name: 'NOTES',
    selector: (row: Device) => <DefaultCell value={row.properties.notes} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
  },

  {
    name: 'MANAGER',
    selector: () => <DefaultCell value={null} />, // selector: not available yet.
    sortable: true,
    overflow: 'false',
    grow: 1.5,
  },
  {
    name: 'ORDER ID',
    selector: (row: Device) => <DefaultCell value={row.properties.order_id} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
  },
  {
    name: 'SOURCE',
    selector: (row: Device) => <DefaultCell value={row.source} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
  },
  {
    name: 'VENUE',
    selector: () => <DefaultCell value={null} />, // selector: not available yet.
    sortable: true,
    overflow: 'false',
    grow: 1.5,
  },
];
