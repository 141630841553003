import { icons } from 'assets/icons';
import React, { useContext, useState } from 'react';
import PageTitle from 'components/Header/components/PageTitle';
import AssetSearchField from './components/AssetSearch/AssetSearchField';
import UserInfoDropdown from './components/UserInfoDropdown/UserInfoDropdown';
import usePageInfo from 'hooks/pageInfo.hook';
import { HeaderStyled, StyledSubHeading } from './style';
import useDevice from 'hooks/useDevice';
import styled from 'styled-components';
import { Horizontal, StretchSpacer } from 'gls';
import IconButton from 'components/Button/IconButton';
import { AssetListPageContext } from 'contexts/assetListPage.context';
import { isEmpty } from 'lodash';

const HeaderAssetSearch = () => {
  const { headerAssetCount, contextPageTitle } = usePageInfo();
  const { isTabletDevice, isMobileDevice } = useDevice();
  const paddedObjectCount = headerAssetCount.toString().padStart(2, '0');
  const [showSearch, setShowSearch] = useState(false);
  const { searchTerm } = useContext(AssetListPageContext);

  if (isMobileDevice) {
    return (
      <div className="container">
        {showSearch ? (
          <SearchStyle>
            <div className="search-container">
              <AssetSearchField />
              {isEmpty(searchTerm) && (
                <IconButton
                  className="close-search"
                  icon={<icons.CloseIcon width="15px" height="15px" />}
                  onClick={() => setShowSearch(false)}
                />
              )}
            </div>
            <StyledSubHeading data-test-id="assetCount">
              # {paddedObjectCount} Total Asset{headerAssetCount === 1 ? '' : 's'}
            </StyledSubHeading>
          </SearchStyle>
        ) : (
          <HeaderStyled className="page-header">
            <div className="header-item title">
              <Horizontal width="100%">
                <PageTitle pageTitle={contextPageTitle} />
                <StretchSpacer />
                <IconButton icon={<icons.SearchIcon />} onClick={() => setShowSearch(true)} />
              </Horizontal>
              <StyledSubHeading data-test-id="assetCount">
                # {paddedObjectCount} Total Asset{headerAssetCount === 1 ? '' : 's'}
              </StyledSubHeading>
            </div>
          </HeaderStyled>
        )}
      </div>
    );
  }

  return (
    <div className="container">
      <HeaderStyled className="page-header">
        <div className="header-item title">
          <PageTitle pageTitle={contextPageTitle} />
          <StyledSubHeading data-test-id="assetCount">
            # {paddedObjectCount} Total Asset{headerAssetCount === 1 ? '' : 's'}
          </StyledSubHeading>
        </div>
        <div className="header-item search">
          <AssetSearchField />
          {!isTabletDevice && <UserInfoDropdown />}
        </div>
      </HeaderStyled>
    </div>
  );
};
export default HeaderAssetSearch;

const SearchStyle = styled.div`
  margin-bottom: 10px;

  .search-container {
    background-color: white;
    padding: 10px 15px;
    margin: 10px -15px;
    position: relative;

    .input-container {
      max-width: 100%;
    }
    .asyncSearch {
      max-width: 100%;
    }

    .close-search {
      position: absolute;
      padding: 0;
      right: 26px;
      top: 22px;
    }
  }
`;
