import axios from 'axios';
import config from 'react-global-configuration';

class EntitiesApi {
  protected token: string;
  protected config: Headers;
  protected axiosConfig: any;

  public constructor(token: string) {
    this.config = new Headers();
    this.token = token;
    this.config.set('Content-Type', 'application/json');
    this.config.set('Accept', 'application/json');
    this.config.set('Authorization', `Bearer ${this.token}`);
    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
  }

  public checkAssetExistsByAssetName = async (assetName: string) => {
    const url = `${config.get().DATA_API_URL}/entity/asset?filter[properties.name]="${assetName}"`;
    const response = await fetch(url, {
      method: 'GET',
      headers: this.config,
    });
    const { count } = await response.json();
    return count > 0;
  };

  public updateAssetDetails = async (newAssetDetails: any) => {
    const url = `${config.get().DATA_API_URL}/entity/asset/${newAssetDetails.entity_id}`;
    return axios.put(url, JSON.stringify(newAssetDetails), this.axiosConfig);
  };

  public createNewAsset = async (newAssetDetails: any) => {
    const url = `${config.get().DATA_API_URL}/entity/asset`;
    return axios.post(url, JSON.stringify(newAssetDetails), this.axiosConfig);
  };

  public deleteAsset = async (assetID: string) => {
    const url = `${config.get().DATA_API_URL}/entity/asset/${assetID}`;
    return axios.delete(url, this.axiosConfig);
  };

  public getJobTemplateFile = async () => {
    const url = `${config.get().DATA_API_URL}/entity/asset/job/template`;

    return axios({
      url: url,
      method: 'get',
      responseType: 'blob',
      headers: {
        'Access-Control-Expose-Headers': 'Content-Disposition',
        'Content-Disposition': 'attachment;',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
}
export default EntitiesApi;
