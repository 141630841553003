import AssetOwnerCell from 'components/DataTable/cells/AssetOwnerCell';
import AssetSubtypeCell from 'components/DataTable/cells/AssetSubtypeCell';
import BatteryCell from 'components/DataTable/cells/BatteryCell';
import LastSeenCell from 'components/DataTable/cells/LastSeenCell';
import LocationCell from 'components/DataTable/cells/LocationCell';
import { Vertical } from 'gls';
import React from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import { LatestBeacon } from 'types/devices';

type MobileAssetColumnProps = {
  row: LatestBeacon;
  onClick: (arg: any) => void;
};
export const MobileAssetColumn = ({ row, onClick }: MobileAssetColumnProps) => {
  return (
    <AssetContainerStyle onClick={() => onClick(row)}>
      <Vertical spacing={10}>
        <span className="asset-name">{row.entity.properties.name}</span>
        {row.entity.subtype && (
          <span>
            <AssetSubtypeCell value={row.entity.subtype} />
          </span>
        )}
        <table>
          <tr>
            <td className="title">LAST SEEN:</td>
            <td>{row.estimate ? <LastSeenCell timestamp={row.estimate?.timestamp} /> : '––'}</td>
          </tr>
          <tr>
            <td className="title">LOCATION:</td>
            <td>
              <LocationCell {...row} />
            </td>
          </tr>
          <tr>
            <td className="title">OWNER:</td>
            <td>
              <AssetOwnerCell value={row.entity.properties?.owner} />
            </td>
          </tr>
          <tr>
            <td className="title">STATUS:</td>
            <td>{row.estimate?.properties?.mobility_state} </td>
          </tr>
          <tr>
            <td className="title">BATTERY:</td>
            <td>
              <BatteryCell {...row} />
            </td>
          </tr>
        </table>
      </Vertical>
    </AssetContainerStyle>
  );
};

const AssetContainerStyle = styled.div`
  width: 100%;

  table td {
    vertical-align: top;
    padding-bottom: 4px;
  }
  .title {
    width: 100px;
    color: ${colours.primary};
  }
  .asset-name {
    font-weight: bold;
  }
`;
