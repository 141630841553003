import { NUMBER_OF_ROWS_PER_PAGE } from 'config/constants';
import config from 'react-global-configuration';
class EstimatesApi {
  queryString: URLSearchParams;
  baseUrl: string | undefined;
  url: string;
  httpHeaders: any;
  public constructor(token: string) {
    this.queryString = new URLSearchParams();
    this.baseUrl = config.get().DATA_API_URL;
    this.url = `${this.baseUrl}/latest/asset`;
    this.httpHeaders = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
  }

  public getEstimates = async (
    nextToken = '',
    sortColumn = '',
    sortDirection = '',
    searchTerm = '',
    searchCategory = '',
  ) => {
    this.queryString.append('count', `${NUMBER_OF_ROWS_PER_PAGE}`);
    if (sortColumn !== '') {
      this.queryString.append('sort', `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`);
    }

    if (nextToken !== '' && nextToken !== null) {
      this.queryString.append('next', nextToken);
    }

    // append search params if they exist:
    if (searchCategory !== '' && searchTerm !== '') {
      if (searchCategory === 'location') {
        // location search does not need filter.
        this.queryString.append(`location`, searchTerm);
      } else {
        this.queryString.append(`filter[${searchCategory}]`, searchTerm);
      }
    } else if (searchTerm !== '') {
      this.queryString.append(`filter[properties.name]`, searchTerm);
      this.queryString.append(`filter[properties.owner]`, searchTerm);
      this.queryString.append(`filter[subtype]`, searchTerm);
      this.queryString.append(`location`, searchTerm);
    }

    this.url = `${this.url}?${this.queryString.toString()}`;

    const response = await fetch(this.url, this.httpHeaders);
    if (!response.ok) {
      const res = await response.json();

      throw new Error(`Error: unable to access endpoint. ${res.detail}`);
    } else {
      const { items, next, total } = await response.json();
      return {
        results: items,
        next: next,
        total: total,
      };
    }
  };

  public getSingleOptionSearchEstimates = async (
    searchTerm = '',
    searchCategory = '',
    nextToken = '',
    sortColumn = '',
    sortDirection = '',
  ) => {
    this.queryString.append('count', `${NUMBER_OF_ROWS_PER_PAGE}`);
    if (sortColumn !== '') {
      this.queryString.append('sort', `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`);
    }

    if (searchCategory === 'location') {
      // location search does not need filter.
      this.queryString.append(`location`, searchTerm);
    } else {
      this.queryString.append(`filter[${searchCategory}]`, searchTerm);
    }

    if (nextToken !== '') {
      this.queryString.append('next', nextToken);
    }
    this.url = `${this.url}?${this.queryString.toString()}`;

    const response = await fetch(this.url, this.httpHeaders);

    if (!response.ok) {
      const res = await response.json();

      throw new Error(`Error: unable to access endpoint. ${res.detail}`);
    } else {
      const { items, next, total } = await response.json();
      return {
        results: items,
        next: next,
        total: total,
      };
    }
  };

  public getAllOptionsSearchEstimates = async (
    searchTerm = '',
    nextToken = '',
    sortColumn = '',
    sortDirection = '',
  ) => {
    this.queryString.append('count', `${NUMBER_OF_ROWS_PER_PAGE}`);
    if (sortColumn !== '') {
      this.queryString.append('sort', `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`);
    }

    this.queryString.append(`filter[properties.name]`, searchTerm);
    this.queryString.append(`filter[properties.owner]`, searchTerm);
    this.queryString.append(`filter[subtype]`, searchTerm);
    this.queryString.append(`location`, searchTerm);

    if (nextToken !== '' && nextToken !== null) {
      this.queryString.append('next', nextToken);
    }
    this.url = `${this.url}?${this.queryString.toString()}`;

    const response = await fetch(this.url, this.httpHeaders);

    if (!response.ok) {
      const res = await response.json();

      throw new Error(`Error: unable to access endpoint. ${res.detail}`);
    } else {
      const { items, next, total } = await response.json();
      return {
        results: items,
        next: next,
        total: total,
      };
    }
  };

  public getSingleEstimateByID = async (entityID: string): Promise<any> => {
    const response = await fetch(
      `${config.get().DATA_API_URL}/estimate/asset/${entityID}`,
      this.httpHeaders,
    );

    if (!response.ok) {
      const res = await response.json();

      throw new Error(`Error: unable to access endpoint. ${res.detail}`);
    } else {
      const res = await response.json();
      return res;
    }
  };
}

export default EstimatesApi;
