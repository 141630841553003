import { UploadFailedStatusMessageType } from 'components/FileUpload/type';
import { StyledUnorderedList } from 'components/List/styled';
import React from 'react';

type FailedErrorListType = {
  processingErrors: UploadFailedStatusMessageType[];
};
export default function ProcessingErrorsList({ processingErrors }: FailedErrorListType) {
  return (
    <StyledUnorderedList>
      {processingErrors.map((error) => {
        return (
          <li key={error.row}>
            {error.row && `Error on line ${error.row}, `}
            {error.message}
          </li>
        );
      })}
    </StyledUnorderedList>
  );
}
