import React from 'react';
import { LatestAssetResults } from 'types/combinedEstimatesAssets';
import TableCellWarning from 'components/DataTable/cells/TableCellWarning';
import { getValueSafely } from 'utils/utils';
import { LOW_BATTERY_THRESHOLD } from 'config/constants';
import warningIcon from './warning.svg';

const BatteryCell = (row: LatestAssetResults) => {
  if (row.estimate?.properties?.battery?.battery_percent <= LOW_BATTERY_THRESHOLD) {
    return (
      <TableCellWarning>
        {getValueSafely(row.estimate?.properties?.battery?.battery_percent, '%')}
        <img
          style={{
            marginLeft: '13px',
            width: '20px',
          }}
          src={warningIcon}
          alt="low battery"
        />
      </TableCellWarning>
    );
  } else {
    return (
      <span className="u-no-pointer-event">
        {getValueSafely(row.estimate?.properties?.battery?.battery_percent, '%')}
      </span>
    );
  }
};

export default BatteryCell;
