import { FullConfigContext } from 'contexts/fullConfig.context';
import React, { useContext } from 'react';
import { AssetsContext } from '../contexts/assets.context';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { UsersContext } from '../contexts/users.context';
import useBuildingLevelUtilHooks from '../hooks/useBuildingLevelUtilHooks';
import useFilteredAssets from '../hooks/useFilteredAssets';
import {
  getAssociatedLevelIDsArrayFromOrdinal,
  getFloorAssetCountFromLevelIDArray,
  getFloorUserCountFromLevelIDArray,
} from '../utils/utils';

// component to show floor count of assets or users depending on permissions enabled.
// assetfloorcount should be the default unless this is not enabled and user_tracking is.
export default function FloorCountBadge({ ordinal }: { ordinal: number }) {
  const { rawAssets } = useContext(AssetsContext);
  const { usersMarkers } = useContext(UsersContext);
  const { userTrackingPermissionEnabled, assetTrackingPermissionEnabled } =
    useContext(FullConfigContext);
  const { apiCallInProgress, showAssetsChecked } = useContext(MapControlContext);

  const getVisibleAssets = useFilteredAssets();

  const { associatedBuildingLevelFeatures } = useBuildingLevelUtilHooks();

  const levelIDsArray = getAssociatedLevelIDsArrayFromOrdinal(
    ordinal,
    associatedBuildingLevelFeatures,
  );
  const floorAssetCount = getFloorAssetCountFromLevelIDArray(
    levelIDsArray,
    getVisibleAssets(rawAssets),
  );
  const floorUserCount = getFloorUserCountFromLevelIDArray(levelIDsArray, usersMarkers);

  const shouldShowFloorAssetCount =
    assetTrackingPermissionEnabled &&
    floorAssetCount > 0 &&
    !apiCallInProgress &&
    showAssetsChecked;

  const shouldShowFloorUserCount =
    userTrackingPermissionEnabled && !assetTrackingPermissionEnabled && floorUserCount > 0;

  return (
    <>
      {shouldShowFloorAssetCount && <span className="count-readout">{floorAssetCount}</span>}
      {shouldShowFloorUserCount && <span className="count-readout">{floorUserCount}</span>}
    </>
  );
}
