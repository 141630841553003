import axios from 'axios';
import config from 'react-global-configuration';

class DevicesJobsApi {
  protected token: string;
  protected config: Headers;
  protected axiosConfig: any;
  protected queryString: URLSearchParams;

  public constructor(token: string) {
    this.config = new Headers();
    this.token = token;
    this.config.set('Content-Type', 'application/json');
    this.config.set('Accept', 'application/json');
    this.config.set('Authorization', `Bearer ${this.token}`);
    this.queryString = new URLSearchParams();

    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
  }

  public createNewJob = async () => {
    const url = `${config.get().DATA_API_URL}/device/beacon/job`;

    return axios.post(url, null, this.axiosConfig);
  };

  public postJobFile = async (jobID: string, fileID: string) => {
    const url = `${config.get().DATA_API_URL}/device/beacon/job/${jobID}/file/${fileID}`;

    return axios.post(url, null, this.axiosConfig);
  };

  public putJobFile = async (uploadUrl: string, file: any) => {
    const url = `${uploadUrl}`;
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': '',
      },
      body: file,
    };
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error();
    }
    return response;
  };

  public postJobUploadComplete = async (jobID: string, fileID: string) => {
    const url = `${config.get().DATA_API_URL}/device/beacon/job/${jobID}/complete`;

    return axios.post(url, { files: [fileID] }, this.axiosConfig);
  };

  public getJobStatus = async (jobID: string) => {
    const url = `${config.get().DATA_API_URL}/device/beacon/job/${jobID}/status`;

    return axios.get(url, this.axiosConfig);
  };

  public deleteJob = async (jobID: string) => {
    const url = `${config.get().DATA_API_URL}/device/beacon/job/${jobID}`;

    return axios.delete(url, this.axiosConfig);
  };
}
export default DevicesJobsApi;
