import useAssetConfig from 'hooks/useAssetConfig.hook';
import React from 'react';

export default function DropdownControlReadout({
  checkedList,
  checkAll,
  type,
}: {
  checkedList: string[];
  checkAll: boolean;
  type: string;
}) {
  let readoutString = '';
  const singleSelected = checkedList.length === 1;
  const { getLabelFromAssetConfig } = useAssetConfig();
  if (checkAll) readoutString = 'All';
  else if (checkedList[0] === 'nop') {
    readoutString = ` (0)`;
  } else {
    readoutString = singleSelected ? getLabelFromAssetConfig(checkedList[0], type) : '';
  }

  return (
    <div>
      {readoutString}
      {!singleSelected && ` (${checkedList.length})`}
    </div>
  );
}
