import React from 'react';
import Button from 'components/Button/Button';
import ArchiveIcon from './icons/ArchiveIcon.svg';
import AntModalStyled from 'components/Modals/AntModalStyled';
import ArchiveAssetModalContent from './content/ArchiveAssetModalContent';
import styled from 'styled-components';
import { coloursNames } from 'styling/colours';
import { LatestBeacon } from 'types/devices';

type ModalProps = {
  closeModal: () => void;
  actionFunction: () => void;
  label?: string;
  apiCallInProgress: boolean;
  selectedAssetDetails: LatestBeacon;
};
const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${coloursNames.midGrey};
  border-radius: 50%;
  img {
    width: 20px;
  }
`;

const ArchiveAssetModal: React.FC<ModalProps> = (props: ModalProps) => {
  return (
    <AntModalStyled
      onCancel={() => props.closeModal()}
      visible
      centered
      footer={null}
      maskClosable={false}
    >
      <header>
        <StyledIconWrapper>
          <img src={ArchiveIcon} alt="archive" />
        </StyledIconWrapper>
        <h3>Archive Asset</h3>
      </header>
      <ArchiveAssetModalContent selectedAssetDetails={props.selectedAssetDetails} />
      <footer>
        <Button id="archiveAssetCancelButton" onClick={() => props.closeModal()} outline>
          Cancel
        </Button>
        <Button
          disabled={props.apiCallInProgress}
          id="archiveAssetArchiveButton"
          onClick={() => props.actionFunction()}
        >
          Archive
        </Button>
      </footer>
    </AntModalStyled>
  );
};
export default ArchiveAssetModal;
