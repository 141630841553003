import React, { useContext } from 'react';
import { colours } from 'styling/colours';
import { getLastSeenString, getValueSafely } from 'utils/utils';
import LastSeenCell from '../../../../components/DataTable/cells/LastSeenCell';
import { BroadcastProfileType, LatestBeacon } from 'types/devices';
import useAssetConfig from 'hooks/useAssetConfig.hook';
import { VenuesContext } from 'contexts/venues.context';
import { StyledTable } from '../Styled';
import LocationSpan from './LocationSpan';
import AccuracyRangeSpan from './AccuracyRangeSpan';
import BatterySpan from './BatterySpan';
import { format } from 'date-fns';

function getBeaconTypesStringFromArray(broadcastProfileArray: BroadcastProfileType[]) {
  let arr: any | BroadcastProfileType[] = [];

  broadcastProfileArray.filter((profile: BroadcastProfileType) => {
    if (profile) {
      arr.push(profile.type);
    }
  });

  return arr.join(', ');
}

const RegisteredBeaconFlyoutDetails = ({
  selectedAssetDetails,
}: {
  selectedAssetDetails: LatestBeacon;
}) => {
  const { getLabelFromAssetConfig } = useAssetConfig();
  const { isSingleVenue } = useContext(VenuesContext);

  const beaconTypeString = getBeaconTypesStringFromArray(
    selectedAssetDetails.device.broadcast_profiles,
  );

  const createdDate = selectedAssetDetails.entity?.created
    ? format(new Date(selectedAssetDetails.entity?.created), 'dd/LL/yyyy')
    : '––';

  const eddystoneProfile: any = selectedAssetDetails.device?.broadcast_profiles.filter(
    (profile) => profile.type === 'eddyuid',
  )[0];
  const ibeaconProfile: any = selectedAssetDetails.device?.broadcast_profiles.filter(
    (profile) => profile.type === 'ibeacon',
  )[0];

  return (
    <>
      <StyledTable data-test-id="beacon-details-general">
        <tbody>
          <tr>
            <td>Beacon ID:</td>
            <td>{getValueSafely(selectedAssetDetails.device?.device_id)}</td>
          </tr>
          <tr>
            <td>Beacon Type:</td>
            <td>{getValueSafely(selectedAssetDetails.device.properties.product)}</td>
          </tr>
        </tbody>
      </StyledTable>
      <div className="hr" />
      <StyledTable data-test-id="beacon-details-protocol">
        <tbody>
          <tr>
            <td>Tags:</td>
            <td>{getValueSafely(selectedAssetDetails.device.properties?.tags?.join(', '))}</td>
          </tr>
          <tr>
            <td>Notes:</td>
            <td>{getValueSafely(selectedAssetDetails.device.properties.notes)}</td>
          </tr>
          <tr>
            <td>Manager:</td>
            <td>––</td>
          </tr>
          <tr>
            <td>Import Date:</td>
            <td>{createdDate}</td>
          </tr>

          <tr>
            <td>Order ID:</td>
            <td>{getValueSafely(selectedAssetDetails.device?.properties?.order_id)}</td>
          </tr>
          <tr>
            <td>Broadcast Profiles:</td>
            <td>{getValueSafely(beaconTypeString)}</td>
          </tr>
          <tr>
            <span className="profile-title">Eddystone</span>
          </tr>
          <tr>
            <td>Namespace:</td>
            <td>{getValueSafely(eddystoneProfile?.namespace)}</td>
          </tr>
          <tr>
            <td>Instance:</td>
            <td>{getValueSafely(eddystoneProfile?.instance)}</td>
          </tr>
          <tr>
            <td>Transmission Power:</td>
            <td>{getValueSafely(eddystoneProfile?.tx_power)}</td>
          </tr>
          <tr>
            <td>MAC Address:</td>
            <td>{getValueSafely(eddystoneProfile?.mac_address)}</td>
          </tr>
          <tr>
            <span className="profile-title">iBeacon</span>
          </tr>
          <tr>
            <td>Proximity UUID:</td>
            <td>{getValueSafely(ibeaconProfile?.proximity_uuid)}</td>
          </tr>
          <tr>
            <td>Major:</td>
            <td>{getValueSafely(ibeaconProfile?.major)}</td>
          </tr>
          <tr>
            <td>Minor:</td>
            <td>{getValueSafely(ibeaconProfile?.minor)}</td>
          </tr>
          <tr>
            <td>Transmission Power:</td>
            <td>{getValueSafely(ibeaconProfile?.tx_power)}</td>
          </tr>
          <tr>
            <td>MAC Address:</td>
            <td>{getValueSafely(ibeaconProfile?.mac_address)}</td>
          </tr>
        </tbody>
      </StyledTable>
      <div className="hr" />

      <StyledTable data-test-id="beacon-details-estimate">
        <tbody>
          <tr>
            <td>Last Seen:</td>
            <td>
              <LastSeenCell timestamp={selectedAssetDetails.estimate?.timestamp} />
              {` ${getLastSeenString(selectedAssetDetails.estimate?.timestamp)}`}
            </td>
          </tr>
          <tr>
            <td>Accuracy Range:</td>
            <td>
              <AccuracyRangeSpan assetDetails={selectedAssetDetails} />{' '}
            </td>
          </tr>
          <tr>
            <td>Status:</td>
            <td style={{ textTransform: 'capitalize' }}>
              {getValueSafely(selectedAssetDetails.estimate?.properties?.mobility_state)}
            </td>
          </tr>
          <tr>
            <td>Battery:</td>
            <td>
              <BatterySpan assetDetails={selectedAssetDetails} />
            </td>
          </tr>
          <tr>
            <td valign="top">Location:</td>
            <td>
              {!isSingleVenue && (
                <div style={{ marginBottom: '15px' }}>
                  {selectedAssetDetails.estimate?.location?.venue?.name}
                </div>
              )}
              <LocationSpan assetDetails={selectedAssetDetails} />
              <div style={{ color: colours.secondaryText, margin: '15px 0' }}>Possible region:</div>
              <div>{getValueSafely(selectedAssetDetails.estimate?.location?.region?.name)}</div>
            </td>
          </tr>
        </tbody>
      </StyledTable>
      <div className="hr" />

      <StyledTable data-test-id="beacon-details-asset">
        <tbody>
          <tr>
            <td>Asset Name:</td>
            <td>{getValueSafely(selectedAssetDetails.entity?.properties?.name)}</td>
          </tr>
          <tr>
            <td>Asset Type:</td>
            <td>{getLabelFromAssetConfig(selectedAssetDetails.entity?.subtype, 'subtype')}</td>
          </tr>
          <tr>
            <td>Asset ID:</td>
            <td>{getValueSafely(selectedAssetDetails.entity?.entity_id)}</td>
          </tr>
        </tbody>
      </StyledTable>
    </>
  );
};
export default RegisteredBeaconFlyoutDetails;
