import React, { useEffect, useReducer, useState } from 'react';
import config from 'react-global-configuration';
import { Amplify } from 'aws-amplify';
import getAmplifyConfig from 'utils/getAmplifyConfig';
import LoadingPlaceholderSkeletonPage from 'components/Loading/LoadingPlaceholderPage/LoadingPlaceholderSkeletonPage';
import ConfigBootstrapApi from 'api/config/config.bootstrap.api';
import { alertErrorMessage } from 'utils/alerts';
import { getConfigApiUrl, getDataApiUrl, shouldUseCognito } from 'utils/utils';
import configReducer from 'reducers/configReducer';
import { useErrorBoundary } from 'react-error-boundary';

const initalConfigObject = {
  SHOULD_USE_COGNITO: true,
  COGNITO_CLIENT_ID: '',
  COGNITO_DOMAIN: '',
  COGNITO_REGION: '',
  COGNITO_USERPOOL_ID: '',
  DATA_API_URL: '',
  CONFIG_API_URL: '',
  HELP_URL: '',
  VENUE_API_URL: '',
};

// a wrapper to fetch config data from various sources, combine them in the reducer, then set them in global config.
// we use global config instead of context, because parts of the application require this that aren't components.
// This needs to be a top level wrapper because we need to get the API URls before we can call any APIs
function PublicConfigWrapper({ children }: { children: React.ReactNode }): any {
  const [ilsConfig, dispatch] = useReducer(configReducer, initalConfigObject);
  const [configLoaded, setConfigLoaded] = useState(false);
  const [amplifySet, setAmplifySet] = useState(false);

  const configBootstrapAPI = new ConfigBootstrapApi();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const localConfigData = {
      DATA_API_URL: getDataApiUrl(),
      CONFIG_API_URL: getConfigApiUrl(),
    };
    dispatch({ type: 'LOCAL', data: localConfigData });
    dispatch({
      type: 'AUTHENTICATION',
      data: { SHOULD_USE_COGNITO: shouldUseCognito() },
    });

    // bootstrap config
    configBootstrapAPI
      .getConfigBootstrap(getConfigApiUrl())
      .then((res) => {
        dispatch({ type: 'BOOTSTRAP', data: res });
        setConfigLoaded(true);
      })
      .catch((e) => {
        alertErrorMessage('error getting config');
        console.error('error getting bootstrap config', e);
        setConfigLoaded(true);
        showBoundary(e);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set global config to use in application, and amplify config.
    if (configLoaded) {
      config.set(ilsConfig, { freeze: false, assign: false });
      if (ilsConfig.SHOULD_USE_COGNITO) {
        Amplify.configure(getAmplifyConfig(config));
      }
      setAmplifySet(true);
    }

    return () => {
      setConfigLoaded(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configLoaded]);

  return amplifySet ? children : <LoadingPlaceholderSkeletonPage />;
}

export default PublicConfigWrapper;
