import React from 'react';
import { UserType } from 'types/userInfo';
import DefaultCell from '../cells/DefaultCell';
import FormattedDateCell from '../cells/FormattedDateCell';
import LocationCellRecentUser from '../cells/LocationCellRecentUser';
import RecentUserToolTipCell from '../cells/RecentUserToolTipCell';

export const recentUsersColumns = [
  {
    name: 'NAME',
    selector: (row: UserType) => <DefaultCell value={row?.entity?.properties.name} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
    sortField: 'name',
  },
  {
    name: 'LOCATION',
    selector: (row: UserType) => <LocationCellRecentUser user={row} />,
    sortable: true,
    overflow: 'false',
    grow: 2,
    sortField: 'location',
  },
  {
    name: 'LAST SEEN',
    selector: (row: UserType) => <FormattedDateCell timestamp={row.estimate?.timestamp} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
    sortField: 'last_seen',
  },
  {
    sortable: false,
    maxWidth: '15px',
    minWidth: '65px',
    cell: (row: UserType) => <RecentUserToolTipCell user={row} />,
    sortField: '',
  },
];
