import React, { useContext, useEffect } from 'react';
import { SearchBarContainerStyled, SearchBarStyled } from '../../Styled';
import { StyledInputContainer } from 'components/AsyncSelect/Styled';
import { MapControlContext } from '../../contexts/mapcontrol.context';
import useCallSearchAssets from '../../hooks/useCallSearchAssets';
import DropdownFilterInputController from 'views/MapPage/controllers/DropdownFilterInputController';
import ShowAssetPeopleDropdown from './ShowAssetPeopleDropdown';
import { SearchFilterContext } from 'views/MapPage/contexts/searchFilter.context';
import ComponentFeaturePermissionRenderWrapper from 'wrappers/ComponentConditionWrappers/ComponentFeaturePermissionRenderWrapper';
import { PERMISSIONS } from 'config/permissions';
import DerivedAsyncSelectSearch from './DerivedAsyncSelectSearch';

export default function AssetMapSearchFilterForm() {
  const { setNoResultsReturned } = useCallSearchAssets();
  const { setRecallGetAssets, setClickedMarkerID } = useContext(MapControlContext);
  const {
    searchTerm,
    setSearchTerm,
    setDoNotCalculateFloor,
    setInSearchMode,
    inSearchMode,
    hasSearchTerm,
    setMenuIsOpen,
  } = useContext(SearchFilterContext);

  function handleInputClick(ev: React.SyntheticEvent) {
    const target = ev.target as HTMLTextAreaElement;
    const targetClassName = target.classList[0];
    // prevent anything occuring if input was not clicked.
    if (targetClassName !== 'asyncSearchPrefix__input') return;

    setMenuIsOpen(hasSearchTerm);
  }

  function resetSearchFromCheckbox() {
    setInSearchMode(false);
    setSearchTerm('');
    setNoResultsReturned(false);
  }

  useEffect(() => {
    // reset search when no searchterm.
    if (searchTerm.length < 1 && inSearchMode) {
      setDoNotCalculateFloor(true); // set to true so we dont recenter map.
      setInSearchMode(false);
      setClickedMarkerID('');
      setRecallGetAssets(true);
      setMenuIsOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    setMenuIsOpen(searchTerm.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <SearchBarContainerStyled>
      <SearchBarStyled>
        <StyledInputContainer
          style={{ marginRight: '10px' }}
          width={350}
          onClick={(ev: React.SyntheticEvent) => handleInputClick(ev)}
        >
          <DerivedAsyncSelectSearch />
        </StyledInputContainer>

        <ComponentFeaturePermissionRenderWrapper
          featurePermissions={PERMISSIONS.FEATURE.USER_TRACKING}
        >
          <ShowAssetPeopleDropdown />
        </ComponentFeaturePermissionRenderWrapper>
      </SearchBarStyled>
      <SearchBarStyled>
        <DropdownFilterInputController resetSearchFromCheckbox={resetSearchFromCheckbox} />
      </SearchBarStyled>
    </SearchBarContainerStyled>
  );
}
