import { LatestBeacon } from 'types/devices';
import React from 'react';
import { getValueSafely } from 'utils/utils';

export default function AccuracyRangeSpan({ assetDetails }: { assetDetails: LatestBeacon }) {
  const accuracyRangeString = getValueSafely(
    assetDetails.estimate?.location?.uncertainty?.toFixed(1),
    ' metres',
  );

  return <span>{accuracyRangeString}</span>;
}
