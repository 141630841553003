import React, { useEffect, useContext, useRef, useState } from 'react';
import SelectInput from 'components/SelectInput/SelectInput';
import { components } from 'react-select';
import { StyledMapDropdownControlWrapper } from '../../Styled';
import { Checkbox } from 'antd';
import DropdownControlReadout from './DropdownControlReadout';
import { MapControlContext } from 'views/MapPage/contexts/mapcontrol.context';
import useCallSearchAssets from 'views/MapPage/hooks/useCallSearchAssets';
import { SearchFilterContext } from 'views/MapPage/contexts/searchFilter.context';
import { AssetConfigContext } from 'contexts/assetConfig.context';
import { getArrayOfValuesFromOptions } from 'views/MapPage/utils/utils';
import { getSortedArrayByValue } from 'utils/utils';
import LoadingSelectInput from 'components/Loading/LoadingSelectInput';

type AssetOwnerDropdownFilterInputType = {
  resetSearchFromCheckbox: () => void;
};
export default function AssetOwnerDropdownFilterInput({
  resetSearchFromCheckbox,
}: AssetOwnerDropdownFilterInputType) {
  const [dropdownModified, setDropdownModified] = useState(false); // boolean for if user has changed dropdown.
  const [ownerDropdownOpen, setOwnerDropdownOpen] = useState(false);

  const inputRef: any = useRef(null);
  const { apiCallInProgress, setClickedMarkerID } = useContext(MapControlContext);
  const {
    inSearchMode,
    assetSubtypeCheckedListArray,
    assetOwnerCheckedListArray,
    setAssetOwnerCheckedListArray,
    assetOwnerCheckAll,
    setAssetOwnerCheckAll,
  } = useContext(SearchFilterContext);
  const { callFilterAssets } = useCallSearchAssets();
  const { assetOwners } = useContext(AssetConfigContext);
  const defaultCheckedListArray = getArrayOfValuesFromOptions(assetOwners);
  const alphaSortedOptions = getSortedArrayByValue(assetOwners, 'value');

  const handleCheckAllChange = (e: any) => {
    setDropdownModified(true);
    setAssetOwnerCheckedListArray(e.target.checked ? defaultCheckedListArray : ['nop']);
    setAssetOwnerCheckAll(e.target.checked);
    resetSearchFromCheckbox();
    setClickedMarkerID('');
  };

  const handleCheckboxClick = (list: any) => {
    setDropdownModified(true);
    setAssetOwnerCheckedListArray(list.length === 0 ? ['nop'] : list);
    setAssetOwnerCheckAll(list.length === defaultCheckedListArray.length);
    resetSearchFromCheckbox();
    setClickedMarkerID('');
  };

  useEffect(() => {
    //reset checked to all, after performing a search
    if (inSearchMode) {
      setAssetOwnerCheckedListArray(defaultCheckedListArray);
      setAssetOwnerCheckAll(true);
      setOwnerDropdownOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inSearchMode]);

  useEffect(() => {
    // call API with selected options when checked list changes, after load
    if (dropdownModified && !inSearchMode) {
      callFilterAssets(inputRef, assetOwnerCheckedListArray, assetSubtypeCheckedListArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetOwnerCheckedListArray]);

  useEffect(() => {
    // set checked list to ALL, on component mount
    setAssetOwnerCheckedListArray(defaultCheckedListArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return assetOwners.length > 0 ? (
    <SelectInput
      options={alphaSortedOptions}
      placeholder={false}
      ref={inputRef}
      openMenuOnClick
      isSearchable={false}
      blurInputOnSelect
      onKeyDown={(ev) => {
        // prevent user inputting keys, as we are just using dropdown.
        ev.preventDefault();
      }}
      components={{
        Control: ({ children, ...rest }) => {
          setOwnerDropdownOpen(rest.menuIsOpen);

          return (
            <StyledMapDropdownControlWrapper>
              <components.Control {...rest}>
                <div style={{ display: 'flex' }}>
                  <span className="react-control__preplaceholder">Owner: </span>
                  <DropdownControlReadout
                    checkAll={assetOwnerCheckAll}
                    checkedList={assetOwnerCheckedListArray}
                    type="owner"
                  />
                </div>

                {children}
              </components.Control>
            </StyledMapDropdownControlWrapper>
          );
        },
        DropdownIndicator: ({ ...rest }) => (
          <button style={{ transform: `rotate(${ownerDropdownOpen ? '180deg' : '0deg'}` }}>
            <components.DropdownIndicator {...rest} />
          </button>
        ),
        Menu: ({ ...rest }) => {
          return (
            <components.Menu {...rest}>
              <Checkbox
                disabled={apiCallInProgress}
                style={{ marginBottom: '20px', width: '100%' }}
                checked={assetOwnerCheckAll}
                onChange={handleCheckAllChange}
              >
                All
              </Checkbox>

              <Checkbox.Group
                disabled={apiCallInProgress}
                value={assetOwnerCheckedListArray}
                options={alphaSortedOptions}
                defaultValue={defaultCheckedListArray}
                onChange={handleCheckboxClick}
              />
            </components.Menu>
          );
        },
      }}
    />
  ) : (
    <LoadingSelectInput />
  );
}
