import { LatestBeacon } from 'types/devices';
import { getLevelFeatureDataFromBuildingID } from '../utils/utils';
import L from 'leaflet';
import { latLngBounds } from 'leaflet';
import { useContext } from 'react';
import { MapContainerRefContext } from '../contexts/mapContainerRef.context';

export default function useMapHooksExternalMapRef() {
  // useEffect that uses leaflets map methods on mapstate passed in from mapContainerRef context.
  // functions are called by components and hooks that exist outside of mapcontainer eg search.
  const { mapContainerRef } = useContext(MapContainerRefContext);

  function fitMapToBoundsOfBuildingLevelsWithAssets(
    levelsFeaturesData: any,
    assets: LatestBeacon[],
    currentSelectedBuildingID?: string,
  ): void {
    // function that pans map to bounds of building levels which contain assets.
    // used for panning map after searching assets within multiple buildings.
    if (!mapContainerRef) return;

    let buildingIDArray: string[] = [];
    const bounds = latLngBounds([]); // start with empty bounds

    // get all unique building IDs from Assets
    assets.forEach((asset) => {
      asset.estimate.location.building.building_uuids.forEach((building_uuid) => {
        if (!buildingIDArray.includes(building_uuid)) {
          buildingIDArray.push(building_uuid);
        }
      });
    });

    // add current selected building if it is passed in
    if (currentSelectedBuildingID) {
      if (!buildingIDArray.includes(currentSelectedBuildingID)) {
        buildingIDArray.push(currentSelectedBuildingID);
      }
    }

    // loop through all buildingIDs and get levels features data that match assets building ID
    buildingIDArray.forEach((buildingID) => {
      const buildingIDLevelsData = getLevelFeatureDataFromBuildingID(
        buildingID,
        levelsFeaturesData,
      );
      const geoJSONLayer = L.geoJSON(buildingIDLevelsData);
      // expand bounds to each buildings data.
      bounds.extend(geoJSONLayer.getBounds());
    });

    // finally pan to fully extended bounds.
    mapContainerRef.fitBounds(bounds);
  }
  return { fitMapToBoundsOfBuildingLevelsWithAssets };
}
