import React, { useContext } from 'react';
import AssetSearchAsyncSelect from './AssetSearchAsyncSelect';
import useAssetListPageHandlers from 'views/AssetListPage/hooks/useAssetListPageHandlers.hook';
import { AssetListPageContext } from 'contexts/assetListPage.context';

export const assetSearchOptionsArray = [
  { value: 'properties.name', label: 'Asset Name' },
  { value: 'subtype', label: 'Asset Type' },
  { value: 'location', label: 'Location' },
  { value: 'properties.owner', label: 'Owner' },
];

const AssetSearchField = () => {
  const {
    searchTerm,
    setSearchTerm,
    setSearchCategory,
    setSelectedSearchMenuOptionLabel,
    selectedSearchMenuOptionLabel,
    isSearching,
    setIsSearching,
    setNextToken,
    setSearchCleared,
    setPaginationIndex,
  } = useContext(AssetListPageContext);
  const { handleEnterSearch, handleClickOptionSearch } = useAssetListPageHandlers();

  return (
    <AssetSearchAsyncSelect
      setSearchCleared={setSearchCleared}
      searchTerm={searchTerm}
      setNextToken={setNextToken}
      handleEnterSearchFunc={handleEnterSearch}
      handleClickOptionSearchFunc={handleClickOptionSearch}
      setSearchTerm={setSearchTerm}
      setIsSearching={setIsSearching}
      isSearching={isSearching}
      menuOptions={assetSearchOptionsArray}
      selectedSearchMenuOptionLabel={selectedSearchMenuOptionLabel}
      setSearchCategory={setSearchCategory}
      setSelectedSearchMenuOptionLabel={setSelectedSearchMenuOptionLabel}
      placeholder="Search for assets"
      width={375}
      setPaginationIndex={setPaginationIndex}
    />
  );
};

export default AssetSearchField;
