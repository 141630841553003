import React, { useContext, useState } from 'react';
import { colours } from 'styling/colours';
import styled from 'styled-components';
import bluemapIcon from './icons/bluemap.svg';
import { getIdToken, getWindowURL } from 'utils/utils';
import { LatestBeacon } from 'types/devices';
import LoadingDots from 'components/Loading/LoadingDots';
import { AuthenticationContext } from 'contexts/authentication.context';
import EstimatesApi from 'api/estimates/estimates.api';
import { alertErrorMessage } from 'utils/alerts';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 222px;
  height: 40px;
  font-size: 15px;
  text-align: center;
  background: #f6f7f9;
  outline: none;
  border: none;
  margin: 15px 0 5px 0;
  color: ${colours.secondary};
  text-decoration: underline;
  img {
    margin-right: 10px;
  }
  &:hover {
    cursor: pointer;
    background: #f6f7f9;
    text-decoration: underline;
  }
`;

export default function ViewAssetOnMapLink({ asset }: { asset: LatestBeacon }) {
  const [fetchingEstimate, setFetchingEstimate] = useState(false);
  const { authState } = useContext(AuthenticationContext);
  if (!asset.estimate || !asset.entity) return null;
  // simple a tag link to render on asset detail flyout with URL params.
  // will not render without required params.
  const venueID = asset.estimate.location?.venue.id;
  const buildingID = asset.estimate.location?.building.building_uuids;
  const entityID = asset.entity.entity_id;
  const levelID = asset.estimate.location?.building.building_level_uuid;
  const allRequiredValuesExist =
    venueID !== null && buildingID !== null && entityID !== null && levelID !== null;

  function handleClick(asset: LatestBeacon) {
    const token = getIdToken(authState);
    const estimatesApi = new EstimatesApi(token);

    setFetchingEstimate(true);

    estimatesApi
      .getSingleEstimateByID(asset.estimate.entity_id)
      .then((res) => {
        const resBuildingID = res.location?.building.building_uuids;
        const resLevelID = res.location?.building.building_level_uuid;
        const resVenueID = res.location?.venue.id;
        const constructedURL = `${getWindowURL()}/map?venue_id=${resVenueID}&building_id=${resBuildingID}&level_id=${resLevelID}&entity_id=${entityID}`;

        window.open(constructedURL, '_blank');
        setFetchingEstimate(false);
      })
      .catch(() => {
        alertErrorMessage('Error fetching estimate details');
      });
  }

  return allRequiredValuesExist ? (
    <StyledButton onClick={() => handleClick(asset)}>
      {fetchingEstimate ? (
        <LoadingDots />
      ) : (
        <>
          <img src={bluemapIcon} alt="view on map" />
          View asset on the map
        </>
      )}
    </StyledButton>
  ) : null;
}
