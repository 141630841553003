import React, { useContext } from 'react';

import freshIcon from '../icons/fresh-asset.svg';
import moderateIcon from '../icons/moderate-asset.svg';
import staleIcon from '../icons/stale-asset.svg';
import movingIcon from '../icons/moving-asset-key.svg';
import personIcon from '../icons/person-icon.svg';
import ComponentFeaturePermissionRenderWrapper from 'wrappers/ComponentConditionWrappers/ComponentFeaturePermissionRenderWrapper';
import { PERMISSIONS } from 'config/permissions';
import { FullConfigContext } from 'contexts/fullConfig.context';
import { StyledKeyLegend, StyledKeyLegendMobileCloseButton } from '../Styled';
import useDevice from 'hooks/useDevice';

export default function KeyLegendPanel({
  setMobileLegendOpen,
}: {
  setMobileLegendOpen: (arg: boolean) => void;
}) {
  const { userTrackingPermissionEnabled, assetTrackingPermissionEnabled } =
    useContext(FullConfigContext);
  const userHasFullPermission = userTrackingPermissionEnabled && assetTrackingPermissionEnabled;
  const { isMobileDevice } = useDevice();

  return (
    <StyledKeyLegend data-test-id="legend">
      {isMobileDevice && (
        <StyledKeyLegendMobileCloseButton onClick={() => setMobileLegendOpen(false)} />
      )}
      <ul>
        <ComponentFeaturePermissionRenderWrapper
          featurePermissions={PERMISSIONS.FEATURE.ASSET_TRACKING}
        >
          <>
            <li>
              <img src={staleIcon} alt="old Estimate" />
              <span>Old estimate</span>
            </li>
            <li>
              <img src={moderateIcon} alt="Moderate Estimate" />
              <span>Moderately fresh estimate</span>
            </li>
            <li>
              <img src={freshIcon} alt="Fresh Estimate" />
              <span>Fresh estimate</span>
            </li>
            <li>
              <img src={movingIcon} alt="Asset mobile" />
              <span>Asset is mobile</span>
            </li>
          </>
        </ComponentFeaturePermissionRenderWrapper>

        {userHasFullPermission && <div className="hr" />}

        <ComponentFeaturePermissionRenderWrapper
          featurePermissions={PERMISSIONS.FEATURE.USER_TRACKING}
        >
          <li>
            <img src={personIcon} alt="Person" />
            <span>Phone-user</span>
          </li>
        </ComponentFeaturePermissionRenderWrapper>
      </ul>
    </StyledKeyLegend>
  );
}
