import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken } from 'utils/utils';
import { LatestBeacon } from 'types/devices';
import { getEstimatesWithBuildingAndEstimate } from '../utils/utils';
import AssetsApi from 'api/assets/assets.api';
import { MapControlContext } from './mapcontrol.context';
import { alertErrorMessage } from 'utils/alerts';
import { VenuesContext } from './venues.context';
import { FullConfigContext } from 'contexts/fullConfig.context';

type AssetsContextTypes = {
  setRawAssets: (arg: LatestBeacon[]) => void;
  rawAssets: LatestBeacon[];
  cachedAssetRef: any;
};
export const AssetsContext = createContext({} as AssetsContextTypes);

type AssetsProviderProps = { children: ReactNode };

// A context that fetches all Registered Beacons filtered by selectedVenue, and passes them into context to display as assets on map.
const AssetsProvider = ({ children }: AssetsProviderProps) => {
  const [rawAssets, setRawAssets] = useState<LatestBeacon[] | []>([]); // raw unfiltered assets straight from API.

  const { recallGetAssets, setRecallGetAssets, setApiCallInProgress, mapCompletedLoadRef } =
    useContext(MapControlContext);
  const authContext = useContext(AuthenticationContext).authState;
  const { assetTrackingPermissionEnabled } = useContext(FullConfigContext);
  const { selectedVenueObj, isSingleVenue } = useContext(VenuesContext);
  const selectedVenueID = isSingleVenue ? null : selectedVenueObj.venue_id;
  const token = getIdToken(authContext);

  const cachedAssetRef = useRef(null);

  const value: AssetsContextTypes = {
    setRawAssets,
    rawAssets,
    cachedAssetRef,
  };

  function callGetAssets() {
    if (assetTrackingPermissionEnabled) {
      const assetsApi = new AssetsApi(token);
      setApiCallInProgress(true);

      assetsApi
        .getRegisteredBeaconMapAssets(selectedVenueID)
        .then((res) => {
          const { items } = res.data;
          const filteredItems = getEstimatesWithBuildingAndEstimate(items);
          setRawAssets(filteredItems);
          setApiCallInProgress(false);
          setRecallGetAssets(false);

          mapCompletedLoadRef.current = true;
        })
        .catch((err) => {
          alertErrorMessage('Could not fetch Assets');
          setApiCallInProgress(false);
          console.error(err.message);
        });
    }
  }

  useEffect(() => {
    // recall API after search has been cleared.
    if (recallGetAssets) {
      callGetAssets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recallGetAssets]);

  return <AssetsContext.Provider value={value}>{children}</AssetsContext.Provider>;
};

export default AssetsProvider;
