import { Estimate } from 'types/combinedEstimatesAssets';
import { LatestBeacon } from 'types/devices';
import { UserType } from 'types/userInfo';
import { VenueListType } from 'types/venue';
import { ReactSelectType } from '../components/VenueSelectDropdownControl';

export function filterAssetMarkersByFloorUUID(
  assetMarkers: any[],
  floorSelectedUUIDArray: string[],
) {
  // function  returns array of markers whos building_level_uuid matches one of the selected floor UUIDs
  return assetMarkers.filter((asset: any) => {
    return floorSelectedUUIDArray.includes(asset.estimate.location.building.building_level_uuid);
  });
}

export function filterUsersMarkersByFloorUUID(
  usersMarkers: any[],
  floorSelectedUUIDArray: string[],
) {
  // function  returns array of markers whos building_level_uuid matches one of the selected floor UUIDs
  return usersMarkers.filter((user: any) => {
    return floorSelectedUUIDArray.includes(user.estimate.location.building.building_level_uuid);
  });
}

export function checkAssetIsOnSelectedFloor(
  asset: LatestBeacon,
  floorSelectedIDArray: string[],
): boolean {
  // function that checks if selected floorIDs contain assets building level_uuid and returns boolean

  return floorSelectedIDArray.includes(asset.estimate.location.building.building_level_uuid);
}

export function getUnitFeatureByFloorSelectedUUID(unitFeatures: any, floorSelectedUUID: string) {
  return unitFeatures.filter((feature: any) => feature.properties.level_id === floorSelectedUUID);
}

export function getLevelFeatureDataFromBuildingID(buildingID: string, levelFeatures: any): any {
  const levelsFeaturesWithMatchingBuildingIDsArray = levelFeatures.filter((feature: any) => {
    if (feature.properties.building_ids) {
      return feature.properties.building_ids.includes(buildingID);
    }
  });

  return levelsFeaturesWithMatchingBuildingIDsArray;
}

export function getLevelFeatureDataFromOrdinal(ordinal: number, levelFeatures: any) {
  // a function to return level feature data from relevant level ordinal.
  return levelFeatures.filter(
    (levelFeature: any) => levelFeature.properties.ordinal === ordinal,
  )[0];
}

export function getUnitFeatureDataFromLevelIDs(levelIDArray: string[], unitFeatures: any): any {
  // return unit feature data filtered by each id in levelIDArray.
  let combinedUnitFeatures: any = [];

  levelIDArray.forEach((levelID) => {
    const levelIDFilteredFeatures = unitFeatures.filter((feature: any) => {
      return feature.properties.level_id === levelID;
    });
    combinedUnitFeatures = [...combinedUnitFeatures, ...levelIDFilteredFeatures];
  });

  return combinedUnitFeatures;
}

export function getLevelFeaturesWithUniqueOrdinals(associatedBuildingLevels: any) {
  // returns array of features with unique ordinals, so we can use them to display floors.
  return associatedBuildingLevels.filter((value: any, index: number, self: any) => {
    return self.findIndex((v: any) => v.properties.ordinal === value.properties.ordinal) === index;
  });
}

export function getLevelFeaturesSortedByOrdinal(levelFeatures: any) {
  return levelFeatures.sort(
    (a: any, b: any) => parseFloat(a.properties.ordinal) - parseFloat(b.properties.ordinal),
  );
}

export function getAssociatedLevelIDsArrayFromOrdinal(ordinal: number, levelFeatures: any) {
  let array: any[] = [];

  levelFeatures.forEach((feature: any) => {
    if (feature.properties.ordinal === ordinal) {
      array.push(feature.id);
    }
  });
  return array;
}

export function getUsersWithEstimate(users: UserType[]) {
  // ensures users have estimate, to prevent null estimates being passed to map.
  return users.filter((user) => user.estimate !== null);
}

export function getEstimatesWithBuildingAndEstimate(items: LatestBeacon[]) {
  return items.filter((item) => {
    // ensures asset markers shown on map have an estimate, and that estimate has building data. Otherwise application will crash.
    return item.estimate !== null && item.estimate.location.building;
  });
}

export function getLowestOrdinalThatHasAsset(
  sortedLevelFeatures: any,
  assetMarkers: LatestBeacon[],
  selectedFloorOrdinal: number,
) {
  // loop through levels in order from lowest ordinal, and then loop through assets for each level.
  // if level ID matches asset building level id, return ordinal of this level.
  // this is the lowest ORDINAL with an asset.
  let lowestOrdinal = selectedFloorOrdinal; // keep at selected floor for returning null cases.
  let ordinalFound = false;

  sortedLevelFeatures.forEach((levelFeature: any) => {
    if (ordinalFound) return;

    assetMarkers.forEach((asset: LatestBeacon) => {
      if (ordinalFound) return;
      const assetLevelID = asset.estimate.location.building.building_level_uuid;

      if (levelFeature.id === assetLevelID) {
        lowestOrdinal = levelFeature.properties.ordinal;
        ordinalFound = true;
        return;
      }
    });
  });

  return lowestOrdinal;
}

export function getOrdinalFromLevelID(levelFeatures: any, levelID: string): number {
  const matchedLevelFeature = levelFeatures.filter(
    (levelFeature: any) => levelFeature.id === levelID,
  )[0];

  return matchedLevelFeature?.properties.ordinal;
}

export function getLowestOrdinalThatHasUser(
  sortedLevelFeatures: any,
  usersMarkers: UserType[],
  selectedFloorOrdinal: number,
) {
  // loop through levels in order from lowest ordinal, and then loop through users for each level.
  // if level ID matches asset building level id, return ordinal of this level.
  // this is the lowest ORDINAL with a user.
  let lowestOrdinal = selectedFloorOrdinal; // keep at selected floor for returning null cases.
  let ordinalFound = false;

  sortedLevelFeatures.forEach((levelFeature: any) => {
    if (ordinalFound) return;

    usersMarkers.forEach((user: UserType) => {
      if (ordinalFound) return;
      const assetLevelID = user.estimate.location.building.building_level_uuid;

      if (levelFeature.id === assetLevelID) {
        lowestOrdinal = levelFeature.properties.ordinal;
        ordinalFound = true;
        return;
      }
    });
  });

  return lowestOrdinal;
}

export function getIndexOfLevelsFromMatchingFloorSelectedIDs(
  sortedLevelsWithUniqueOrdinals: any,
  floorSelectedIDArray: string[],
): number {
  // function to return index of levels array that matches the floorSelectedIDArrays.
  const matchingLevel = sortedLevelsWithUniqueOrdinals.findIndex((level: any) => {
    return floorSelectedIDArray.includes(level.id);
  });

  return matchingLevel;
}

export function getFloorAssetCountFromLevelIDArray(
  levelIDArray: any,
  assetMarkers: LatestBeacon[],
) {
  // a function that creates an array of markers whos building level uuid matches one of the level_ids in the array.
  // we use this to determine how many assets are on that floor,
  // then return the length of the new array which is the count.
  let filteredMarkersArray = [];

  levelIDArray.forEach((levelID: string) => {
    filteredMarkersArray = assetMarkers.filter((asset) => {
      const { building_level_uuid } = asset.estimate.location.building;
      return levelID === building_level_uuid;
    });
  });

  return filteredMarkersArray.length;
}

export function getFloorUserCountFromLevelIDArray(levelIDArray: any, userMarkers: UserType[]) {
  // a function that creates an array of markers whos building level uuid matches one of the level_ids in the array.
  // we use this to determine how many users are on that floor,
  // then return the length of the new array which is the count.
  let filteredMarkersArray = [];

  levelIDArray.forEach((levelID: string) => {
    filteredMarkersArray = userMarkers.filter((user) => {
      const { building_level_uuid } = user.estimate.location.building;

      return levelID === building_level_uuid;
    });
  });

  return filteredMarkersArray.length;
}

export function getBuildingAssetCountFromFeatureBuildingUUIDArray(
  buildingUUIDArray: string[],
  assetMarkers: LatestBeacon[],
) {
  let filteredMarkersArray = [];

  buildingUUIDArray.forEach((buildingUUID) => {
    assetMarkers.forEach((asset) => {
      const { building_uuids } = asset.estimate.location.building;

      if (building_uuids.includes(buildingUUID)) {
        filteredMarkersArray.push(asset);
      }
    });
  });

  return filteredMarkersArray.length;
}
export function getUserAssetCountFromFeatureBuildingUUIDArray(
  buildingUUIDArray: string[],
  users: any[],
) {
  let filteredMarkersArray = [];

  buildingUUIDArray.forEach((buildingUUID) => {
    users.forEach((user) => {
      const { building_uuids } = user.estimate.location.building;

      if (building_uuids.includes(buildingUUID)) {
        filteredMarkersArray.push(user);
      }
    });
  });

  return filteredMarkersArray.length;
}

export function getCoordsFromAssets(assets: LatestBeacon[]) {
  let coords: any = [];

  assets.forEach((asset) => {
    const latLongArray = asset.estimate.location.coordinates.coordinates;
    const correctedCoord: any = [...latLongArray].reverse(); // reversed to correct order

    coords.push(correctedCoord);
  });

  return coords;
}

export function getCoordsFromUsers(users: UserType[]) {
  let coords: any = [];

  users.forEach((user) => {
    const latLongArray = user.estimate.location.coordinates.coordinates;
    const correctedCoord: any = [...latLongArray].reverse(); // reversed to correct order

    coords.push(correctedCoord);
  });

  return coords;
}

export function getArrayOfValuesFromOptions(options: any) {
  let arr: any = [];

  options.forEach((element: any) => {
    arr.push(element.value);
  });
  return arr;
}

// A function that returns only items if the assets from the APIs owner & subtype exists within the selected filter params.
// used for returning intersection of results of owner & subtype for filter dropdowns.
export function getIntersectionFilteredAssets(
  assets: LatestBeacon[],
  assetOwnerQueryArray: string[],
  assetSubtypeQueryArray: string[],
) {
  let filteredAssetArray: LatestBeacon[] = [];

  // loop through assets with estimates,
  // and push assets only if subtype and owner exist on asset.
  assets.forEach((asset) => {
    const assetMatchesSelectedOwners = assetOwnerQueryArray.includes(asset.entity.properties.owner);
    const assetMatchesSelectedSubtype = assetSubtypeQueryArray.includes(asset.entity.subtype);

    if (assetMatchesSelectedOwners && assetMatchesSelectedSubtype) {
      filteredAssetArray.push(asset);
    }
  });
  return filteredAssetArray;
}

export function getReactSelectOptionsFromVenueListData(
  venueList: VenueListType[],
): ReactSelectType[] {
  // a function that converts venueList objects into datastructure that react-select can use.
  const dataStructure: any[] = [];

  venueList.forEach((venue: any) => {
    const newObj = {
      label: venue.name,
      value: venue.venue_id,
    };
    dataStructure.push(newObj);
  });

  return dataStructure;
}

export function getVenueObjFromReactSelectValue(
  reactSelectValue: string,
  venuesList: VenueListType[],
): VenueListType {
  // a function that finds and returns matched venueObj from reactSelect value.
  return venuesList.filter((venue: VenueListType) => venue.venue_id === reactSelectValue)[0];
}

export function getAssetEstimatesOfSelectedBuilding(assets: LatestBeacon[], buildingUUID: string) {
  // simple function to return assets with building_uuid to match selected buildingsUUID
  return assets.filter((asset: LatestBeacon) => {
    return asset.estimate.location.building.building_uuids.includes(buildingUUID);
  });
}

export function getUserEstimatesOfSelectedBuilding(users: UserType[], buildingUUID: string) {
  // simple function to return users with building_uuid to match selected buildingsUUID
  return users.filter((user: UserType) => {
    return user.estimate.location.building.building_uuids.includes(buildingUUID);
  });
}

export function checkBuildingUUIDExistsWithinBuildingFeatures(
  buildingFeatures: any,
  buildingID: string,
) {
  const matchingId = buildingFeatures.filter((feature: any) => feature.id === buildingID);

  return matchingId.length > 0;
}

export function checkLevelIDExistsWithinLevelFeatures(levelFeatures: any, levelID: string) {
  const matchingId = levelFeatures.filter((feature: any) => {
    return feature.id === levelID;
  });

  return matchingId.length > 0;
}

export function checkAssetEstimateLocationIsDifferent(
  firstAssetEstimate: Estimate,
  secondAssetEstimate: Estimate,
): boolean {
  // serialise the locations, and checks if they are equal and returns boolean.
  const firstCoordsStr = JSON.stringify(firstAssetEstimate.location.coordinates.coordinates);
  const secondCoordsStr = JSON.stringify(secondAssetEstimate.location.coordinates.coordinates);

  return firstCoordsStr !== secondCoordsStr;
}

export function checkAssetIsInSameBuilding(asset: LatestBeacon, buildingSelectedID: string) {
  return asset.estimate.location.building.building_uuids.includes(buildingSelectedID);
}

export function getRandomKey() {
  // create random key to force re-render of markers on every render.
  return Math.random() * 100;
}

export function checkAssetsShouldDisplayConditions(...args: [boolean, boolean][]): boolean {
  // function that accepts dynamic array of array arguements pair
  // loop over each array pair, and check condition is what is expected to pass.
  // function will return false if one of these conditions does not match.
  return args.every((argArrayPair) => {
    const param = argArrayPair[0];
    const conditionToPass = argArrayPair[1];

    return param === conditionToPass;
  });
}

export function getAPIMatchedAssetFromEntityID(assets: LatestBeacon[], entityID: string) {
  const foundAsset = assets.filter((asset) => asset.entity.entity_id === entityID)[0];

  return foundAsset !== undefined ? foundAsset : null;
}

export function getSearchParamValue(searchParam: string) {
  return new URLSearchParams(location.search).get(searchParam);
}

export function checkFullAssetURLParamsExist(): boolean {
  const urlParamEntityID = getSearchParamValue('entity_id');
  const urlParamVenueID = getSearchParamValue('venue_id');
  const urlParamBuildingID = getSearchParamValue('building_id');
  const urlParamlevelID = getSearchParamValue('level_id');
  const fullAssetURLParamsExist =
    urlParamEntityID !== null &&
    urlParamVenueID !== null &&
    urlParamBuildingID !== null &&
    urlParamlevelID !== null;
  return fullAssetURLParamsExist;
}

export function checkFullMobileClickThroughParamsExist(): boolean {
  const urlParamBuildingID = getSearchParamValue('building_id');
  const urlParamlevelID = getSearchParamValue('level_id');
  const urlParamUserLat = getSearchParamValue('user_lat');
  const urlParamUserLong = getSearchParamValue('user_long');

  const fullMobileClickThroughParamsExist =
    urlParamUserLat !== null &&
    urlParamUserLong !== null &&
    urlParamBuildingID !== null &&
    urlParamlevelID !== null;

  return fullMobileClickThroughParamsExist;
}

export function checkIfMobileFiltersHaveChangedSinceLastSearch(
  assetOwnerCheckedListArray: string[],
  assetSubtypeCheckedListArray: string[],
  assetOwnerCheckedListArrayRef: string[],
  assetSubtypeCheckedListArrayRef: string[],
) {
  const ownerArrayIsSame =
    JSON.stringify(assetOwnerCheckedListArray) === JSON.stringify(assetOwnerCheckedListArrayRef);
  const subtyypeArrayIsSame =
    JSON.stringify(assetSubtypeCheckedListArray) ===
    JSON.stringify(assetSubtypeCheckedListArrayRef);

  return !ownerArrayIsSame || !subtyypeArrayIsSame;
}

export function checkIfSearchTermHasChangedSinceLastSearch(
  searchTerm: string,
  searchTermRef: string,
) {
  return searchTerm !== searchTermRef;
}
