import React, { useContext } from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import personIcon from '../../icons/person-icon.svg';
import { UserType } from 'types/userInfo';
import { MapControlContext } from 'views/MapPage/contexts/mapcontrol.context';
import UserNameTooltip from '../ToolTips/UserNameTooltip';

export default function UserMarker({ user }: { user: UserType }) {
  const { userMarkerInfoChecked } = useContext(MapControlContext);

  const markerLong = user.estimate.location.coordinates.coordinates[0];
  const markerLat = user.estimate.location.coordinates.coordinates[1];

  const customIcon = new L.Icon({
    iconUrl: personIcon,
    iconSize: new L.Point(20, 20),
    iconAnchor: [10, 10], // half of icon size.
    className: 'leaflet-custom-icon',
  });

  return (
    <Marker icon={customIcon} position={[markerLat, markerLong]}>
      {userMarkerInfoChecked && <UserNameTooltip user={user} />}
    </Marker>
  );
}
