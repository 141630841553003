import { AssetConfigContext } from 'contexts/assetConfig.context';
import { useContext } from 'react';

export default function useAssetConfig() {
  const { assetOwners, assetSubtype } = useContext(AssetConfigContext);

  function getLabelFromAssetConfig(value: any, type: string) {
    const derivedConfig = type === 'subtype' ? assetSubtype : assetOwners;
    const matchingObject = derivedConfig.filter((item: any) => item.value === value)[0];

    return matchingObject ? matchingObject.label : '––';
  }

  return { getLabelFromAssetConfig };
}
