/* eslint-disable jsx-a11y/click-events-have-key-events */
import DevicesApi from 'api/devices/devices.api';
import EntitiesApi from 'api/entities/entities.api';
import { ButtonStyledAsLink } from 'components/Button/styled';
import { StyledText, StyledUploadFileForm } from 'components/FileUpload/Form/Styled';
import { AuthenticationContext } from 'contexts/authentication.context';
import React, { useContext } from 'react';
import { alertErrorMessage } from 'utils/alerts';
import { getIdToken, programaticallyDownloadFile } from 'utils/utils';
import CollapseArrowButton from '../components/CollapseArrowButton';
import useUploadFormDOMEvents from '../hooks/useUploadFormDOMEvents';

export default function UploadFileForm({
  isAssetUpload,
  isCollapsable,
  setFormCollapsed,
  setUploadModalOpen,
  setSelectedFile,
}: {
  isAssetUpload?: boolean;
  isCollapsable: boolean;
  setFormCollapsed?: (arg: boolean) => void;
  setUploadModalOpen: (arg: boolean) => void;
  setSelectedFile: (arg: any) => void;
}) {
  const { authState } = useContext(AuthenticationContext);

  const { dropFileContainerRef, fileInputRef, fileIsDragging } =
    useUploadFormDOMEvents(handleFileSelect);

  function handleFileSelect(e: any) {
    // file handler to handle input select event and drop event to capture files.
    const isInputSelect = e.type === 'change';
    const file: any = isInputSelect ? e.target.files[0] : e.dataTransfer.files[0];

    setSelectedFile(file);
    setUploadModalOpen(true);
  }

  function resetInputValue() {
    // to prevent browser doing nothing if file is the same.
    fileInputRef.current.value = null;
  }

  function handleDownload(e: React.SyntheticEvent) {
    const idToken = getIdToken(authState);
    const derivedAPI = isAssetUpload ? new EntitiesApi(idToken) : new DevicesApi(idToken);
    const fileName = isAssetUpload ? 'asset_import_template.csv' : 'beacon_import_template.csv';

    e.preventDefault();
    derivedAPI
      .getJobTemplateFile()
      .then((res: any) => {
        programaticallyDownloadFile(res.data, fileName);
      })
      .catch(() => {
        alertErrorMessage('Error downloading file');
      });
  }

  return (
    <StyledUploadFileForm ref={dropFileContainerRef} className={fileIsDragging ? '--dragging' : ''}>
      <div className="form-inner">
        {isCollapsable && setFormCollapsed && (
          <CollapseArrowButton setFormCollapsed={setFormCollapsed} />
        )}
        <StyledText>
          <label
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="link"
            tabIndex={0}
            className="label-button"
            htmlFor="fileInput"
            onClick={() => resetInputValue()}
          >
            Select
          </label>{' '}
          .xls, .xlsx or .csv file to upload or drag and drop here
        </StyledText>
        <StyledText>
          You can also{' '}
          <ButtonStyledAsLink onClick={handleDownload}>download the template</ButtonStyledAsLink> to
          ensure your file fits
          <br /> the format shown in the template
        </StyledText>
        <input ref={fileInputRef} style={{ display: 'none' }} type="file" id="fileInput" />
      </div>
    </StyledUploadFileForm>
  );
}
