import SelectInput from 'components/SelectInput/SelectInput';
import React, { useContext, useEffect, useState } from 'react';
import { StyledMapDropdownControlWrapper } from 'views/MapPage/Styled';
import { components } from 'react-select';
import { Checkbox } from 'antd';
import { getArrayOfValuesFromOptions } from 'views/MapPage/utils/utils';
import { MapControlContext } from 'views/MapPage/contexts/mapcontrol.context';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

type optionsType = {
  value: string;
  label: string;
};

const options: optionsType[] = [
  {
    value: 'assets',
    label: 'Assets',
  },
  {
    value: 'people',
    label: 'People',
  },
];

export default function ShowAssetPeopleDropdown() {
  const defaultOptions = getArrayOfValuesFromOptions(options);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCheckboxArray, setSelectedCheckboxArray] = useState<any>(defaultOptions);
  const { setShowAssetsChecked, setShowUsersChecked } = useContext(MapControlContext);

  const handleCheckboxClick = (e: CheckboxValueType[]) => {
    if (e.length >= 1) {
      setSelectedCheckboxArray(e);
    } else {
      const nextSelected = getDeterminedSelection();

      setSelectedCheckboxArray([nextSelected]);
    }
  };

  function getDeterminedSelection() {
    // ensure always one option is selected. If user unselects single checkbox, we select the opposite one.
    const previousSelected = selectedCheckboxArray[0];
    const oppositeOfSelected = defaultOptions.find(
      (option: string[]) => option !== previousSelected,
    );

    return oppositeOfSelected;
  }

  function getTextToDisplay() {
    if (selectedCheckboxArray.length === 2) return 'Assets & People';
    else return selectedCheckboxArray;
  }

  useEffect(() => {
    // keep track of checkboxes in dropdown, and update states accordingly.
    const assetsChecked = selectedCheckboxArray.includes('assets');
    const usersChecked = selectedCheckboxArray.includes('people');

    setShowAssetsChecked(assetsChecked);
    setShowUsersChecked(usersChecked);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCheckboxArray]);

  return (
    <SelectInput
      className="react-select-container asset-people-input"
      options={options}
      placeholder={false}
      openMenuOnClick
      menuIsOpen={dropdownOpen}
      onMenuOpen={() => {
        setDropdownOpen(true);
      }}
      onKeyDown={(ev) => {
        // prevent user inputting keys, as we are just using dropdown.
        ev.preventDefault();
      }}
      components={{
        Control: ({ children, ...rest }) => (
          <StyledMapDropdownControlWrapper>
            <components.Control {...rest}>
              <div style={{ display: 'flex' }}>
                <span className="react-control__preplaceholder">Show: </span>
                <span style={{ textTransform: 'capitalize' }}>{getTextToDisplay()}</span>
              </div>
              {children}
            </components.Control>
          </StyledMapDropdownControlWrapper>
        ),
        DropdownIndicator: ({ ...rest }) => (
          <button
            onClick={() => setDropdownOpen(false)}
            style={{ transform: `rotate(${dropdownOpen ? '180deg' : '0deg'}` }}
          >
            <components.DropdownIndicator {...rest} />
          </button>
        ),
        Menu: ({ ...rest }) => {
          return (
            <components.Menu {...rest}>
              <Checkbox.Group
                value={selectedCheckboxArray}
                options={options}
                defaultValue={defaultOptions}
                onChange={handleCheckboxClick}
              />
            </components.Menu>
          );
        },
      }}
    />
  );
}
