import React, { useContext, useRef } from 'react';
import useAssetUserSearchMenuOptions, {
  menuOptionsAssets,
} from '../../hooks/useAssetUserSearchMenuOptions.hook';
import OptionMenu from 'components/AsyncSelect/OptionMenu';
import { StyledAsyncSelect } from 'components/AsyncSelect/Styled';
import FeatureFlagComponentWrapper from 'wrappers/FeatureFlagComponentWrapper';
import useCallSearchAssets from 'views/MapPage/hooks/useCallSearchAssets';
import { SearchFilterContext } from 'views/MapPage/contexts/searchFilter.context';
import NoSearchResultsPanel from '../NoSearchResultsPanel';
import { MapControlContext } from 'views/MapPage/contexts/mapcontrol.context';
import ClearInputButton from 'components/Button/ClearInputButton';

export default function DerivedAsyncSelectSearch() {
  const { callSearchAssets, setNoResultsReturned, noResultsReturned } = useCallSearchAssets();
  const {
    searchTerm,
    setSearchTerm,
    menuIsOpen,
    setMenuIsOpen,
    setDoNotCalculateFloor,
    setInSearchMode,
    inFilterMode,
  } = useContext(SearchFilterContext);
  const { setRecallGetAssets, setClickedMarkerID } = useContext(MapControlContext);
  const { getMenuOptions } = useAssetUserSearchMenuOptions();
  const inputRef: any = useRef(null);

  function handleInputChange(term: string, actionObj: any) {
    switch (actionObj.action) {
      case 'input-change':
        setSearchTerm(term);
        setNoResultsReturned(false);

        break;
      case 'menu-close':
        setMenuIsOpen(false);
        break;
      case 'input-blur':
        setMenuIsOpen(false);
        break;
      default:
        break;
    }
  }

  function handleKeyPress(e: React.KeyboardEvent) {
    if (searchTerm.length < 1) return;
    if (e.keyCode === 13) {
      const termTrimmed = searchTerm.trim();

      setSearchTerm(termTrimmed);
      callSearchAssets(termTrimmed);
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  }

  function handleClickOptionSearch(selectedOptionObj: { value: string; label: string }) {
    if (searchTerm.length < 1) return;

    if (selectedOptionObj.value === 'assets') {
      const termTrimmed = searchTerm.trim();
      setSearchTerm(termTrimmed);
      callSearchAssets(termTrimmed);

      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  }

  function handleClearField() {
    // clear search field, and trigger recall API in Asset Context, to show unfiltered assets.
    setDoNotCalculateFloor(true); // set to true so we dont recenter map.
    setInSearchMode(false);
    setSearchTerm('');
    setClickedMarkerID('');
    setNoResultsReturned(false);
    if (!inFilterMode) setRecallGetAssets(true); // we do not want to recall api if filters are present and user cancels search.
  }

  return (
    <>
      <FeatureFlagComponentWrapper
        disabledFlagString="FF_DISABLE_MAP_USER_SEARCH"
        fallbackComponent={
          //  asset only search.
          <StyledAsyncSelect
            blurInputOnSelect
            placeholder="Search"
            onInputChange={handleInputChange}
            onKeyDown={(e: React.KeyboardEvent) => handleKeyPress(e)}
            inputValue={searchTerm}
            className="asyncSearch"
            classNamePrefix="asyncSearchPrefix"
            menuIsOpen={menuIsOpen}
            ref={inputRef}
            components={{
              Menu: (props: any) => (
                <OptionMenu
                  {...props}
                  searchTerm={searchTerm}
                  menuOptions={menuOptionsAssets}
                  handleClickOptionSearch={handleClickOptionSearch}
                />
              ),
            }}
          />
        }
      >
        <StyledAsyncSelect
          blurInputOnSelect
          placeholder="Search"
          onInputChange={(term: any, actionObj: any) => handleInputChange(term, actionObj)}
          onKeyDown={(e: React.KeyboardEvent) => handleKeyPress(e)}
          inputValue={searchTerm}
          className="asyncSearch"
          classNamePrefix="asyncSearchPrefix"
          menuIsOpen={menuIsOpen}
          ref={inputRef}
          components={{
            Menu: (props: any) => (
              <OptionMenu
                {...props}
                searchTerm={searchTerm}
                menuOptions={getMenuOptions()}
                handleClickOptionSearch={handleClickOptionSearch}
              />
            ),
          }}
        />
      </FeatureFlagComponentWrapper>
      {noResultsReturned && <NoSearchResultsPanel searchTerm={searchTerm} />}
      {searchTerm.length > 0 && <ClearInputButton onClick={handleClearField} />}
    </>
  );
}
